import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import useStyles from './styles/auditCalendarStyles'
import moment from 'moment';
import { chunk, isEmpty } from 'lodash';
import { Fragment } from 'react';
import Button from './button'
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";

const daysHeaders = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const AuditCalendar = ({ data = [], month = 0 }) => {
  const styles = useStyles();
  const [currentMonth, setCurrentMonth] = useState(month);
  const [dayInfo, setDayInfo] = useState({ open: false, title: '', summary: {}, attempts: [] });

  useEffect(() => {
    setCurrentMonth(month)
  }, [month])

  const showDayInfo = (data) => {
    const momentDate = moment(data?.date);
    const monthNumber = momentDate.month();
    const summary = data?.details || { ok: 0, fail: 0 };
    const title = `Registro ${months[monthNumber]} ${momentDate.format(`D - YYYY`)}`;
    const attempts = data?.rawData;
    setDayInfo({ open: true, title, summary, attempts });
  }
  const CalendarCell = ({ data }) => {
    const date = moment(data?.date);
    const isCurrentMonth = date.year() === moment().year() && date.month() === month;
    const isToday = moment(data?.date).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY');
    const onClick = data?.status === 'wrong' ? () => showDayInfo(data) : () => {};
    return <Box className={[styles.calendarCell, styles[data?.status]].join(' ')} onClick={onClick}>
      <Box className={styles.calendarCellDay}>
        <Box className={[
          styles.calendarCellDayContent,
          isCurrentMonth ? styles.currentMonth : '',
          isToday ? styles.today : '',
          ].join(' ')}>{date.format('D')}</Box>
      </Box>
      {!isEmpty(data?.details) ? (
        <Box className={styles.calendarCellDetails}>
          {Object.keys(data?.details)?.map((detail, iCell) => (
            <Fragment key={`iCell-${iCell}`}>
              <span className={styles.calendarDetail}>{detail?.toUpperCase()}: <span>{data?.details?.[detail]}</span></span>
            </Fragment>))}
        </Box>)
      : ''}
    </Box>
  }

  return <Box className={styles.auditCalendar}>
    <Box className={styles.calendarContainer}>
      <Box className={styles.calendarMonthsControl}>
        {months[currentMonth]}
      </Box>
      <Box className={styles.calendarContent}>
        <Box className={styles.calendarHeader}>
          {daysHeaders?.map((header, i) => (<Box key={i} className={styles.calendarHeaderCell}>{header}</Box>))}
        </Box>
        {chunk(data, daysHeaders?.length)?.map((daysRow, iRow) => (
          <Box key={`iRow-${iRow}`} className={styles.calendarCellRow}>
            {daysRow?.map((day, iDay) => (
              <CalendarCell key={`iDay-${iDay}`} data={day} />
            ))}
          </Box>
        ))}
      </Box>
    </Box>
    <Dialog fullWidth maxWidth="sm" open={dayInfo.open} onClose={() => setDayInfo({ ...dayInfo, open: false })}>
      <DialogTitle className={"p-8 flex justify-center font-bold"}><div className='font-bold'>{dayInfo?.title}</div></DialogTitle>
      <div className={"px-8"}>    
        <div className={styles.modalSummary}>
          {Object.keys(dayInfo.summary)?.map(key => (
            <p key={`info-${key}`}>
              <span className={"font-bold uppercase"}>{key}</span>: <span>{dayInfo.summary[key]}</span>
            </p>
          ))}
        </div>   
        <div className={styles.modalDayInfo}>
          {dayInfo?.attempts?.map((attempt, i) => (
            <div className='mt-2 mx-4 mb-4' key={`attempt-${i}`}>
              <p className='font-bold'>{`Intento ${i + 1}`}</p>
              <p className={'ml-4'}><span className={"font-bold capitalize"}>Inicio</span>: <span>{moment(attempt?.start).format('HH:mmA')}</span></p>
              <p className={'ml-4'}><span className={"font-bold capitalize"}>Final</span>: <span>{moment(attempt?.end).format('HH:mmA')}</span></p>
              {!isEmpty(attempt?.steps) ? (
                <>
                  <p className={'ml-4'}><span className={"font-bold capitalize"}>Pasos</span>:</p>
                  {attempt?.steps?.map((step, iStep) => (<span key={`step-${iStep}`}><p className={'ml-8'}>{`- ${step}`}</p></span>))}
                </>
              ) : ''}
              <p className={'ml-4'}><span className={"font-bold capitalize"}>Ok</span>: <span>{attempt?.ok}</span></p>
              <p className={'ml-4'}><span className={"font-bold capitalize"}>Errores</span>: <span>{attempt?.errors}</span></p>
              {!isEmpty(attempt?.errorDetails) ? (
                <>
                  <p className={'ml-8'}><span className={"font-bold capitalize"}>Tipos</span>:</p>
                  {Object.entries(attempt?.errorDetails)?.map(([key, error]) => (
                    <span key={`error-${key}`}>
                      <p className={'ml-8'}>{`* ${key}`}</p>
                      { error?.details?.message ? 
                        <>
                          <p className={'ml-12'}><span className={"font-bold capitalize"}>- Mensaje: </span>{error?.details?.message || 'N/A'}</p>
                          <p className={'ml-12'}><span className={"font-bold capitalize"}>- Motivo: </span>{error?.details?.reason || 'N/A'}</p>
                        </>
                      : error.details.errors.length > 0 ? 
                        error.details.errors.map( rowError => (
                          (rowError?.rowId) ? 
                            <>
                              <p className={'ml-12'}><span className={"font-bold capitalize"}>- Linea: </span>{rowError?.rowId || 'N/A'}</p>
                              <p className={'ml-12'}><span className={"font-bold capitalize"}>- Motivo: </span>{rowError?.error || 'N/A'}</p>
                            </>  
                          : '' 
                        )) 
                        : 'NA'
                      }
                      
                    </span>
                  ))}
                </>
              ) : ''}
            </div>
          ))}
        </div>   
        <DialogActions style={{ justifyContent: 'center', margin: '16px 0px' }} >
          <Button
            text='Salir'
            onClick={() => setDayInfo({ ...dayInfo, open: false })}
          />
        </DialogActions>
      </div>
    </Dialog>
  </Box>
};
export default AuditCalendar