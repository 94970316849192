import _ from 'lodash';
import React from "react";
import { Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { isEmpty } from 'lodash';
import moment from 'moment';
import { getImageUrl } from '../../../utils/common';
import NotificationSection from "../sections/notification-section/notification-section";

const ProfileSection = (props) => {
  const { user } = props;
  const { politicallyExposed } = user;
  const { family, ...ownInfo } = (politicallyExposed || {})
  const { t } = useTranslation();

  let userAge = '-'
  let dateOfBirthFormat = '-'
  if (user?.dateOfBirth && !_.isEmpty(user?.dateOfBirth) && /T/.test(user?.dateOfBirth)) {

    const onlyDate = user?.dateOfBirth.split('T')[0]; //Get only date portion
    const dateOfBirthDate = moment(onlyDate);

    dateOfBirthFormat = dateOfBirthDate.format("DD/MM/YYYY");
    userAge = moment().diff(dateOfBirthDate, 'years');
  }
  const baseUrl = 'https://storage.cloud.google.com';
  const originalUrl = baseUrl + getImageUrl(user?.photoPath)?.split('https://storage.googleapis.com')[1];
  const modifiedUrl = originalUrl.replace('googleapis', 'cloud.google');


  return (
    <Paper elevation={3} className={'col-span-2'}>
      <div className={"col-span-1 flex flex-row space-between-columns justify-between"}>
        <div className={"col-span-1 flex flex-row gap-2 p-8 space-between-columns"}>
        <div style={{ height: 'fit-content' }} className={"border border-solid"}>
             {user?.photoPath && (
             <img className={"w-32 h-44"} alt={"profile"} src={modifiedUrl} />
            )}
        </div>
          <div className={"flex flex-col gap-2"}>
            <div className={"font-bold uppercase"}>
              {user?.firstName} {user?.lastName}
            </div>
            <div className={"flex flex-row gap-1"}>
              <span>DUI:</span>
              <Typography
                variant={"inherit"}
                className={"hover:underline"}
                color={"primary"}
              >
                <a
                  href={user.photoPath}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  {user?.dui}
                </a>
              </Typography>
            </div>
            <div className={"flex flex-row gap-1"}>
              <div>Estado:</div>
              <div className={"font-bold"}>
                {t(`profile.status.${user?.isBlocked === true ? 'inactive' : user?.status?.toLowerCase()}`)}
              </div>
            </div>
            <div>
              {t('profile.labels.dateOfBirth')}: {dateOfBirthFormat} - {userAge !== '-' ? (userAge + ' ' + t('profile.labels.years')) : userAge}
            </div>
            <div>{t('profile.labels.gender')}: {user.gender ? t(`profile.gender.${user?.gender.toLowerCase()}`) : '-'}</div>
            <div className={"flex flex-col"}>
              <div className={"flex gap-1"}>
                <div>{t('profile.labels.politicallyExposedPerson')}:</div>
                <div className={"font-bold"}>{isEmpty(politicallyExposed) ? 'No' : 'Si'}</div>
              </div>
              {!isEmpty(politicallyExposed) && (
                <>
                  {!isEmpty(ownInfo) && (
                    <>
                      <div className={"flex gap-1 p-1 pl-2"}>
                        <div className={"font-bold"}>{t('profile.labels.position')}:</div>
                        <div>{ownInfo?.position}</div>
                      </div>
                      <div className={"flex gap-1 p-1 pl-2"}>
                        <div className={"font-bold"}>{t('profile.labels.period')}:</div>
                        <div>{moment(ownInfo?.startDate).format('DD/MM/YYYY')} - {ownInfo?.endDate ? moment(ownInfo?.endDate).format('DD/MM/YYYY') : 'Actualmente'}</div>
                      </div>
                    </>
                  )}
                  {!isEmpty(family) && (
                    <>
                      <div className={"font-bold p-1 pl-2"}>{t('profile.labels.family')}:</div>
                      <div className={"flex flex-col gap-1 pl-4"}>
                        <div className={"flex gap-1"}>
                          <div className={"font-bold"}>{t('profile.labels.name')}:</div>
                          <div>{family?.fullName}</div>
                        </div>
                        <div className={"flex gap-1"}>
                          <div className={"font-bold"}>{t('profile.labels.relation')}:</div>
                          <div>{family?.relation}</div>
                        </div>
                        <div className={"flex gap-1"}>
                          <div className={"font-bold"}>{t('profile.labels.position')}:</div>
                          <div>{family?.position}</div>
                        </div>
                        <div className={"flex gap-1"}>
                          <div className={"font-bold"}>{t('profile.labels.period')}:</div>
                          <div>{moment(family?.startDate).format('DD/MM/YYYY')} - {family?.endDate ? moment(family?.endDate).format('DD/MM/YYYY') : 'Actualmente'}</div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className={"flex flex-row gap-2 p-8 items-end"}>
          <NotificationSection user={user} />
        </div>
      </div>
    </Paper>
  );
};

export default ProfileSection;
