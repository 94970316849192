import firebase from 'firebase';
import * as qs from 'query-string';
import Model from '../hooks/Model';
import i18n from './I18n';

const queryParamFormat = (location, validOptions = new Set([])) => {
  const response = { filterBy: '', searchText: ''};
  try {
    const search = location.substring(1);
    const paramsObject = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
    const [ filterBy ] = Object.keys(paramsObject);
    if (validOptions.has(filterBy)) {
      return { filterBy, searchText: paramsObject[filterBy] };
    }
    return response;
  } catch (e) {
    return response;
  }
}

const getQueryParams = (queryString = '') => qs.parse(queryString)

const logoutSession = async () => {
  await Model.setData("opacity", 1);
  await Model.setData("loading", true);
  await firebase.auth().signOut();
  await Model.resetData();
  await Model.setData("uid", "");
  await Model.setData("loading", false);
};

const getImageUrl = (photoUrl) => photoUrl ? `${photoUrl}?t=${(new Date()).getTime()}` : photoUrl;

const validatePhysicalSignature = (status) =>
  ['pending_approval', 'upgrade_request', 'upgrade_qualified'].includes(status)


const getFilterError = (filters = { filter: false, value: false }) => {
  const { filter, value } = filters;
  if(!filter && !value) return i18n.t('common.filterErrors.allFiltersEmpty');
  if(!filter) return i18n.t('common.filterErrors.typeFilterEmpty');
  if(!value) return i18n.t('common.filterErrors.valueFilterEmpty');
  return i18n.t('common.filterErrors.allFiltersEmpty');
}


export {
  queryParamFormat,
  getQueryParams,
  logoutSession,
  getImageUrl,
  validatePhysicalSignature,
  getFilterError
}