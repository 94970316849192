import api from "./api";

const upgrade = (userId, level) => {
  const body = {
    id: userId,
    level
  };
  return api.post('/credits/upgrade', body);
}


export default {
  upgrade
}