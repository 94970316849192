import moment from "moment-timezone";
import api from "./api";

export const getAuditData = (range = 30) => {
  const headers = { 'x-timezone': moment.tz.guess() };
  return api.get(`/crons/summary/processCreditBalancesWithDetails?predefinedRange=${range}`, {}, { headers });
};


export default {
  getAuditData
};
