import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Store } from '../../../../../hooks/main_store';
import LevelsService from "../../../../../services/levels";

const ApprovedAction = (props) => {
  const { user } = props;
  const { t } = useTranslation()
  const { state } = React.useContext(Store);
  const levelsDesc = _.orderBy(user.levelHistory, ["level"], ["desc"]);
  const currentLevel = levelsDesc.shift();
  const levelDate = new Date(currentLevel.createdAt)
  const date = new Date();
  const diffTime = Math.abs(date - levelDate);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const [levels, setLevels] = useState({ data: [], isLoading: false });

  useEffect(() => {
    (async () => {
      const response = await LevelsService.getLevels();
      setLevels({ data: response.data.data, isLoading: false });
    })();
  }, [])

  const approvedMessage = (level) => {
    const levelInfo = levels.data.find(l => l.level === level);
    const showPSMessage = state.hasAdminPolicy && levelInfo && levelInfo?.physicalSignature;
    return `${t('users.clientHasAssignedToLevel')} ${level} ${showPSMessage ? t('users.clientHasLevelWithPhysicalSignature') : t('users.clientHasNotSentDocsToValidate')}`
  }

  return (
    <Paper elevation={3} className={"flex flex-col justify-between gap-4 p-8"}>
      {!state.hasTestPolicy &&
        <>
          <div className={"text-center"}>
            <div className={"text-5xl font-bold"}>{diffDays} {t("users.days")}</div>
            <div>{t("users.sinceCreditApproved")}</div>
          </div>
          <div className={"text-justify"}>
            {approvedMessage(user?.level)}
          </div>
        </>
      }

    </Paper>
  );
};

export default ApprovedAction;
