import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import { Dialog } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogTitle } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";

const ValidatePhysicalSignatureModal = ({ title = '', message = '', open = false, onCloseHandle = () => {}, onAcceptHandle = () => {} }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(open);
  const onClose = () => {
    setOpen(false);
    if(onCloseHandle) {
      onCloseHandle();
    }
  }
  const onAccept = () => {
    setOpen(false);
    if(onAcceptHandle) {
      onAcceptHandle();
    }
  }

  return (
    <Fragment>
      <Dialog open={isOpen} onClose={onCloseHandle}>
        <DialogTitle className={"p-8"}>{title || t("users.validatePhysicalSignatureTitle")}</DialogTitle>
        <Divider />
        <div className="p-8">
          { message || 
            <div>
              <p style={{paddingBottom:'20px'}}>{t("users.validatePhysicalSignatureMsgP1")}</p>
              <p style={{paddingBottom:'20px'}}>{t("users.validatePhysicalSignatureMsgP2")}</p>
              <p>{t("users.validatePhysicalSignatureMsgP3")}</p>  
            </div>  
          }
        </div>
        <DialogActions>
          <Button onClick={onClose} color="primary">{t('common.cancel')}</Button>
          <Button onClick={onAccept} color="primary">{t('common.accept')}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ValidatePhysicalSignatureModal;
