import React from 'react'
import { Box } from '@material-ui/core'
import useStyles from './styles/buttonsStyles'
import PropTypes from 'prop-types'

const Button = (props) => {
  const styles = useStyles();
  return <Box
    className={[
      styles.container,
      props.fullWidth ? styles.fullWidth : '',
      props.customStyles,
      props.withoutBorder ? styles.noBorder : '',
      styles[props.variant]
    ].join(' ')}
    onClick={props.onClick}
  >
    <p className={styles.lblButton}>
      {props.text}
    </p>
    {props.iconRight ? <span className={[styles.iconRightButton, props.iconRight].join(' ')}>
    </span> : null}
  </Box>
};

Button.defaultProps = {
  fullWidth: false,
  customStyles: '',
  text: '',
  withoutBorder: false,
  variant: 'primary',
  onClick: () => {},
  iconRight: ''
};

Button.propTypes = {
  fullWidth: PropTypes.bool,
  customStyles: PropTypes.string,
  text: PropTypes.string,
  withoutBorder: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary', 'light', 'danger', 'alert']),
  iconRight: PropTypes.string,
  onClick: PropTypes.func
};

export default Button