import { makeStyles } from '@material-ui/styles';
import Commons from '../../../utils/commons/styles';
import { colors } from '../../../styleguide';

const styles = makeStyles({
  contBg: {
    width: '100%',
    height: '100%',
    overflow:' auto',
    backgroundColor: colors.white,
    minWidth: 880
  },
  container: {
    margin: '0 auto',
    padding: 0,
    minHeight: '100%',
    minWidth: '100%',
    display: 'initial'
  },
  rowTitle: {
    ...Commons.flexRow,
    padding: '0px 18px',
    position: 'relative',
    flexWrap: 'wrap',
    paddingTop: 18
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    margin: 0,
    marginRight: 30
  },
  colInter: {
    flex: 1,
    fontSize: 12
  },
  containerCards: {
    ...Commons.flexRow,
    padding: 30,
    flexWrap: 'wrap',
  },
  emptyTxt: {
    textAlign: 'center',
    margin: '30px 0px'
  },
  contCard: {
    backgroundColor: colors.white,
    boxShadow: colors.shadow2,
    borderRadius: 6,
    margin: '24px 24px 0px 0px',
    width: 'calc(25% - 24px)',
    overflow: 'hidden',
    maxWidth: 200
  },
  headerCard: {
    ...Commons.flexCenter,
    padding: '12px 0px',
    cursor: 'pointer'
  },
  lblLevel: {
    fontWeight: 'bold',
    fontSize: 18
  },
  lblAmount: {
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 170,
    lineHeight: 1.1
  },
  iconEdit: {
    fontSize: 18,
    marginLeft: 12
  },
  iconPhysicalSignature: {
    fontSize: 18,
    marginLeft: '85%',
    position: 'absolute',
  },
  rowColsCard: {
    ...Commons.flexRow,
    alignItems: 'flex-start'
  },
  colCard: {
    flex: 1,
    width: 'calc(100% / 3)',
    height: 66,
    borderRight: `1px solid ${colors.mainxText}`,
    overflow: 'hidden',
    '&:last-child': {
      border: 'none'
    }
  },
  titleColCard: {
    backgroundColor: colors.mainxText,
    color: colors.white,
    fontSize: 10,
    padding: '6px 0px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  dataColCard: {
    ...Commons.flexCenter,
    ...Commons.flexColumn,
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
    height: 42,
    '& span': {
      fontWeight: 'normal',
      fontSize: 10
    }
  },
  rowStatsCard: {
    ...Commons.flexRow,
    backgroundColor: colors.mainxText,
    padding: '18px 6px'
  },
  wrapperCircle: {
    position: 'relative',
  },
  whiteCircleCard: {
    ...Commons.flexCenter,
    ...Commons.flexColumn,
    backgroundColor: colors.white,
    width: 66,
    height: 66,
    borderRadius: '50%',
    position: 'absolute',
    top: 7,
    left: 6
  },
  numLines: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: 16
  },
  lblLines: {
    margin: 0,
    fontSize: 10
  },
  rowLbls: {
    marginLeft: 12,
    flex: 1,
  },
  rowLabel: {
    ...Commons.flexRow,
    justifyContent: 'flex-end'
  },
  colorLbl: {
    width: 8,
    height: 8,
    backgroundColor: colors.primary
  },
  txtLbl: {
    margin: '0px 0px 0px 6px',
    color: colors.white,
    fontSize: 12
  },
  valueLbl: {
    margin: 0,
    textAlign: 'right',
    color: colors.white,
    fontSize: 24,
    fontWeight: 'bold'
  },
  footerCard: {
    backgroundColor: colors.mainxText,
    padding: '0px 6px',
  },
  borderFooter: {
    ...Commons.flexCenter,
    borderTop: `1px solid ${colors.gray50}`,
  },
  lblFooterCard: {
    margin: 0,
    color: colors.white,
    fontSize: 10,
    flex: 1,
    marginRight: 6,
    padding: '12px 0px'
  },
  valueFooterCard: {
    fontWeight: 'bold',
    fontSize: 18,
    color: colors.white
  },
  colorGreen: {
    '& svg': {
      color: colors.primary,
    }
  }
});

export default styles;