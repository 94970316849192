const styles = {
  flexColumn: {
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
  },
  flexRow: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
  },
  flexCenter: {
    justifyContent: 'center',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
  },
  coverBackground: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttonAction: {
    backgroundColor: '#787F87',
    color: 'white',
    padding: '6px 24px',
    width: 'fit-content',
    fontSize: 12,
    fontWeight: 'bold',
    cursor: 'pointer',
    border: '1px solid #787F87'
  }
};

export default styles;