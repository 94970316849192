import _ from 'lodash'
import React from 'react'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import {Visibility, VisibilityOff} from '@material-ui/icons'
import { verifyEmail } from '../../utils/validations'
import fileStyles from './styles/authStyles'
import AuthService from '../../services/auth'
import firebase from '../../hooks/firebase'
import Input from '../../components/input'
import Button from '../../components/button'
import Model from '../../hooks/Model'

const LoginPage = (props) => {
  const styles = fileStyles()
  const { t } = useTranslation();
  const [vc, setVc] = React.useState({
    showPassword: false,
    username: '',
    password: '',
    email: '',
  });

  const changeEye = () => {
    setVc(oldValues => ({
      ...oldValues,
      showPassword: !oldValues.showPassword
    }))
  }

  const goTo = (route) => {
    props.history.push(route)
  }

  const emailLoginFirebase = async () => {
    try {
      Model.setData('loading', true)
      const result = await AuthService.emailFirebaseLogin(vc.email, vc.password)
      await Model.setData('uid', result.uid)
      goTo('/dashboard')
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: _.get(e, 'message', t('common.unknownError')),
        variant: 'error'
      })
    }
  }

  const submitForm = () => {
    const { email, password } = vc;
    if (!email || !password) {
      Model.updateAlerts({
        message: t('common.pleaseFill'),
        variant: 'error'
      })
    } else if(verifyEmail(email)) {
      emailLoginFirebase()
    } else {
      Model.updateAlerts({
        message: t('common.errorEmail'),
        variant: 'error'
      })
    }
  }

  const changeState = name => event => {
    event.persist()
    let auxVal = event.target.value
    if (name === 'email') {
      auxVal = auxVal.replace(/\s/, '')
    }
    setVc(oldValues => ({
      ...oldValues,
      [name]: auxVal
    }))
  }

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        goTo('/dashboard')
      } else {
        Model.setData('loading', false)
        Model.setData('opacity', 0.7)
      }
    })
    return () => {}
    //eslint-disable-next-line
  }, []);

  return <Box className={styles.container}>
    <img
      src={require('../../assets/images/bg-color.png')}
      className={styles.bgColor}
      alt='Unicomer'
    />
    <Box className={styles.content}>
      <img
        src={require('../../assets/images/logo.svg')}
        className={styles.logo}
        alt='Unicomer'
      />
      <p className={styles.title}>
        {t('login.welcome')}
      </p>
      <p className={styles.label}>
        {t('login.enterAccount')}
      </p>
      <Input
        placeholder={t('common.email')}
        onChange={changeState('email')}
        value={vc.email}
        fullWidth
        customStyles={styles.input}
      />
      <Box className={styles.passCont}>
        <Input
          type={vc.showPassword ? 'text' : 'password'}
          placeholder={t('common.password')}
          onChange={changeState('password')}
          value={vc.password}
          fullWidth
          customStyles={styles.inputPass}
        />
        <Box onClick={changeEye} className={styles.eyeBt}>
          {!vc.showPassword ? <Visibility /> : <VisibilityOff/>}
        </Box>
      </Box>
      <Button
        text={t('common.forgotPassword')}
        variant='primary'
        customStyles={styles.forgotPassBtn}
        fullWidth
        onClick={() => goTo('/forgotPassword')}
      />
      <Button
        text={t('common.enter')}
        variant='primary'
        customStyles={styles.sendBt}
        fullWidth
        onClick={submitForm}
      />
    </Box>
  </Box>
}

export default LoginPage;
