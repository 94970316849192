import React from "react";
import { useState } from "react";

import UsersService from "../../../../../services/users";
import LoadingButton from "../../../../../components/loading-button";
import { Box } from "@material-ui/core";

const BlockPurchaseButton = (props) => {
  const { user, reFetch } = props;
  const isBlocked = ['invalidated'].includes(user?.status)
  const hide = ['pending_approval','approved','commited', 'pending_validation', 'blocked', 'indebted', 'cancelled'].includes(user?.status) || user?.isBlocked === true
  const [status, setStatus] = useState({ isLoading: false, data: undefined });

  const handleOnClickBlockButton = async () => {
    const action = ['invalidated'].includes(user?.status) ? "activate" : "inactivate";
    setStatus((prevState) => ({ ...prevState, isLoading: true }));
    UsersService.changeStatus(user.id, action).then((response) => {
      setStatus(() => ({ data: response.data, isLoading: false }));
      reFetch((prev) => prev + 1);
    });
  };

  return (
    <Box display={hide? 'none' : 'block'}>
    <LoadingButton
      type={"submit"}
      color={"secondary"}
      variant={"contained"}
      loadingLabel={"procesando"}
      isLoading={status.isLoading}
      onClick={handleOnClickBlockButton}
      label={isBlocked ? "Habilitar Compras" : "Deshabilitar Compras"}
    />
    </Box>
  );
};

export default BlockPurchaseButton;
