import React from "react";
import { Fragment } from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { Divider } from "@material-ui/core";
import { ListItem } from "@material-ui/core";

const NotificationListItem = (props) => {
  const { notification } = props;
  const title = `${notification.notification.title}`;
  const message = `${notification.notification.body}`;
  const date = new Date(notification.createdAt);
  const year = format(date, "yyyy");
  const month = format(date, "MMM", { locale: es });
  const day = format(date, "dd");
  const hours = format(date, "p");
  return (
    <Fragment>
      <ListItem alignItems="flex-start">
        <div className={"grid grid-cols-5 gap-4 w-full"}>
          <div className={"text-center flex flex-col justify-center"}>
            <div className={"text-xl font-bold"}>{day}</div>
            <div className={"text-base text-gray-400 capitalize"}>{month}</div>
            <div className={"text-sm text-gray-400"}>{year}</div>
          </div>
          <div className={"col-span-4"}>
            <div className={"text-xl font-bold"}>{title}</div>
            <div>{message}</div>
            <div className={"text-xs mt-2 text-gray-400"}>{hours}</div>
          </div>
        </div>
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </Fragment>
  );
};

export default NotificationListItem;
