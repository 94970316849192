import React, { useEffect, useState } from "react";
import PartnersTableRow from "./partners-table-row";
import { Button } from "@material-ui/core";
import partnersService from './../../../../../services/partners';
import DialogFormComponent from "../../../../../components/dialogForm";
import { useTranslation } from "react-i18next";
import Model from "../../../../../hooks/Model";
import { Store } from "../../../../../hooks/main_store";

const PartnersTable = (props) => {
  const { t } = useTranslation();
  const { user } = props;
  const [partners, setPartners] = useState([]);
  const [availablePartners, setAvailablePartners] = useState([]);
  const [openModal, setOpenModal] = React.useState(false)
  const { state } = React.useContext(Store);
  const [vc, setVc] = React.useState({
    newCode: '',
    newPartner: '',
  })
  const changeVc = (value) => setVc(ov => ({ ...ov, ...value }))

  const firstLoad = async () => {
    const [partners, availablePartners] = await Promise.all([
      partnersService.getUserPartners(`?page=1&limit=10&userId=${user?.id}`),
      partnersService.getUserPartners(`?page=1&limit=10&userId=${user?.id}&exclude=true`)
    ])
    setPartners(partners?.data?.data);
    setAvailablePartners(availablePartners?.data?.data);
  }

  useEffect(() => {
    if (user?.id) {
      firstLoad()
    }
    // eslint-disable-next-line
  }, [])

  const getPartnersData = (partners = []) => {
    return partners?.map(partner => ({
      beneficiaryId: partner?.id,
      clientBeneficiaryId: partner?.clientBeneficiaryId,
      name: partner?.name,
      code: partner?.clientCode,
      status: partner?.clientBeneficiaryStatus,
      updatedAt: partner?.updatedAt
    }));
  }

  const onAddPartner = async () => {
    try {
      setOpenModal(false)
      Model.setData("loading", true);
      const { newCode, newPartner } = vc;
      const payload = {
        userId: user?.id,
        clientCode: newCode,
        beneficiaryId: newPartner
      }

      if (Object.values(payload).some(v => !v)) {
        throw new Error(t('common.notEmptyFields'))
      }
      const response = await partnersService.createUserPartner(payload)
      if (response.ok) {
        await firstLoad()
      } else {
        throw new Error(
          t(`partners.clientPartners.errors.${response?.data?.message}`, { defaultValue: t('common.partnerCodeError') })
          || response?.data?.message
          || response?.problem
        );
      }
      Model.setData("loading", false);
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      changeVc({ newCode: '', newPartner: '' })
    }
  }

  return (
    <>
      <div className={"col-span-2 pr-0 overflow-auto"}>
        {partners?.length ? (
          <table className={"table-auto w-full"}>
            <thead>
              <tr className={"text-center font-bold uppercase h-7"}>
                <th></th>
                <th>{t('partners.name')}</th>
                <th>{t('partners.code')}</th>
                <th>{t('partners.updatedAt')}</th>
              </tr>
            </thead>
            <tbody>
              <PartnersTableRow user={user} reFetch={firstLoad} data={getPartnersData(partners)} availablePartners={availablePartners} />
            </tbody>
          </table>
        ) : t('partners.clientPartners.emptyMsg')}
      </div>
      {availablePartners?.length ? (
        <>
          <div className={"flex flex-row justify-end"}>
            {!state.hasTestPolicy &&
              <>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  children={t('partners.clientPartners.addPartner')}
                  onClick={() => setOpenModal(true)}
                />
              </>
            }

          </div>
          <DialogFormComponent
            show={openModal}
            title={'Adicionar Proveedor'}
            text='Por favor ingresar todos los datos que se solicitan'
            leftBtText='Cancelar'
            rightBtText='Guardar'
            funcLeft={() => {
              changeVc({ newCode: '', newPartner: '' })
              setOpenModal(false)
            }}
            funcRight={onAddPartner}
            inputs={[
              { label: 'Código', value: 'newCode' },
              { label: 'Proveedor', value: 'newPartner', values: (availablePartners || []).map(p => ({ value: p.id, label: p.name })), type: 'select' },
            ]}
            vc={vc}
            changeVc={changeVc}
          />
        </>
      ) : ''}
    </>
  );
};

export default PartnersTable;
