import _ from 'lodash'
import React from 'react'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import fileStyles from './styles/authStyles'
import UsersService from '../../services/users'
import Input from '../../components/input'
import Button from '../../components/button'
import Model from '../../hooks/Model'

const ForgotPassword = (props) => {
  const styles = fileStyles()
  const { t } = useTranslation();
  const [vc, setVc] = React.useState({
    email: '',
  });

  const goTo = (route) => {
    props.history.push(route)
  }

  const submitForm = async () => {
    try {
      Model.setData('loading', true)
      const result = await UsersService.forgotPassword({ email: vc.email })
      if (result.ok) {
        goTo('/')
        Model.updateAlerts({
          message: t('login.doneForgotpass'),
          variant: 'success'
        })
      } else {
        throw result.data
      }
    } catch (e) {
      console.log(e)
      Model.setData('loading', false)
      Model.updateAlerts({
        message: _.get(e, 'message', t('common.unknownError')),
        variant: 'error'
      })
    }
  }

  const changeState = name => event => {
    event.persist()
    setVc(oldValues => ({
      ...oldValues,
      [name]: event.target.value
    }))
  }

  return <Box className={styles.container}>
    <img
      src={require('../../assets/images/bg-color.png')}
      className={styles.bgColor}
      alt='Unicomer'
    />
    <Box className={styles.content}>
      <img
        src={require('../../assets/images/logo.svg')}
        className={styles.logo}
        alt='Unicomer'
      />
      <p className={styles.title}>
        {t('login.forgotPasswordTitle')}
      </p>
      <p className={styles.label}>
        {t('login.lblforgotPassword')}
      </p>
      <Input
        placeholder={t('common.email')}
        onChange={changeState('email')}
        value={vc.email}
        fullWidth
        customStyles={styles.input}
      />
      <Button
        text={t('common.send')}
        variant='primary'
        customStyles={styles.sendBt}
        fullWidth
        onClick={submitForm}
      />
    </Box>
  </Box>
}

export default ForgotPassword;
