import React from 'react'
import { Box, FormControlLabel, Switch } from '@material-ui/core'
import useStyles from './styles/switchStyles'
import PropTypes from 'prop-types'

const Radio = (props) => {
  const styles = useStyles();
  return <Box className={[
    styles.container,
    props.customStyles ? props.customStyles : '',
    styles[props.variant],
  ].join(' ')}>
    <FormControlLabel
      disabled={props.variant === 'disabled'}
      control={<Switch
        checked={props.value}
        onChange={props.onChange}
        name={props.name}
      />}
      label={props.label}
    />
    {props.message ? <p className={styles.message}>
      {props.message}
    </p> : null}
  </Box>
  
};

Radio.defaultProps = {
  onChange: () => {},
  variant: 'normal',
  message: '',
  customStyles: '',
  label: '',
  value: false,
  name: '',
};

Radio.propTypes = {
  variant: PropTypes.oneOf(['normal', 'disabled']),
  customStyles: PropTypes.string,
  message: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string
};

export default Radio