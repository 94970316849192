import React from "react";
import * as yup from "yup";
import { Field } from "formik";
import { useEffect } from "react";
import { useField } from "formik";
import { useFormikContext } from "formik";
import { MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { withSchema } from "../../formik/withSchema.util";

const StatusFields = () => {
  const name = "status";
  const [field] = useField(name);
  const { values, setFieldValue } = useFormikContext();

  const isStatusType = values.type === "status";

  useEffect(() => {
    if (isStatusType) {
      setFieldValue(name, "");
    } else {
      setFieldValue(name, undefined);
    }
  }, [setFieldValue, isStatusType, name]);

  const validationSchema = yup.string().required();

  if (!isStatusType) return null;

  return (
    <Field
      name={name}
      type={"text"}
      select={true}
      label={"Estado"}
      component={TextField}
      value={field.value || ""}
      validate={withSchema(validationSchema)}
    >
      <MenuItem value={"onboarding"}>onboarding</MenuItem>
      <MenuItem value={"validated"}>validated</MenuItem>
      <MenuItem value={"invalidated"}>invalidated</MenuItem>
      <MenuItem value={"cancelled"}>cancelled</MenuItem>
      <MenuItem value={"blocked"}>blocked</MenuItem>
      <MenuItem value={"indebted"}>indebted</MenuItem>
    </Field>
  );
};

export default StatusFields;
