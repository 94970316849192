import { create } from 'apisauce'
import firebase from '../hooks/firebase'
const api = create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
      'Accept': 'application/vnd.openxmlformats'
    },
    timeout: 600000,
    responseType: 'blob'
})

api.axiosInstance.interceptors.request.use(async (config) => {
  // Do something before request is sent
  
  config.headers['x-tenant'] = window.location.hostname;

  if(firebase.auth().currentUser){
    const token = await firebase.auth().currentUser.getIdToken()
  
    if(token){
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  }
  
  
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


api.axiosInstance.interceptors.response.use(undefined, async (error) => {
  const errorResponse =  error.response;

  if (errorResponse && errorResponse.status === 401 && !!error.config &&  typeof error.config.canRetry  === 'undefined' ) {
    if(firebase.auth().currentUser){
      const token = await firebase.auth().currentUser.getIdToken(true)
      error.config.headers['Authorization'] = `Bearer ${token}`;
      // console.warn('retry config headers =>', error.config.headers)
      error.config.canRetry = false;
      return api.axiosInstance.request(error.config)
    }
    
  }
  throw error;
})

export const axios = api.axiosInstance

export default api