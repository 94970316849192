import { makeStyles } from '@material-ui/styles';
import Commons from '../../../utils/commons/styles';
import { colors } from '../../../styleguide';

const pageWidth = '75%';

const styles = makeStyles({
  contBg: {
    width: '100%',
    height: '100%',
    overflow:' auto',
    backgroundColor: colors.white,
    minWidth: 820
  },
  container: {
    margin: '0 auto',
    padding: 0,
    minHeight: '100%',
    minWidth: '100%',
    display: 'initial'
  },
  rowTitle: {
    ...Commons.flexRow,
    margin: '0 18px',
    position: 'relative',
    flexWrap: 'wrap',
    paddingTop: 18,
    justifyContent: 'space-between',
    width: pageWidth
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    margin: 0,
    marginRight: 30
  },
  contInfo: {
    padding: 18,
    margin: 18,
    paddingBottom: 30,
    height: 'fit-content',
    width: pageWidth
  },

  auditDetailsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  auditDetails: {
    width: 250,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 25,
    border: '1px solid lightgrey',
    borderRadius: 15,
    flexDirection: 'column',
    padding: '15px 15px 0px 15px',
    '&:first-child': {
      marginLeft: 0
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  auditDetailsInfo: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  auditDetailsRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  auditDetailsRowButton: {
    display: 'flex',
    width: '100%',
    minHeight: 48,
    justifyContent: 'center',
  },
  auditIcon: {
    backgroundColor: '#10C1BC',
    padding: 12,
    borderRadius: 12,
    margin: 10,
    '&.wrong': {
      backgroundColor: '#F5A623',
    },
    '&.notProcessed': {
      backgroundColor: '#D0021B',
    },
  },
  auditCount: {
    fontSize: 25,
    fontWeight: 'bold'
  },

  daysFilter: {
    width: 200,
    borderRadius: 25,
    '& > *:focus': {
      borderRadius: 25,
      backgroundColor: 'transparent'
    }
  },

});

export default styles;