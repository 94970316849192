const HdValues = (t) => {
  return ([{
    label: t('partners.status'),
    colWidth: 'col1c',
    value: 'status'
  },
  {
    label: t('users.firstName'),
    colWidth: 'col2',
    value: 'firstName'
  }, {
    label: t('users.lastName'),
    colWidth: 'col2',
    value: 'lastName'
  }, {
    label: t('users.email'),
    colWidth: 'col3',
    value: 'email'
  }, {
    label: t('users.firstTimeLogin'),
    colWidth: 'col1',
    value: 'firstTimeLogin'
  }, {
    label: t('users.confirmationMail'),
    colWidth: 'col2',
    value: 'confirmationMail'
  }, {
    label: t('users.newRegistrationMail'),
    colWidth: 'col2',
    value: 'newRegistrationMail'
  }, {
    label: '',
    colWidth: 'col0',
    value: 'btActions'
  }]);
}

export default HdValues