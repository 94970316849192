import React from "react";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

const LoadingButton = (props) => {
  const { label, loadingLabel, isLoading, loadingSize, ...buttonProps } = props;
  return (
    <Button {...buttonProps} disabled={isLoading}>
      <div className={"grid grid-flow-col gap-2"}>
        {isLoading && (<CircularProgress size={loadingSize || 20} color={"inherit"} />)}
        <Typography>{isLoading ? loadingLabel || "" : label}</Typography>
      </div>
    </Button>
  );
};

export default LoadingButton;
