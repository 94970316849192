import React from "react";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";

import colors from "./colors";

const MuiThemeProvider = (props) => {
  const { children } = props;

  const customTheme = createMuiTheme({
    palette: { type: "light", primary: { main: colors.primary } },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MuiThemeProvider;
