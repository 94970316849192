const verifyEmail = function (text) {
  var regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  return regex.test(text)
}

const emptyObj = (obj) => {
  let flag = false
  Object.keys(obj).forEach((key) => {
    if (!obj[key]) {
      flag = true
    }
  })
  return flag
}

const limitNum = (value) => {
  return value ? (parseFloat(value)).toFixed(2) : value
}

export {
  verifyEmail,
  emptyObj,
  limitNum
}