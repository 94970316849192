const HdValues = (t) => {
  //[createdAt, code, orderId, withdrawalAt, amount]
  return ([ {
    label: t('users.status'),
    colWidth: 'col1c',
    value: 'status'
  }, {
    label: t('users.withdrawalAt'),
    colWidth: 'col2',
    value: 'withdrawalAt'
  }, {
    label: `${t('ordersList.orderId')} / ${t('ordersList.orderBranchOffice')}`,
    colWidth: 'col2',
    value: 'orderId'
  }, {
    label: t('users.user'),
    colWidth: 'col2',
    value: 'user'
  }, {
    label: t('users.tradeName'),
    colWidth: 'col2',
    value: 'tradeName'
  }, {
    label: t('ordersList.confirmationCode'),
    colWidth: 'col2',
    value: 'code'
  }, {
    label: t('ordersList.clientCode'),
    colWidth: 'col2',
    value: 'clientCode'
  }, {
    label: t('ordersList.productId'),
    colWidth: 'col2',
    value: 'productId'
  }, {
    label: t('users.amount'),
    colWidth: 'col2',
    value: 'amount'
  }, {
    label: t('users.dui'),
    colWidth: 'col2',
    value: 'dui'
  }, {
    label: t('ordersList.usedExtraCredit'),
    colWidth: 'col2',
    value: 'usedExtraCredit'
  }, {
    label: '',
    colWidth: 'col1',
    value: 'btActions'
  }
  //, {
  //   label: t('users.firstName'),
  //   colWidth: 'col1',
  //   value: 'firstName'
  // }, {
  //   label: t('users.lastName'),
  //   colWidth: 'col1',
  //   value: 'lastName'
  // }, {
  //   label: t('users.dui'),
  //   colWidth: 'col1',
  //   value: 'dui'
  // }, {
  //   label: t('users.tradeName'),
  //   colWidth: 'col1',
  //   value: 'tradeName'
  // }, {
  //   label: t('users.cellPhone'),
  //   colWidth: 'col1',
  //   value: 'cellPhone'
  // }, 
]);
}

export default HdValues