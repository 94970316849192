import { makeStyles } from '@material-ui/styles';
import { colors } from '../../../../../styleguide';

const styles = makeStyles({
  addBtn: {
    maxWidth: 165,
    marginTop: 40
  },
  iconStatus: {
    fontSize: 24,
    color: colors.mainxText
  },
  colorOrange: {
    color: colors.orange
  },
  colorGreen: {
    color: colors.primary
  },
  colorYellow: {
    color: colors.yellow
  },
  colorRed: {
    color: colors.red
  },
  colorBlue: {
    color: colors.blue
  },
  btMoreAction: {
    backgroundColor: colors.primary,
    color: colors.white,
    textAlign: 'center',
    fontSize: 12,
    padding: '12px 8px',
    fontWeight: 'bold',
    cursor: 'pointer',
    opacity: 0.75,
    '&:hover': {
      opacity: 1
    }
  },
});

export default styles;