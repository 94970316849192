import React from "react";
import * as yup from "yup";
import { Form } from "formik";
import { Formik } from "formik";
import { useState } from "react";

import UserService from "../../services/users";
import NotificationFields from "./form-fields/notification-fields";
import NotificationSubmit from "./form-fields/notification-submit";

const NotificationForm = () => {
  const [isSubmitLoading, setSubmitLoading] = useState(false);

  const initialValue = {
    type: "",
    notification: {
      title: "",
      body: "",
    },
  };

  const validationSchema = yup.object({
    type: yup.string().required(),
    notification: yup
      .object({
        title: yup.string().required(),
        body: yup.string().required(),
      })
      .required(),
  });

  const handleOnSubmit = async (values, formikBag) => {
    setSubmitLoading(true);
    await UserService.pushNotification(values);
    formikBag.resetForm();
    setSubmitLoading(false);
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <Form className={"grid grid-cols-1 gap-8"}>
          <NotificationFields />
          <NotificationSubmit isLoading={isSubmitLoading} />
        </Form>
      </Formik>
    </div>
  );
};

export default NotificationForm;
