import React from "react";
import CreateNotificationModal from "./components/createNotificationModal/create-notification-modal";
import ListNotificationModal from "./components/listNotificationModal/list-notification-modal";

const NotificationSection = (props) => {
  const { user } = props;
  
  return (
    <div className={"flex flex-col gap-4 p-8"}>
      <div className={"h-full flex flex-col gap-4 px-8"}>
        {<CreateNotificationModal user={user} />}
        {<ListNotificationModal user={user}/>}
      </div>
    </div>
  );
};

export default NotificationSection;
