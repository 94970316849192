import React from "react";
import {
  AccordionSummary,
  Box,
  Input,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TablePagination,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  ArrowDropDown,
  ArrowDropUp,
  Assignment,
  AssignmentInd,
  AssignmentLate,
  Autorenew,
  Cached,
  Cancel,
  CheckCircle,
  Help,
  Lock,
  PanTool,
  Search,
  Warning,
} from "@material-ui/icons";
import DialogForm from "../../components/dialogForm";
import UsersService from "../../services/users";
import LevelsService from "../../services/levels";
import { useTranslation } from "react-i18next";
import fileStyles from "./styles/usersStyles";
import Model from "../../hooks/Model";
import { Store } from "../../hooks/main_store";
import Layout from "../layout";
import HdValues from "./hdValues";
import Button from "../../components/button";
import { /*emptyObj,*/ limitNum } from "../../utils/validations";
import { getFilterError, queryParamFormat } from "../../utils/common";
import _ from "lodash";
import moment from "moment";
import NotificationService from "./components/notification-service";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const numberFilters = ['approvedAmount', 'savedAmount', 'availableAmount', 'spentAmount', 'aov', 'GAPRegisterApproval', 'level'];
const numberRangeFilters = ['approvedAmount', 'savedAmount', 'availableAmount', 'spentAmount', 'aov', 'GAPRegisterApproval'];
const dateFilters = ['approvalDate', 'firstOrderDate', 'paymentDueDate'];

const Users = ({ history, location }) => {
  const { state } = React.useContext(Store);
  const { t } = useTranslation();
  const headers = HdValues(t);
  const styles = fileStyles();
  const iconsTool = {
    onboarding: {
      icon: Autorenew,
      style: styles.colorOrange,
      text: t("users.onboardingHelp"),
    },
    pending: {
      icon: Cached,
      style: styles.colorBlue,
      text: t("users.pendingHelp"),
    },
    approved: {
      icon: Assignment,
      style: styles.colorGreen,
      text: t("users.approvedHelp"),
    },
    committed: {
      icon: Assignment,
      style: styles.colorGreen,
      text: t("users.committedHelp"),
    },
    pending_validation: {
      icon: AssignmentLate,
      style: styles.colorOrange,
      text: t("users.pendingValidationHelp"),
    },
    pending_approval: {
      icon: AssignmentLate,
      style: styles.colorOrange,
      text: t("users.pendingApprovalHelp"),
    },
    validated: {
      icon: CheckCircle,
      style: styles.colorGreen,
      text: t("users.validatedHelp"),
    },
    invalidated: {
      icon: PanTool,
      style: styles.colorYellow,
      text: t("users.invalidatedHelp"),
    },
    cancelled: {
      icon: Cancel,
      style: styles.colorRed,
      text: t("users.cancelledHelp"),
    },
    unauthorized: {
      icon: Lock,
      style: styles.colorRed,
      text: t("users.unauthorizedHelp"),
    },
    blocked: {
      icon: Lock,
      style: styles.colorYellow,
      text: t("users.blockedHelp"),
    },
    indebted: {
      icon: Warning,
      style: styles.colorOrange,
      text: t("users.indebtedHelp"),
    },
    admin_registered: {
      icon: AssignmentInd,
      style: styles.colorBlue,
      text: t("users.adminRegisterHelp"),
    },
    upgrade_request: {
      icon: AssignmentLate, 
      style: styles.colorOrange, 
      text: t('users.upgradeRequestHelp')},
    upgrade_qualified: {
      icon: AssignmentLate, 
      style: styles.colorOrange, 
      text: t('users.upgradeQualifiedHelp')
    },
    upgrade_accepted_no_signature: {
      icon: AssignmentLate, 
      style: styles.colorOrange, 
      text: t('users.upgradeAcceptedNoSignatureHelp')
    },
    upgrade_accepted_pending_validation: {
      icon: AssignmentLate, 
      style: styles.colorOrange, 
      text: t('users.upgradeAcceptePendingValidationHelp')
    },
    unknown: { icon: Help, style: "", text: t("users.unknownHelp") },
  };
  const [moreSelected, setMoreSelected] = React.useState({});
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState(null);
  const [selectedRange, setSelectedRange] = React.useState('');
  const [selectedRangeTo, setSelectedRangeTo] = React.useState('');
  const [vc, setVc] = React.useState({
    orderBy: "status",
    orderDirec: "DESC",
    expandedItem: -1,
    totalItems: 0,
    pageSelected: 0,
    rowsPerPage: 10,
    listItems: [],
    filterBy: "",
    searchText: "",
    showClean: false,
    activeUsers: {},
    approvalDate: null,
    paymentDueDate: null,
    // showAddModal: false,
    userInfo: {
      gender: "",
      dateOfBirth: moment().add(-18, "years").format("YYYY-MM-DD"),
    },
    levelChange: null,
    showLevelModal: false,
    listLevels: [],
  });
  const [notificationData, setNotificationData] = React.useState({
    title: "",
    body: "",
  });
  const [alert, setAlert] = React.useState({
    hasError: false,
    message: "",
    label: "",
  });
  const changeVc = (value) => setVc((ov) => ({ ...ov, ...value }));
  // const changeUser = (value) =>
    // setVc((ov) => ({ ...ov, userInfo: { ...ov.userInfo, ...value } }));

  const [showDropdownStatus, setShowDropdownStatus] = React.useState(false);

  const filterByOptions = new Set([
    "dui",
    "firstName",
    "lastName",
    "cellPhone",
  ]);

  const returnDate = (date) => {
    if (date && date._isValid) {
      return moment(date._d).format('YYYY-MM-DD')
    } else {
      return ''
    }
  }

  const returnUrlVars = (
    obj = {},
    additional = vc.showClean ? `&${vc.filterBy}=${vc.searchText}` : ''
  ) => {
    additional = !dateFilters.includes(vc.filterBy) && !numberRangeFilters.includes(vc.filterBy) ? `&${vc.filterBy}=${vc.searchText}` : '';
    console.log('obj',obj)
    console.log('additional',additional)
    // date range filters
    const dateFrom = returnDate(selectedDate)
    const dateTo = returnDate(selectedDateTo)
    const datesFilter = vc.filterBy && (dateTo || dateFrom) ? `&dateFilter=${vc.filterBy}` : ''
    const urlDate = dateFilters.includes(vc.filterBy) && dateFrom && dateTo ? datesFilter + (`${dateFrom ? `&fromDate=${dateFrom}` : ''}${dateTo ? `&toDate=${dateTo}` : ''}`) : ''

    // number range filters
    const rangeFilter =  numberRangeFilters.includes(vc.filterBy) ? `&rangeFilter=${vc.filterBy}` : ''
    const urlRange = numberRangeFilters.includes(vc.filterBy) && selectedRange && selectedRangeTo ? rangeFilter + (`&fromRange=${selectedRange}&toRange=${selectedRangeTo}`) : ''

    return (
      `?page=${obj.pageSelected + 1}&limit=${obj.rowsPerPage}&sort=${
        obj.orderBy
      }&direction=${obj.orderDirec}` + urlDate + urlRange + additional
    );
  };

  const handleChange = (event) => {
    if (event.persist) {
      event.persist();
    }
    if (event.target) {
      changeVc({ 'searchText': '' });
      changeVc({ [event.target.name]: event.target.value });
      setShowDropdownStatus(event.target.value === 'status')
      
    }
  };

  const onChangeOrder = async (orderObj) => {
    try {
      if (orderObj.orderDirec) {
        setVc((ov) => ({
          ...ov,
          expandedItem: -1,
        }));
        Model.setData("loading", true);
        const response = await UsersService.getUsers(returnUrlVars(orderObj));
        if (response.ok) {
          const data = _.get(response, "data.data", []);
          const paging = _.get(response, "data.paging", {});
          changeVc({
            ...orderObj,
            pageSelected: orderObj.pageSelected,
            totalItems: paging.totalItems,
            listItems: data,
          });
        } else {
          throw new Error(response.problem);
        }
        Model.setData("loading", false);
      }
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: "error",
      });
    }
  };

  const renderHeaders = () => {
    return headers.map((item, index) => (
      <Box
        key={"header-table-" + index}
        className={[
          styles.hdListBold,
          styles[item.colWidth],
          vc.orderBy === item.value ? styles.hdSelected : "",
          item.value === "btActions" ? styles.hdNohover : "",
        ].join(" ")}
        onClick={() => {
          if (item.value === "btActions") {
            return;
          }
          let orderObj = {};
          if (vc.orderBy === item.value) {
            orderObj = {
              orderBy: vc.orderBy,
              orderDirec: vc.orderDirec === "ASC" ? "DESC" : "ASC",
            };
          } else {
            orderObj = {
              orderBy: item.value,
              orderDirec: "DESC",
            };
          }
          onChangeOrder({
            ...orderObj,
            rowsPerPage: vc.rowsPerPage,
            pageSelected: 0,
          });
        }}
      >
        {item.label}{" "}
        {vc.orderBy === item.value ? (
          vc.orderDirec === "ASC" ? (
            <ArrowDropUp />
          ) : (
            <ArrowDropDown />
          )
        ) : null}
      </Box>
    ));
  };

  const renderDataUser = (dataUser, indexUser) => {
    const returnValueUser = (value, currency) => {
      if (currency) {
        return parseFloat(value).toFixed(2);
      } else {
        return value || value === 0 ? value : "-";
      }
    };
    const returnItem = (currency, value, type) => {
      if (type === "status") {
        let IconComp = iconsTool[value] || iconsTool.unknown;
        if (dataUser.isBlocked) {
          IconComp = iconsTool.unauthorized;
        }
        return (
          <LightTooltip title={IconComp.text} placement="right">
            <IconComp.icon className={[styles.iconStatus, IconComp.style]} />
          </LightTooltip>
        );
      } else if(type === 'GAPRegisterApproval') {
        return dataUser?.GAPRegisterApproval !== null
          ? Math.abs(dataUser.GAPRegisterApproval || 0)
          : '-'
      } else if(type === 'approvalDate' || type === 'firstOrderDate') {
        const tooltipDateTitle = {
          approvalDate: t('users.approvalDateTooltip'),
          firstOrderDate: t('users.firstOrderDateTooltip')
        }
        return (
          <LightTooltip title={tooltipDateTitle[type]} placement="right">
            <p>{`${currency}${returnValueUser(value, currency)}`}</p>
          </LightTooltip>)
        } else {
        return `${currency}${returnValueUser(value, currency)}`;
      }
    };
    return headers.map((item, index) => {
      let currency = "";
      if (item.value === "aov" || item.value.indexOf("Amount") > -1) {
        currency = "$";
      }
      return (
        <Box
          key={"data-table-" + index}
          className={[styles.hdList, styles[item.colWidth]].join(" ")}
          onClick={() => history.push(`/profile/${dataUser.id}`)}
        >
          {returnItem(currency, dataUser[item.value], item.value)}
        </Box>
      );
    });
  };

  const renderItemsList = () => {
    if (vc.listItems.length > 0) {
      return vc.listItems.map((item, index) => (
        <AccordionSummary
          key={index}
          aria-controls="panelc-content"
          id="panelc-header"
          className={styles.headerList2}
        >
          {renderDataUser(item, index)}
        </AccordionSummary>
      ));
    } else {
      return <p className={styles.emptyText}>No hay usuarios para mostrar</p>;
    }
  };

  const handleChangePage = (event, newPage) => {
    onChangeOrder({
      pageSelected: newPage,
      rowsPerPage: vc.rowsPerPage,
      orderBy: vc.orderBy,
      orderDirec: vc.orderDirec,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    onChangeOrder({
      pageSelected: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      orderBy: vc.orderBy,
      orderDirec: vc.orderDirec,
    });
  };

  const renderSelectOptions = () => {
    const values = [
      { label: t('common.filterBy'), value: "" },
      { label: t('users.firstName'), value: "firstName" },
      { label: t('users.lastName'), value: "lastName" },
      { label: t('users.dui'), value: "dui" },
      { label: t('users.phone'), value: "cellPhone" },
      { label: t('users.status'), value: "status" },
      { label: t('users.level'), value: "level" },
      { label: t('users.aov'), value: "aov" },
      { label: t('users.availableAmount'), value: "availableAmount" },
      { label: t('users.spentAmount'), value: "spentAmount" },
      { label: t('users.savedAmount'), value: "savedAmount" },
      { label: t('users.approvalDate'), value: "approvalDate" },
      { label: t('users.firstOrderDate'), value: "firstOrderDate" },
      { label: t('users.paymentDueDate'), value: "paymentDueDate" },
      { label: t('users.GAPRegisterApproval'), value: "GAPRegisterApproval" },
    ];
    return values.map((item, index) => (
      <MenuItem
        value={item.value}
        className={styles.itemSelect}
        key={"select-opt-" + index}
      >
        {item.label}
      </MenuItem>
    ));
  };

  const renderStatusSelectOptions = () => {
    const values = [
      {label: t(`profile.status.${'onboarding_validated'}`),value: 'onboarding_validated'},
      {label: t(`profile.status.${'pending_approval'}`),value: 'pending_approval'},
      {label: t(`profile.status.${'approved'}`),value: 'approved'},
      {label: t(`profile.status.${'committed'}`),value: 'committed'},
      {label: t(`profile.status.${'pending_validation'}`),value: 'pending_validation'},
      {label: t(`profile.status.${'validated'}`),value: 'validated'},
      {label: t(`profile.status.${'cancelled'}`),value: 'cancelled'},
      {label: t(`profile.status.${'blocked'}`),value: 'blocked'},
      {label: t(`profile.status.${'indebted'}`),value: 'indebted'},
      {label: t(`profile.status.${'invalidated'}`),value: 'invalidated'},
      {label: t(`profile.status.${'upgrade_request'}`),value: 'upgrade_request'},
      {label: t(`profile.status.${'upgrade_qualified'}`),value: 'upgrade_qualified'},
      {label: t(`profile.status.${'upgrade_accepted_no_signature'}`),value: 'upgrade_accepted_no_signature'},
      {label: t(`profile.status.${'upgrade_accepted_pending_validation'}`),value: 'upgrade_accepted_pending_validation'},
    ];
    return values.map((item, index) => (
      <MenuItem
        value={item.value}
        className={styles.itemSelect}
        key={"select-opt-" + index}
      >
        {item.label}
      </MenuItem>
    ));
  };

  const onClean = () => {
    if (vc.showClean) {
      changeVc({
        filterBy: "",
        searchText: "",
        showClean: false,
        approvalDate: null,
        paymentDueDate: null,
        statusSelect: ''
      });
      setSelectedDate(null)
      setSelectedDateTo(null)
      setSelectedRange('')
      setSelectedRangeTo('')
      setShowDropdownStatus(false)
      history.replace({ search: "" });
      firstLoad();
    }
  };

  const onSearch = async () => {
    try {
      if ((vc.filterBy && vc.searchText) || selectedDate || selectedDateTo || selectedRange || selectedRangeTo) {
        Model.setData("loading", true);
        const filter = vc.filterBy && vc.searchText ? `&${vc.filterBy}=${vc.searchText}` : ''
        const response = await UsersService.getUsers(
          returnUrlVars(vc, filter)
        );
        if (response.ok) {
          const data = _.get(response, "data.data", []);
          const paging = _.get(response, "data.paging", {});
          changeVc({
            pageSelected: 0,
            totalItems: paging.totalItems,
            listItems: data,
            showClean: true,
          });
        } else {
          throw new Error(response.problem);
        }
        Model.setData("loading", false);
      } else {
        const filterError = vc.filterBy || (selectedDate && selectedDateTo);
        const valueError = vc.searchText || (selectedDate && selectedDateTo);
        const filterErrorMsg = getFilterError({ filter: filterError, value: valueError });
        throw new Error(filterErrorMsg);
      }
    } catch (e) {
      Model.updateAlerts({
        message: String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onExport = async () => {
    try {
      Model.setData("loading", true);
      const response = await UsersService.exportTable(
        vc.filterBy ? `?${vc.filterBy}=${vc.searchText}` : ""
      );
      if (response.ok) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "users-list.xlsx");
        document.body.appendChild(link);
        link.click();
      } else {
        let resErr = response.problem
        if (response?.data?.text) {
          resErr = JSON.parse(await response.data.text()).message
        }
        throw new Error(resErr);
      }
      Model.setData("loading", false);
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: "error",
      });
    }
  };

  const acceptChangeLevel = async () => {
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);
      const response = await UsersService.changeUser(moreSelected.id, {
        level: parseInt(vc.levelChange),
      });
      if (response.ok) {
        setMoreSelected({});
        changeVc({ levelChange: null });
        firstLoad();
      } else {
        throw new Error(response.problem);
      }
      Model.setData("loading", false);
      Model.updateAlerts({
        message: "Usuario actualizado",
        variant: "success",
      });
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: "error",
      });
    }
  };

  const onChangeLevel = () => {
    changeVc({ showLevelModal: false });
    if (vc.levelChange !== moreSelected.level) {
      Model.setData("dialog", {
        open: true,
        title: t("common.areUsure"),
        text: t("users.sureChangeLevel", {
          dui: moreSelected.dui,
          oldLevel: moreSelected.level,
          newLevel: vc.levelChange,
        }),
        txtLeft: t("common.cancel"),
        txtRight: t("common.yes"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        fnRight: acceptChangeLevel,
        onClose: () => Model.setData("dialog", { open: false }),
      });
    }
  };

  const onSendNotification = async (userId) => {
    try {
      console.table(notificationData);
      if (_.isEmpty(notificationData.title)) {
        setAlert({
          hasError: true,
          message: "El titulo es obligatorio",
          label: "title",
        });
        return;
      }
      if (_.isEmpty(notificationData.body)) {
        setAlert({
          hasError: true,
          message: "El cuerpo del mensaje es obligatorio",
          label: "body",
        });
        return;
      }

      changeVc({ showNotificationModal: false });

      Model.setData("loading", true);
      const response = await UsersService.pushNotification({
        id: [userId],
        notification: {
          title: notificationData.title,
          body: notificationData.body,
        },
        type: "id",
      });
      if (response.ok) {
        changeVc({ userInfo: { gender: "" } });
        firstLoad();
      } else {
        throw new Error(response.data.message);
      }
      Model.setData("loading", false);

      setNotificationData({ title: "", body: "" });
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: `${String(e.message)}`,
        variant: "error",
      });
    }
  };

  const firstLoad = async (queryParams = "") => {
    try {
      Model.setData("opacity", 0.7);
      Model.setData("loading", true);
      const { filterBy, searchText } = queryParamFormat(
        queryParams,
        filterByOptions
      );
      if (filterBy && searchText) {
        changeVc({
          filterBy,
          searchText,
          showClean: true,
        });
      }
      // const getUsersPayload = returnUrlVars(
      //   vc,
      //   filterBy && searchText ? `&${filterBy}=${searchText}` : ""
      // )
      
      const [ response, responseStats, responseLevels ] = await Promise.all([
        // UsersService.getUsers(getUsersPayload),
        UsersService.getUsers('?page=1&limit=10&sort=status&direction=DESC&='),
        UsersService.getStatistics(),
        LevelsService.getLevels()
      ]);

      // validate get users request
      if (response.ok) {
        const data = _.get(response, "data.data", []);
        const paging = _.get(response, "data.paging", {});
        changeVc({
          totalItems: paging.totalItems,
          listItems: data,
        });
      } else {
        throw new Error(response.problem);
      }

      // validate get stats request
      if (responseStats.ok) {
        const data = _.get(responseStats, "data.data", {});
        changeVc({
          activeUsers: data,
        });
      } else {
        throw new Error(responseStats.problem);
      }

      // validate get levels request
      if (responseLevels.ok) {
        const data = _.get(responseLevels, "data.data", []);
        changeVc({
          listLevels: data.map((level) => ({
            label: `Nivel ${level.level} - $${level.maxAmount}`,
            value: level.level,
          })),
        });
      } else {
        throw new Error(responseLevels.problem);
      }
      Model.setData("loading", false);
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: "error",
      });
    }
  };

  React.useEffect(() => {
    if (state.hasAdminPolicy || state.hasAgentPolicy || state.hasTestPolicy) {
      firstLoad(location.search);
    } else {
      history.replace("/dashboard");
    }
    return () => {};
    //eslint-disable-next-line
  }, []);

  const returnRangeDate = (opt) => {
    if (opt === 'min') {
      if (selectedDate && selectedDate._isValid) {
        return moment(selectedDate._d).format('YYYY-MM-DD')
      } else {
        return ''
      }
    } else {
      if (selectedDateTo && selectedDateTo._isValid) {
        return moment(selectedDateTo._d).format('YYYY-MM-DD')
      } else {
        return ''
      }
    }
  }

  const renderFilters = () => {
    return (
      <>
        {dateFilters.includes(vc.filterBy) ? (
          <>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                format='YYYY-MM-DD'
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                className={styles.pickerDate}
                label={t('common.firstDate')}
                placeholder='YYYY-MM-DD'
                maxDate={returnRangeDate('max')}
              />
            </MuiPickersUtilsProvider>
            <Box className={styles.lblTo}>
              a
            </Box>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                format='YYYY-MM-DD'
                value={selectedDateTo}
                onChange={(date) => setSelectedDateTo(date)}
                className={styles.pickerDate}
                label={t('common.endDate')}
                placeholder='YYYY-MM-DD'
                minDate={returnRangeDate('min')}
              />
            </MuiPickersUtilsProvider>
          </>           
        ) : 
          <>{numberRangeFilters.includes(vc.filterBy) ? (
            <>
              <Input
                placeholder={'Inicial'}
                name="searchText"
                value={selectedRange}
                onChange={(e) => setSelectedRange(e.target.value)}
                className={[styles.textInput, styles.textInput2, styles.textInputRange].join(" ")}
                type={"number"}
                endAdornment={
                  <InputAdornment position="end">
                    <Search className={styles.iconSearch} />
                  </InputAdornment>
                }
              />
              <Box className={styles.lblTo}>
                a
              </Box>
              <Input
                placeholder={'Final'}
                name="searchText"
                value={selectedRangeTo}
                onChange={(e) => setSelectedRangeTo(e.target.value)}
                className={[styles.textInput, styles.textInput2, styles.textInputRange].join(" ")}
                type={"number"}
                endAdornment={
                  <InputAdornment position="end">
                    <Search className={styles.iconSearch} />
                  </InputAdornment>
                }
              />
            </>) : (
            showDropdownStatus ? (
              <Select
                name="statusSelect"
                className={[
                  styles.selectInput,
                  styles.selectInput2,
                  vc.filterBy ? "" : styles.grayTxt,
                ].join(" ")}
                
                onChange={(event)=>{
                  changeVc({ 'searchText': event.target.value });
                }}
              >
                {renderStatusSelectOptions()}
              </Select>
            ) : (
              <Input
                placeholder={t('common.writeHere')+' ...'}
                name="searchText"
                value={vc.searchText}
                onChange={handleChange}
                className={[styles.textInput, styles.textInput2].join(" ")}
                type={numberFilters.includes(vc.filterBy) ? "number" : "text"}
                endAdornment={
                  <InputAdornment position="end">
                    <Search className={styles.iconSearch} />
                  </InputAdornment>
                }
              />)
          )}
        </>}
      </>
    )
  }

  return (
    <Layout menuSelected={1}>
      <Box className={styles.contBg}>
        <Box className={styles.container}>
          <Box className={styles.rowTitle}>
            <p className={styles.title}>{t("layout.clients")}</p>
            <Box className={styles.cardInfo}>
              <p className={styles.titleCard}>
                Usuarios activos: <b>{vc.activeUsers.activeUsers}</b>
              </p>
              <p className={styles.subtitleCard}>
                <b>{vc.activeUsers.newUsers}</b> usuarios nuevos
              </p>
            </Box>
            <Box className={styles.cardInfo}>
              <p className={styles.titleCard}>
                Crédito otorgado:{" "}
                <b>${limitNum(vc.activeUsers.creditLastYear)}</b>
              </p>
              <p className={styles.subtitleCard}>
                <b>Último</b> año
              </p>
            </Box>
            <Box className={styles.cardInfo}>
              <p className={styles.titleCard}>
                Crédito: <b>${limitNum(vc.activeUsers.creditPending)}</b>
              </p>
              <p className={styles.subtitleCard}>
                Pendiente por <b>pagar</b>
              </p>
            </Box>
            <Box style={{ flex: 1 }} />
          </Box>
          <Paper elevation={3} className={styles.contInfo}>
            <Box className={styles.rowFilters}>
              <Select
                name="filterBy"
                value={vc.filterBy}
                onChange={handleChange}
                displayEmpty
                className={[
                  styles.selectInput,
                  styles.selectInput2,
                  vc.filterBy ? "" : styles.grayTxt,
                ].join(" ")}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: -10,
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {renderSelectOptions()}
              </Select>
              {renderFilters()}
              {vc.showClean ? (
                <Button
                  text={t('common.clean')}
                  onClick={onClean}
                  variant="light"
                  customStyles={styles.btClean}
                />
              ) : null}
              <Button text={t('common.search')} onClick={onSearch} />
            </Box>
            <Box className={styles.contList}>
              <Box className={styles.headerList}>{renderHeaders()}</Box>
              <Box className={styles.listItems}>{renderItemsList()}</Box>
            </Box>
            <Box className={styles.rowPagination}>
              {state.hasAdminPolicy || state.hasTestPolicy? (
                <Button
                  text={t('common.export')}
                  iconRight="iconmin-download-3"
                  onClick={onExport}
                />
              ) : (
                <span />
              )}
              <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={vc.totalItems}
                rowsPerPage={vc.rowsPerPage}
                page={vc.pageSelected}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Usuarios por página"
              />
            </Box>
          </Paper>
        </Box>
      </Box>
      <DialogForm
        show={vc.showLevelModal}
        title={`Cambiar de nivel - ${moreSelected.firstName} ${moreSelected.lastName}`}
        text="Por favor seleccione el nivel al que desea que se actualice el usuario"
        leftBtText="Cancelar"
        rightBtText="Guardar"
        funcLeft={() => {
          setMoreSelected({});
          changeVc({
            showLevelModal: false,
            levelChange: "",
          });
        }}
        funcRight={onChangeLevel}
        inputs={[
          {
            label: "Nivel",
            value: "levelChange",
            type: "select",
            values: [...vc.listLevels],
          },
        ]}
        vc={vc}
        changeVc={changeVc}
      />
      <DialogForm
        show={vc.showNotificationModal}
        title="Enviar Notificación"
        text="Por favor ingresar todos los datos que se solicitan"
        leftBtText="Cancelar"
        rightBtText="Enviar"
        alert={alert}
        funcLeft={() => {
          setNotificationData({ title: "", body: "" });
          changeVc({ showNotificationModal: false });
        }}
        funcRight={() => {
          onSendNotification(moreSelected.id);
        }}
        inputs={[
          { label: "Titulo", value: "title" },
          {
            label: "Mensaje",
            value: "body",
            multiline: true,
            rows: 6,
            width: "100%",
          },
        ]}
        vc={vc}
        changeVc={(props) => {
          const { title, body } = props;
          if (!_.isNil(title)) {
            setNotificationData((notification) => ({ ...notification, title }));
            if (alert.hasError && alert.label === "title") {
              setAlert({ hasError: false, message: "", label: "" });
            }
          }
          if (!_.isNil(body)) {
            setNotificationData((notification) => ({ ...notification, body }));
            if (alert.hasError && alert.label === "body") {
              setAlert({ hasError: false, message: "", label: "" });
            }
          }
        }}
      />

      <NotificationService
        user={moreSelected}
        isOpen={vc.showListNotificationModal}
        onCloseHandle={() => changeVc({ showListNotificationModal: false })}
      />
    </Layout>
  );
};

export default Users;
