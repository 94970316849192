import React from 'react'
import {
  Box,
  CircularProgress
} from '@material-ui/core'
import {
  Edit,
  Receipt
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import LevelsService from '../../services/levels'
import DialogForm from '../../components/dialogForm'
import fileStyles from './styles/levelsStyles'
import Model from '../../hooks/Model'
import {Store} from '../../hooks/main_store'
import Layout from '../layout'
import Button from '../../components/button'
import _ from 'lodash'

const Levels = ({ history }) => {
  const { t } = useTranslation()
  const {state} = React.useContext(Store)
  const styles = fileStyles()
  const [vc, setVc] = React.useState({
    listItems: [],
    item2Edit: null,
    showAddModal: false,
    infoLevel: {}
  })
  const changeVc = (value) => setVc(ov => ({...ov, ...value}))

  const onNewLevel = async () => {
    try {
      const data = vc.infoLevel
      changeVc({
        showAddModal: false,
        item2Edit: null,
        newName: '',
        newCode: ''
      })
      if (!data.rate || !data.level || !data.maxAmount || !data.days || !data.minimumAmount) {
        throw new Error(t('common.notEmptyFields'))
      }
      Model.setData('loading', true)
      const response = await LevelsService.createLevel({
        level: parseInt(data.level),
        days: parseInt(data.days),
        maxAmount: parseFloat(data.maxAmount),
        minimumAmount: parseFloat(data.minimumAmount).toFixed(2),
        rate: parseFloat(data.rate),
        physicalSignature: !!data?.physicalSignature
      })
      if (response.ok) {
        firstLoad()
      } else {
        throw new Error(response.problem)
      }
      Model.setData('loading', false)
      Model.updateAlerts({
        message: 'Nivel guardado',
        variant: 'success'
      })
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const onEditLevel = async () => {
    try {
      const idEdit = vc.infoLevel.id
      const data = {
        days: String(vc.infoLevel.days),
        maxAmount: String(vc.infoLevel.maxAmount),
        minimumAmount: String(vc.infoLevel.minimumAmount),
        rate: String(vc.infoLevel.rate),
        status: vc.infoLevel.status,
        physicalSignature: !!vc?.infoLevel?.physicalSignature
      }
      changeVc({
        showAddModal: false,
        item2Edit: null,
        infoLevel: {}
      })
      if (!data.rate || !data.maxAmount || !data.days || !data.status || !data.minimumAmount) {
        throw new Error(t('common.notEmptyFields'))
      }
      Model.setData('loading', true)
      data.minimumAmount = parseFloat(data.minimumAmount).toFixed(2);
      const response = await LevelsService.changeLevel(idEdit, data)
      if (response.ok) {
        firstLoad()
      } else {
        throw new Error(response.problem)
      }
      Model.setData('loading', false)
      Model.updateAlerts({
        message: 'Nivel guardado',
        variant: 'success'
      })
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const utilRate = (value) => {
    return parseFloat(isNaN(value) ? 0 : value.toFixed(1))
  }

  const renderCards = () => {
    if (vc.listItems.length > 0) {
      return vc.listItems.map((item, index) => <Box
        key={'cardlevel-' + index}
        className={styles.contCard}
      >
        <Box
          style={{ paddingBottom: 5 }}
          className={styles.headerCard}
          onClick={() => changeVc({
            infoLevel: item,
            showAddModal: true,
            item2Edit: String(index)
          })}
        >
          <span className={styles.lblLevel}>
            { t("levels.level")} {item.level}
          </span>
          <Edit className={styles.iconEdit} />
          {item?.physicalSignature ? (
            <Receipt className={styles.iconPhysicalSignature} />
          ) : ''}
        </Box>
        <Box style={{ paddingTop: 0 }}className={styles.headerCard}>
          <span className={styles.lblAmount}>
            { t("levels.minimumAmount")}: ${parseFloat(item?.minimumAmount || 0).toFixed(2)}
          </span>
        </Box>
        <Box className={styles.rowColsCard}>
          <Box className={styles.colCard}>
            <Box className={styles.titleColCard}>TOPE</Box>
            <Box className={styles.dataColCard}>
              ${item.maxAmount}
            </Box>
          </Box>
          <Box className={styles.colCard}>
            <Box className={styles.titleColCard}>INTERÉS</Box>
            <Box className={styles.dataColCard}>
              {item.rate.toFixed(2)}% <br/><span>MENSUAL</span>
            </Box>
          </Box>
          <Box className={styles.colCard}>
            <Box className={styles.titleColCard}>TIEMPO</Box>
            <Box className={styles.dataColCard}>
              {item.days} <br/><span>DÍAS</span>
            </Box>
          </Box>
        </Box>
        <Box className={styles.rowStatsCard}>
          <Box className={styles.wrapperCircle}>
            <CircularProgress
              size={78}
              variant="static" value={utilRate(item.utilizationRate * 100)}
              className={styles.colorGreen}
            />
            <Box className={styles.whiteCircleCard}>
              <p className={styles.numLines}>
                {item.activeUsers}
              </p>
              <p className={styles.lblLines}>LÍNEAS</p>
            </Box>
          </Box>
          <Box className={styles.rowLbls}>
            <Box className={styles.rowLabel}>
              <Box className={styles.colorLbl} />
              <p className={styles.txtLbl}>Utilización</p>
            </Box>
            <p className={styles.valueLbl}>
              {utilRate(item.utilizationRate * 100)}%
            </p>
          </Box>
        </Box>
        <Box className={styles.footerCard}>
          <Box className={styles.borderFooter}>
            <p className={styles.lblFooterCard}>Compras realizadas</p>
            <span className={styles.valueFooterCard}>
              {item.openOrders}
            </span>
          </Box>
        </Box>
      </Box>)
    } else {
      return <Box className={styles.emptyTxt}>
        No hay niveles creados para mostrar
      </Box>
    }
  }

  const firstLoad = async () => {
    try {
      Model.setData('opacity', 0.7)
      Model.setData('loading', true)
      const response = await LevelsService.getLevels()
      if (response.ok) {
        const data = _.get(response, 'data.data', [])
        changeVc({ listItems: data });
      } else {
        throw new Error(response.problem)
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  React.useEffect(() => {
    if (state.hasAdminPolicy) {
      firstLoad()
    } else {
      history.replace('/orders')
    }
    return () => {}
    //eslint-disable-next-line
  }, []);

  return <Layout menuSelected={5}>
    <Box className={styles.contBg}>
      <Box className={styles.container}>
        <Box className={styles.rowTitle}>
          <p className={styles.title}>
            Niveles de Crédito
          </p>
          <Box className={styles.colInter} />
          <Button
            text='Crear Nivel'
            onClick={() => changeVc({showAddModal: true})}
            iconRight='iconmin-plus_circle-1'
          />
        </Box>
      </Box>
      <Box className={styles.containerCards}>
        {renderCards()}
      </Box>
    </Box>
    <DialogForm
      show={vc.showAddModal}
      title={`${vc.item2Edit ? 'Editar' : 'Crear'} Nivel ${vc.item2Edit ? vc.infoLevel.level : ''}`}
      text='Por favor ingresar todos los datos que se solicitan'
      leftBtText='Cancelar'
      rightBtText='Guardar'
      funcLeft={() => changeVc({
        item2Edit: null,
        showAddModal: false,
        infoLevel: {}
      })}
      funcRight={vc.item2Edit ? onEditLevel : onNewLevel}
      inputs={vc.item2Edit ? [
        {label: 'Tope ($)', value: 'maxAmount'},
        {label: 'Interés (%)', value: 'rate'},
        {label: 'Tiempo limite de pago (días)', value: 'days'},
        {label: 'Estado', value: 'status', type: 'select', values: [{
          label: 'Activado', value: 'active'
        }, {
          label: 'Desactivado', value: 'inactive'
        }]},
        {label: 'Valor mínimo para ordenes', value: 'minimumAmount'},
        {label: 'Requiere pagaré fisico', value: 'physicalSignature', type: 'switch'},
      ] : [
        {label: 'Nivel', value: 'level'},
        {label: 'Tope ($)', value: 'maxAmount'},
        {label: 'Interés (%)', value: 'rate'},
        {label: 'Tiempo limite de pago (días)', value: 'days'},
        {label: 'Valor mínimo para ordenes', value: 'minimumAmount'},
        {label: 'Requiere pagaré fisico', value: 'physicalSignature', type: 'switch'},
      ]}
      vc={vc.infoLevel}
      changeVc={(value) => changeVc({infoLevel: {
        ...vc.infoLevel,
        ...value
      }})}
    />
  </Layout>
}

export default Levels
