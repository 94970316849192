import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styleguide'

const useStyles = makeStyles({
  container: {
    cursor: 'pointer',
    textAlign: 'center',
    width: 'fit-content',
    fontWeight: '600',
    fontSize: 14,
    borderRadius: 4,
    boxShadow: 'inset 0px 2px 0px 0px rgba(255, 255, 255, 0.08), 0px 1px 1px 0px rgba(43, 26, 24, 0.1)',
    padding: '9px 18px',
    transition: 'all 0.2s',
    color: colors.mainxText,
    border: '1px solid rgb(216, 220, 230)',
    opacity: 0.8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      transform: 'scale(1.01)',
      opacity: 1
    }
  },
  fullWidth: {
    width: 'calc(100% - 36px)'
  },
  noBorder: {
    border: 'none !important'
  },
  primary: {
    backgroundColor: colors.primary,
    color: colors.white,
    border: '1px solid rgb(75, 166, 118)'
  },
  secondary: {
    backgroundColor: colors.secondary,
    color: colors.white,
    border: '1px solid rgb(69, 57, 178)'
  },
  light: {
    backgroundColor: colors.white,
    color: colors.mainxText,
    border: '1px solid rgb(216, 220, 230)'
  },
  danger: {
    backgroundColor: colors.red,
    color: colors.white,
    border: '1px solid rgb(199, 81, 78)'
  },
  alert: {
    backgroundColor: colors.orange,
    color: colors.white,
    border: '1px solid rgb(218, 108, 14)'
  },
  lblButton: {
    margin: 0,
    textAlign: 'center',
    flex: 1
  },
  iconRightButton: {
    marginLeft: 12,
    fontSize: 20
  }
});

export default useStyles;