import React from 'react';
import {Button, Select, MenuItem, InputLabel, FormControl, IconButton, Checkbox, ListItemText} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { MuiPickersUtilsProvider,  KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types'
import moment from 'moment'
import Alert from '@material-ui/lab/Alert';
import { Edit } from '@material-ui/icons';
import _ from 'lodash';
import Switch from './switch'

const DialogFormComponent = ({
  show,
  title,
  text,
  leftBtText,
  rightBtText,
  funcLeft,
  funcRight,
  inputs,
  vc,
  changeVc,
  alert,
  maxWidth = 'sm',
  extraContent = '',
  defaultButtonOnClick
}) => {

  const onChangeText = (event, index) => {
    if (event.persist) {
      event.persist()
    }
    if (event.target) {
      changeVc({[event.target.name]: event.target.value, ...(index ? { index } : {})})
    }
  }

  const renderSelectOptions = (values, item) => {
    if(item?.checks) {
      const { index: itemIndex, isChecked = () => false, stateSource } = item;
      return values.map((item, index) => (
        <MenuItem key={'select-opt-' + index} value={item.value}>
          <Checkbox checked={isChecked(item, itemIndex, vc[stateSource])} />
          <ListItemText primary={item.label} />
        </MenuItem>
      ))
    }
    return values.map((item, index) => <MenuItem
      value={item.value}
      key={'select-opt-' + index}
    >
      {item.label}
    </MenuItem>)
  }

  const renderType = (item, index) => {
    if (!item.type) {
      return <TextField
        key={'input-modal-' + index}
        margin="dense"
        label={item.label}
        value={_.get(vc, item.value) || ''}
        multiline={item.multiline || false}
        {...(!item?.autoSize ? {rows: item.rows || 1} : {})}
        placeholder={item?.placeholder || ''}
        style={{
          width: item.width || 'calc(50% - 12px)',
          margin: 6
        }}
        InputLabelProps={{
          shrink: item?.shrink,
        }}
        name={item.value}
        onChange={item?.onChange || onChangeText}
      />
    } else if(item.type === 'rowTextField') {
      return <TextField
        key={'input-modal-' + index}
        margin="dense"
        label={item.label}
        value={item.value || ''}
        multiline={item.multiline || false}
        rows={item.rows || 1}
        style={{
          width: item.width || 'calc(50% - 12px)',
          margin: 6
        }}
        name={item.key}
        onChange={e => onChangeText(e, item.index)}
      />
    } else if (item.type === 'date') {
      return <MuiPickersUtilsProvider utils={MomentUtils} key={'input-modal-' + index}>
        <KeyboardDatePicker
          format='YYYY-MM-DD'
          value={vc[item.value]}
          onChange={(date) => {
            changeVc({[item.value]: date})
          }}
          style={{
            width: 'calc(50% - 12px)',
            margin: 6
          }}
          label={item.label}
          placeholder='YYYY-MM-DD'
          maxDate={item.maxAmount === 'now' ? moment().format('YYYY-MM-DD') : moment().add(-18, 'years').format('YYYY-MM-DD')}
        />
      </MuiPickersUtilsProvider>
    } else if (item.type === 'selectMultiple') {
      return (
        <FormControl key={'input-modal-' + index} style={{
          width: item.width || 'calc(50% - 12px)',
          margin: 6
        }}>
          <InputLabel id={'input-modal-' + index}>{item.label}</InputLabel>
          <Select
            labelId={'input-modal-' + index}
            key={'input-modal-' + index}
            name={item.name}
            value={item.useValue ? item.value : vc[item.value]}
            onChange={item.onChange ? (e) => item.onChange(e, item.type, item.index, vc[item.stateSource]) : onChangeText}
            inputProps={{ 'aria-label': 'Without label' }}
            multiple={item?.multiple || false}
            displayEmpty
            {...(item.renderValue ? { renderValue: item.renderValue } : {})}
          >
            {renderSelectOptions(item.values, item)}
          </Select>
      </FormControl>)
    } else if (item.type === 'select') {
      return (
        <FormControl key={'input-modal-' + index} style={{
          width: item.width || 'calc(50% - 12px)',
          margin: 6
        }}>
          <InputLabel id={'input-modal-' + index}>{item.label}</InputLabel>
          <Select
            labelId={'input-modal-' + index}
            key={'input-modal-' + index}
            name={item.value}
            value={item.useValue ? item.value : vc[item.value]}
            onChange={item.onChange ? (e) => item.onChange(e, item.type, item.index, vc[item.stateSource]) : onChangeText}
            inputProps={{ 'aria-label': 'Without label' }}
            displayEmpty
          >
            {renderSelectOptions(item.values)}
          </Select>
      </FormControl>)
    } else if (item.type === 'iconButton') {
      return (
        <IconButton onClick={defaultButtonOnClick ? () => defaultButtonOnClick(item?.index) : item.onClick}>
          {item.icon}
        </IconButton>)
    } else if (item.type === 'image') {
      return (
        <span style={{
          width: 'calc(50% - 12px)',
          margin: 6
        }} className={'flex justify-between items-center'}>
          <div className={'flex items-end'}>
            <span className={'mr-1'}>{item?.label}</span>
            <div style={{ height: 'fit-content' }} className={"border border-solid"}>
              {item?.src ? <img className={"w-8 h-8"} alt={"profile"} src={item?.src} /> : ''}
            </div>
          </div>
          <IconButton
            color="default"
            variant="contained"
            component="label"
            style={{ padding: 4 }}
          >
            <Edit />
            <input
              type="file"
              hidden
              name={item.value}
              onChange={item.onChange}
            />
          </IconButton>
        </span>)
    } else if (item.type === 'switch') {
      return (
        <Switch
          key={'input-modal-' + index}
          label={item.label}
          value={vc[item.value]}
          onChange={() => changeVc({[item.value]: !vc[item.value]})}
        />)
    }
  }

  return <Dialog open={show} aria-labelledby="form-dialog-title" fullWidth maxWidth={maxWidth}>
    <DialogTitle id="form-dialog-title">
      {title}
    </DialogTitle>
    {alert?.hasError && <Alert severity="error">{alert.message}</Alert>}
    <DialogContent>
      <DialogContentText>
        {text}
      </DialogContentText>
      <Box style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
      }}>
        {inputs.map(renderType)}
      </Box>
      {extraContent || ''}
    </DialogContent>
    <DialogActions>
      <Button onClick={funcLeft} color="primary">
        {leftBtText}
      </Button>
      <Button onClick={funcRight} color="primary">
        {rightBtText}
      </Button>
    </DialogActions>
  </Dialog>
}

DialogFormComponent.defaultProps = {
  show: false,
  title: '',
  text: '',
  leftBtText: '',
  rightBtText: '',
  funcLeft: () => {},
  funcRight: () => {},
  inputs: [],
  vc: {},
  changeVc: () => {}
};

DialogFormComponent.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  leftBtText: PropTypes.string,
  rightBtText: PropTypes.string,
  funcLeft: PropTypes.func,
  funcRight: PropTypes.func,
  inputs: PropTypes.array,
  vc: PropTypes.object,
  changeVc: PropTypes.func,
};

export default DialogFormComponent;
