import React from "react";
import { List } from "@material-ui/core";
import NotificationListItem from "./notification-list-item";

const NotificationList = (props) => {
  const { notifications } = props;
  return (
    <List>
      {notifications.map((notification) => (
        <NotificationListItem
          key={notification.id}
          notification={notification}
        />
      ))}
    </List>
  );
};

export default NotificationList;
