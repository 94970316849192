import React from "react";
import * as yup from "yup";
import { Form } from "formik";
import { Field } from "formik";
import { Formik } from "formik";
import { useState } from "react";
import { Button } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { DialogActions } from "@material-ui/core";

import UsersService from "../../../../../../services/users";
import LoadingButton from "../../../../../../components/loading-button";

const CreateNotificationForm = (props) => {
  const { user, closeModal } = props;
  const [isLoading, setLoading] = useState(false);

  const initialValues = {
    title: "",
    body: "",
  };

  const validationSchema = yup.object({
    title: yup.string().required(),
    body: yup.string().required(),
  });

  const handleSubmit = async (values, formikBag) => {
    const data = {
      id: [Number(user.id)],
      type: "id",
      notification: values,
    };
    setLoading(true);
    await UsersService.pushNotification(data);
    formikBag.resetForm();
    setLoading(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className={"flex flex-col gap-4"}>
        <div>Por favor ingresar todos los datos que se solicitan</div>

        <Field
          type={"text"}
          label={"Titulo"}
          component={TextField}
          name={"title"}
        />

        <Field
          rows={4}
          rowsMax={4}
          type={"text"}
          multiline={true}
          label={"Mensaje"}
          component={TextField}
          name={"body"}
        />

        <DialogActions>
          <Button
            variant={"text"}
            color={"default"}
            children={"Salir"}
            onClick={closeModal}
          />
          <LoadingButton
            type={"submit"}
            label={"Enviar"}
            color={"primary"}
            children={"Enviar"}
            isLoading={isLoading}
            variant={"contained"}
            loadingLabel={"Enviando"}
          />
        </DialogActions>
      </Form>
    </Formik>
  );
};

export default CreateNotificationForm;
