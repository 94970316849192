import _ from 'lodash'
import React from 'react'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import {Visibility, VisibilityOff} from '@material-ui/icons'
import fileStyles from './styles/authStyles'
import AuthService from '../../services/auth'
import firebase from '../../hooks/firebase'
import Input from '../../components/input'
import Button from '../../components/button'
import Model from '../../hooks/Model'

const SetPasswordPage = (props) => {
  const queries = props?.history?.location?.search
  const styles = fileStyles()
  const { t } = useTranslation();
  const [vc, setVc] = React.useState({
    showPassword: false,
    showPassword2: false,
    password: '',
    password2: '',
  });

  const changeEye = () => {
    setVc(oldValues => ({
      ...oldValues,
      showPassword: !oldValues.showPassword
    }))
  }

  const changeEye2 = () => {
    setVc(oldValues => ({
      ...oldValues,
      showPassword2: !oldValues.showPassword2
    }))
  }

  const goTo = (route) => {
    props.history.push(route)
  }

  const setpasswordValue = async () => {
    try {
      Model.setData('loading', true)
      const urlParams = new URLSearchParams(queries);
      const code = urlParams.get('c');
      const uid = urlParams.get('fa');
      const result = await AuthService.setpasswordValue({
        password: vc.password,
        code,
        uid,
      })
      if (result.ok) {
        goTo('/')
        Model.updateAlerts({
          message: t('login.doneSetpass'),
          variant: 'success'
        })
      } else {
        throw result.data
      }
    } catch (e) {
      console.log(e)
      Model.setData('loading', false)
      Model.updateAlerts({
        message: _.get(e, 'message', t('common.unknownError')),
        variant: 'error'
      })
    }
  }

  const submitForm = () => {
    const { password, password2 } = vc;
    if (!password || !password2) {
      Model.updateAlerts({
        message: t('common.pleaseFill'),
        variant: 'error'
      })
    } else if (password !== password2) {
      Model.updateAlerts({
        message: t('login.equalPass'),
        variant: 'error'
      })
    } else {
      setpasswordValue()
    }
  }

  const changeState = name => event => {
    event.persist()
    setVc(oldValues => ({
      ...oldValues,
      [name]: event.target.value
    }))
  }

  React.useEffect(() => {
    const urlParams = new URLSearchParams(queries);
    const code = urlParams.get('c');
    const uid = urlParams.get('fa');
    firebase.auth().signOut().then(() => {}).catch(() => {})
    if (!queries || !code || !uid) {
      goTo('/')
    } else {
      Model.setData('loading', false)
      Model.setData('opacity', 0.7)
    }
    return () => {}
    //eslint-disable-next-line
  }, []);

  return <Box className={styles.container}>
    <img
      src={require('../../assets/images/bg-color.png')}
      className={styles.bgColor}
      alt='Unicomer'
    />
    <Box className={styles.content}>
      <img
        src={require('../../assets/images/logo.svg')}
        className={styles.logo}
        alt='Unicomer'
      />
      <p className={styles.title}>
        {t('login.setPassword')}
      </p>
      <p className={styles.label}>
        {t('login.lblsetPassword')} {/*<b>angela@rokk3rlabs.com</b>*/}
      </p>
      <Box className={styles.passCont}>
        <Input
          type={vc.showPassword ? 'text' : 'password'}
          placeholder={t('login.yourPassword')}
          onChange={changeState('password')}
          value={vc.password}
          fullWidth
          customStyles={styles.inputPass}
        />
        <Box onClick={changeEye} className={styles.eyeBt}>
          {!vc.showPassword ? <Visibility /> : <VisibilityOff/>}
        </Box>
      </Box>
      <Box className={styles.passCont}>
        <Input
          type={vc.showPassword2 ? 'text' : 'password'}
          placeholder={t('login.repeatPassword')}
          onChange={changeState('password2')}
          value={vc.password2}
          fullWidth
          customStyles={styles.inputPass}
        />
        <Box onClick={changeEye2} className={styles.eyeBt}>
          {!vc.showPassword2 ? <Visibility /> : <VisibilityOff/>}
        </Box>
      </Box>
      <Button
        text={t('login.save')}
        variant='primary'
        customStyles={styles.sendBt}
        fullWidth
        onClick={submitForm}
      />
    </Box>
  </Box>
}

export default SetPasswordPage;
