import React from "react";

import LoadingButton from "../../../components/loading-button";

const NotificationSubmit = (props) => {
  const { isLoading } = props;
  return (
    <div className={"w-full flex flex-col items-center"}>
      <LoadingButton
        type={"submit"}
        label={"Enviar"}
        color={"primary"}
        isLoading={isLoading}
        variant={"contained"}
        loadingLabel={"Enviando"}
      />
    </div>
  );
};

export default NotificationSubmit;
