import React from "react";
import { Field } from "formik";
import { MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";

import DuiFields from "./conditional-fields/dui-fields";
import LevelFields from "./conditional-fields/level-fields";
import StatusFields from "./conditional-fields/status-fields";
import CreationFields from "./conditional-fields/creation-fields";
import CellPhoneFields from "./conditional-fields/cellPhone-fields";

const NotificationFields = () => {
  return (
    <div className={"grid grid-cols-1 gap-4"}>
      <Field
        name="type"
        type="text"
        select={true}
        component={TextField}
        label={"Tipo"}
      >
        <MenuItem value={"dui"}>DUI</MenuItem>
        <MenuItem value={"cellPhone"}>Número Celular</MenuItem>
        <MenuItem value={"level"}>Nivel</MenuItem>
        <MenuItem value={"status"}>Estado</MenuItem>
        <MenuItem value={"creation"}>Fecha de creación</MenuItem>
      </Field>

      <DuiFields />

      <LevelFields />

      <StatusFields />

      <CreationFields />

      <CellPhoneFields />

      <Field
        type={"text"}
        label={"Titulo"}
        component={TextField}
        name={"notification.title"}
      />

      <Field
        rows={4}
        rowsMax={4}
        type={"text"}
        multiline={true}
        label={"Mensaje"}
        component={TextField}
        name={"notification.body"}
      />
    </div>
  );
};

export default NotificationFields;
