import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styleguide'

const useStyles = makeStyles({
  container: {
    width: 'fit-content'
  },
  fullWidth: {
    width: '100%',
  },
  input: {
    background: colors.white,
    borderRadius: 4,
    border: '1px solid rgb(226, 229, 237)',
    boxShadow: 'inset 0px 1px 2px 0px rgba(102, 113, 123, 0.21)',
    width: 'calc(100% - 42px)',
    //width: '100%',
    outline: 'none',
    fontFamily: 'Mulish',
    fontSize: 14,
    padding: '16px 20px',
    color: colors.mainxText,
    '&::placeholder': {
      color: colors.mainxText,
      opacity: 0.5
    }
  },
  noBorder: {
    border: 'none !important'
  },
  errorMsg: {
    textAlign: 'right',
    margin: '13px 0px 0px 0px',
    width: '100%',
    color: colors.red,
    fontSize: 16,
    display: 'none'
  },
  error: {
    '& input': {
      border: '1px solid rgb(255, 102, 99) !important',
      borderRadius: 4,
    },
    '& p': {
      display: 'inherit !important'
    }
  },
  success: {
    '& input': {
      border: '1px solid rgb(97, 208, 149) !important',
      borderRadius: 4,
    }
  }
});

export default useStyles;