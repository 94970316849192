const HdValues = (t) => {
  return [
    {
      label: t("users.status"),
      colWidth: "col1c",
      value: "status",
    },
    {
      label: t("users.dui"),
      colWidth: "col2",
      value: "dui",
    },
    {
      label: t("users.firstName"),
      colWidth: "col2",
      value: "firstName",
    },
    {
      label: t("users.lastName"),
      colWidth: "col2",
      value: "lastName",
    },
    {
      label: t("users.approvalDate"),
      colWidth: "col2",
      value: "approvalDate",
    },
    {
      label: t("users.firstOrderDate"),
      colWidth: "col2",
      value: "firstOrderDate",
    },
    {
      label: t("users.GAPRegisterApproval"),
      colWidth: "col2",
      value: "GAPRegisterApproval",
    },
    {
      label: t("users.level"),
      colWidth: "col1",
      value: "level",
    },
    {
      label: t("users.aov"),
      colWidth: "col1",
      value: "aov",
    },
    {
      label: t("users.availableAmount"),
      colWidth: "col1",
      value: "availableAmount",
    },
    {
      label: t("users.spentAmount"),
      colWidth: "col1",
      value: "spentAmount",
    },
    {
      label: t("users.savedAmount"),
      colWidth: "col1",
      value: "savedAmount",
    },
    {
      label: t("users.paymentDueDate"),
      colWidth: "col2",
      value: "paymentDueDate",
    },
  ];
};

export default HdValues;
