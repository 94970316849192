import React from 'react'
import { Box } from '@material-ui/core'
import useStyles from './styles/inputStyles'
import PropTypes from 'prop-types'

const Input = (props) => {
  const styles = useStyles();
  return <Box className={[
    styles.container,
    props.fullWidth ? styles.fullWidth : '',
    props.customStyles,
    styles[props.variant]
  ].join(' ')}>
    <input
      className={[
        styles.input,
        props.withoutBorder ? styles.noBorder : '',
      ].join(' ')}
      placeholder={props.placeholder}
      onChange={props.onChange}
      onClick={props.onClick}
      value={props.value}
      type={props.type}
    />
    <p className={styles.errorMsg}>
      {props.errorMsg}
    </p>
  </Box>
  
};

Input.defaultProps = {
  withoutBorder: false,
  onChange: () => {},
  onClick: () => {},
  variant: 'normal',
  errorMsg: 'Error',
  fullWidth: false,
  customStyles: '',
  placeholder: '',
  value: '',
  type: 'text'
};

Input.propTypes = {
  variant: PropTypes.oneOf(['normal', 'error', 'success']),
  customStyles: PropTypes.string,
  placeholder: PropTypes.string,
  withoutBorder: PropTypes.bool,
  errorMsg: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password']),
};

export default Input