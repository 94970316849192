const colors = {
  black: '#1D253C',
  white: '#FFFFFF',
  primary: '#0D9DA2',
  orange: '#FF7F11',
  blue: '#1050E6',
  secondary: '#EB2A89',
  red: '#FF6663',
  yellow: '#FCE762',
  mainxText: '#1D253C',
  secondaryText: '#4F6178',
  icon: '#A9B4C2',
  gray50: '#8E929D',
  shadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.04)',
  shadow2: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
};

export default colors;