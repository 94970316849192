import React from 'react'
import {
  Box,
  Paper,
  Accordion,
  AccordionSummary,
  TablePagination,
  Tooltip,
  Popover,
  Select,
  MenuItem,
  Input,
  InputAdornment
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import {
  ArrowDropUp,
  ArrowDropDown,
  CheckCircle,
  Help,
  Cancel,
  MoreVert,
  Search
} from '@material-ui/icons'
import { getFilterError } from '../../utils/common';
import UsersService from '../../services/users'
import DialogForm from '../../components/dialogForm'
import { useTranslation } from 'react-i18next'
import fileStyles from './styles/partnersStyles'
import Model from '../../hooks/Model'
import {Store} from '../../hooks/main_store'
import Layout from '../layout'
import HdValues from './hdValues'
import Button from '../../components/button'
import _ from 'lodash'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const Admins = ({ history }) => {
  const {state} = React.useContext(Store)
  const { t } = useTranslation()
  const headers = HdValues(t)
  const styles = fileStyles()
  const iconsTool = {
    active: {icon: CheckCircle, style: styles.colorGreen, text: t('partners.activeHelp')},
    inactive: {icon: Cancel, style: styles.colorRed, text: t('partners.inactiveHelp')},
    unknown: {icon: Help, style: '', text:  t('partners.unknownHelp')},
  }
  const [moreOpened, setMoreOpened] = React.useState('')
  const [hideResend, setHideResend] = React.useState(true)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [moreSelected, setMoreSelected] = React.useState({})
  const [vc, setVc] = React.useState({
    orderBy: 'status',
    orderDirec: 'DESC',
    totalItems: 0,
    pageSelected: 0,
    rowsPerPage: 10,
    listItems: [],
    filterBy: '',
    searchText: '',
    showClean: false,
    showAddModal: false,
    newName: '',
    newlastName: '',
    newEmail: '',
    newRol: '',
  })
  const changeVc = (value) => setVc(ov => ({...ov, ...value}))
  const lblRol = {
    'user_admin': t(`profile.roles.${'user_admin'}`),
    'user_admin_agent': t(`profile.roles.${'user_admin_agent'}`),
    'user_order_manager': t(`profile.roles.${'user_order_manager'}`),
    'user_test': t(`profile.roles.${'user_test'}`),
  };

  const returnUrlVars = (
    obj = {},
    additional = vc.showClean ? `&${vc.filterBy}=${vc.searchText}` : ''
  ) => {
    return `?isAdmin=true&page=${obj.pageSelected + 1}&limit=${obj.rowsPerPage}&sort=${obj.orderBy}&direction=${obj.orderDirec}` + additional
  }

  const handleClickMore = (e, id, item) => {
    setMoreOpened(id !== moreOpened ? id : '')
    setAnchorEl(e.currentTarget)
    setMoreSelected(item)
    setHideResend(item?.firstTimeLogin)
  }

  const handleCloseMore = () => {
    setAnchorEl(null)
    setMoreOpened('')
    setMoreSelected({})
  }

  const onResend = async () => {
    try {
      handleCloseMore()
      Model.setData('loading', true)
      const response = await UsersService.resendInvite({
        uid: moreSelected.uid
      })
      if (response.ok) {
        firstLoad()
        Model.setData('loading', false)
        Model.updateAlerts({
          message: 'Invitación enviada',
          variant: 'done'
        })
      } else {
        throw new Error(response.problem)
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const handleChange = (event) => {
    if (event.persist) {
      event.persist()
    }
    if (event.target) {
      changeVc({ 'searchText': '' });
      changeVc({[event.target.name]: event.target.value})
    }
  };

  const onChangeOrder = async (orderObj) => {
    try {
      if (orderObj.orderDirec) {
        Model.setData('loading', true)
        const response = await UsersService.getUsers(
          returnUrlVars({...vc, ...orderObj}, `&${vc.filterBy}=${vc.searchText}`)
        );
        if (response.ok) {
          const data = _.get(response, 'data.data', [])
          const paging = _.get(response, 'data.paging', {})
          changeVc({
            ...orderObj,
            pageSelected: orderObj.pageSelected,
            totalItems: paging.totalItems,
            listItems: data,
          });
        } else {
          throw new Error(response.problem)
        }
        Model.setData('loading', false)
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const renderHeaders = () => {
    return headers.map((item, index) => <Box
      key={'header-table-' + index}
      className={[
        styles.hdListBold,
        styles[item.colWidth],
        vc.orderBy === item.value ? styles.hdSelected : '',
        item.value === 'btActions' ? styles.hdNohover : '',
      ].join(' ')}
      onClick={() => {
        if (item.value === 'btActions') { return }
        let orderObj = {}
        if (vc.orderBy === item.value) {
          orderObj = {
            orderBy: vc.orderBy,
            orderDirec: vc.orderDirec === 'ASC' ? 'DESC' : 'ASC'
          }
        } else {
          orderObj = {
            orderBy: item.value,
            orderDirec: 'DESC'
          }
        }
        onChangeOrder({
          ...orderObj,
          rowsPerPage: vc.rowsPerPage,
          pageSelected: 0
        })
      }}
    >
      {item.label} {vc.orderBy === item.value ? (vc.orderDirec === 'ASC' ? <ArrowDropUp /> : <ArrowDropDown />) : null}
    </Box>)
  }

  const renderDataPartner = (dataPartner, indexPartner) => {
    const returnValuePartner = (value, currency) => {
      if (currency) {
        return parseFloat(value).toFixed(2)
      } else {
        return (value || value === 0) ? value : '-'
      }
    }
    const returnItem = (currency, value, type) => {
      if (type === 'role') {
        return lblRol[value] || value
      } else if (type === 'status') {
        let IconComp = iconsTool[value] || iconsTool.unknown
        if (dataPartner.isBlocked) {
          IconComp = iconsTool.blocked
        }
        return <LightTooltip
          title={IconComp.text}
          placement='right'
        >
          <IconComp.icon className={[styles.iconStatus, IconComp.style]} />
        </LightTooltip>
      } else if (type === 'btActions') {
        return <MoreVert
          onClick={(e) => handleClickMore(e, `simple-popover-${indexPartner}`, dataPartner)}
        />
      } else if (type === 'createdAt') {
        const date = value.split('T')
        return date[0]
      } else {
        return `${currency}${returnValuePartner(value, currency)}`
      }
    }
    return headers.map((item, index) => {
      let currency = ''
      if (item.value === 'aov' || item.value.indexOf('Amount') > -1) {
        currency = '$'
      }
      return <Box
        key={'data-table-' + index}
        className={[
          styles.hdList,
          styles[item.colWidth],
        ].join(' ')}
      >
        {returnItem(currency, dataPartner[item.value], item.value)}
      </Box>
    })
  }

  const renderItemsList = () => {
    if (vc.listItems.length > 0) {
      return vc.listItems.map((item, index) => <Accordion
        className={styles.rowItem}
        key={'item-list-' + index}
        expanded={false}
      >
        <AccordionSummary
          aria-controls="panelc-content"
          id="panelc-header"
          className={styles.headerList2}
        >
          {renderDataPartner(item, index)}
        </AccordionSummary>
      </Accordion>)
    } else {
      return <p className={styles.emptyText}>
        No hay administradores para mostrar
      </p>
    }
  }

  const handleChangePage = (event, newPage) => {
    onChangeOrder({
      pageSelected: newPage,
      rowsPerPage: vc.rowsPerPage,
      orderBy: vc.orderBy,
      orderDirec: vc.orderDirec
    })
  };

  const handleChangeRowsPerPage = (event) => {
    onChangeOrder({
      pageSelected: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      orderBy: vc.orderBy,
      orderDirec: vc.orderDirec
    })
  };

  const onDelete = async () => {
    try {
      handleCloseMore()
      Model.setData('loading', true)
      const response = await UsersService.deleteUser(moreSelected.id)
      if (response.ok) {
        firstLoad()
        Model.setData('loading', false)
        Model.updateAlerts({
          message: 'Administrador eliminado',
          variant: 'done'
        })
      } else {
        throw new Error(response.problem)
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const returnPopover = () => {
    return <Popover
      anchorEl={anchorEl}
      id={moreOpened || undefined}
      open={Boolean(anchorEl)}
      onClose={handleCloseMore}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {hideResend ? null : <Box
        className={styles.btMoreAction}
        onClick={onResend}
      >
        Reenviar Invitación
      </Box>}
      <Box
        className={styles.btMoreAction}
        onClick={onDelete}
      >
        Borrar
      </Box>
    </Popover>
  }
  
  const renderSelectOptions = () => {
    const values = [
      {label: 'Filtrar por', value: ''},
      {label: 'Nombre', value: 'firstName'},
      {label: 'Apellido', value: 'lastName'},
      {label: 'Rol', value: 'role'},
      {label: 'Email', value: 'email'},
    ]
    return values.map((item, index) => <MenuItem 
      value={item.value}
      className={styles.itemSelect}
      key={'select-opt-' + index}
    >
      {item.label}
    </MenuItem>)
  }

  const onClean = async () => {
    try {
      if (vc.showClean) {
        changeVc({
          filterBy: '',
          searchText: '',
          showClean: false
        });
        Model.setData('loading', true)
        const response = await UsersService.getUsers(
          returnUrlVars(vc, ' ')
        );
        if (response.ok) {
          const data = _.get(response, 'data.data', [])
          const paging = _.get(response, 'data.paging', {})
          changeVc({
            pageSelected: 0,
            totalItems: paging.totalItems,
            listItems: data,
          });
        } else {
          throw new Error(response.problem)
        }
        Model.setData('loading', false)
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: String(e),
        variant: 'error'
      })
    }
  }

  const onSearch = async () => {
    try {
      if (vc.filterBy && vc.searchText) {
        Model.setData('loading', true)
        const response = await UsersService.getUsers(
          returnUrlVars(vc, `&${vc.filterBy}=${vc.searchText}`)
        );
        if (response.ok) {
          const data = _.get(response, 'data.data', [])
          const paging = _.get(response, 'data.paging', {})
          changeVc({
            pageSelected: 0,
            totalItems: paging.totalItems,
            listItems: data,
            showClean: true
          });
        } else {
          throw new Error(response.problem)
        }
        Model.setData('loading', false)
      } else {
        const filterErrorMsg = getFilterError({ filter: vc.filterBy, value: vc.searchText });
        throw new Error(filterErrorMsg);
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: String(e),
        variant: 'error'
      })
    }
  }

  const onNewPartner = async () => {
    try {
      const data = {
        firstName: vc.newName,
        lastName: vc.newlastName,
        email: vc.newEmail,
        type: vc.newRol,
      }
      changeVc({
        showAddModal: false,
        newName: '',
        newlastName: '',
        newEmail: '',
        newRol: '',
      })
      if (!data.firstName || !data.lastName || !data.email || !data.type) {
        throw new Error(t('common.notEmptyFields'))
      }
      Model.setData('loading', true)
      const response = await UsersService.createOne(data)
      if (response.ok) {
        firstLoad()
      } else {
        throw new Error(response.problem)
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const firstLoad = async () => {
    try {
      Model.setData('opacity', 0.7)
      Model.setData('loading', true)
      const response = await UsersService.getUsers(
        returnUrlVars(vc, `&${vc.filterBy}=${vc.searchText}`)
      );
      if (response.ok) {
        const data = _.get(response, "data.data", []);
        const paging = _.get(response, "data.paging", {});
        changeVc({
          totalItems: paging.totalItems,
          listItems: data,
        });
      } else {
        throw new Error(response.problem);
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  React.useEffect(() => {
    if (state.hasAdminPolicy) {
      firstLoad()
    } else {
      history.replace('/orders')
    }
    return () => {}
    //eslint-disable-next-line
  }, []);

  const renderStatusSelectOptions = () => {
    const values = [
      {label: t(`profile.roles.${'user_admin'}`),value: 'user_admin'},
      {label: t(`profile.roles.${'user_admin_agent'}`),value: 'user_admin_agent'},
      {label: t(`profile.roles.${'user_order_manager'}`),value: 'user_order_manager'},
      {label: t(`profile.roles.${'user_test'}`),value: 'user_test'},
    ];
    return values.map((item, index) => (
      <MenuItem
        value={item.value}
        className={styles.itemSelect}
        key={"select-opt-" + index}
      >
        {item.label}
      </MenuItem>
    ));
  };

  return <Layout menuSelected={2}>
    <Box className={styles.contBg}>
      <Box className={styles.container}>
        <Box className={styles.rowTitle}>
          <p className={styles.title}>
            {t('layout.admins')}
          </p>
          <Box className={styles.colInter} />
          <Button
            text='Crear Administrador'
            onClick={() => changeVc({showAddModal: true})}
            iconRight='iconmin-plus_circle-1'
          />
        </Box>
        <Paper
          elevation={3}
          className={styles.contInfo}
        >
          <Box className={styles.rowFilters}>
            <Select
              name='filterBy'
              value={vc.filterBy}
              onChange={handleChange}
              displayEmpty
              className={[styles.selectInput, styles.selectInput2, vc.filterBy ? '' : styles.grayTxt].join(' ')}
              inputProps={{ 'aria-label': 'Without label' }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: -10,
                  horizontal: "left"
                },
                getContentAnchorEl: null,
              }}
            >
              {renderSelectOptions()}
            </Select>
            {vc.filterBy === 'role' ? (
              <Select
                name="statusSelect"
                className={[
                  styles.selectInput,
                  styles.selectInput2,
                  vc.filterBy ? "" : styles.grayTxt,
                ].join(" ")}
                
                onChange={(event)=>{
                  changeVc({ 'searchText': event.target.value });
                }}
              >
                {renderStatusSelectOptions()}
              </Select>
            ) : (
              <Input
                placeholder={'Escribe aquí ...'}
                name='searchText'
                value={vc.searchText}
                onChange={handleChange}
                className={[styles.textInput, styles.textInput2].join(' ')}
                endAdornment={
                  <InputAdornment position="end">
                    <Search className={styles.iconSearch} />
                  </InputAdornment>
                }
              />
            )}
            {vc.showClean ? <Button
              text='Limpiar'
              onClick={onClean}
              variant='light'
              customStyles={styles.btClean}
            /> : null}
            <Button
              text='Buscar'
              onClick={onSearch}
            />
          </Box>
          <Box className={styles.contList}>
            <Box className={styles.headerList}>
              {renderHeaders()}
            </Box>
            <Box className={styles.listItems}>
              {renderItemsList()}
            </Box>
            {returnPopover()}
          </Box>
          <Box className={styles.rowPagination}>
            <Box style={{flex: 1}} />
            <TablePagination
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={vc.totalItems}
              rowsPerPage={vc.rowsPerPage}
              page={vc.pageSelected}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage='Administradores por página'
            />
          </Box>
        </Paper>
      </Box>
    </Box>
    <DialogForm
      show={vc.showAddModal}
      title={'Crear Administrador'}
      text='Por favor ingresar todos los datos que se solicitan'
      leftBtText='Cancelar'
      rightBtText='Guardar'
      funcLeft={() => changeVc({
        showAddModal: false,
        newName: '',
        newlastName: '',
        newEmail: '',
        newRol: '',
      })}
      funcRight={onNewPartner}
      inputs={[
        {label: 'Nombre', value: 'newName'},
        {label: 'Apellidos', value: 'newlastName'},
        {label: 'Email', value: 'newEmail'},
        {label: 'Rol', values: [
          {value: 'admin', label: 'Super admin'},
          {value: 'user_admin_agent', label: 'Agente'},
          {value: 'order_manager', label: 'Gestión de ordenes'},
          {value: 'user_test', label: 'Centro de Experiencia'},
        ], type: 'select', value: 'newRol'},
      ]}
      vc={vc}
      changeVc={changeVc}
    />
  </Layout>
}

export default Admins
