import _ from "lodash";
import React from "react";
import * as yup from "yup";
import { Field } from "formik";
import { useEffect } from "react";
import { parseJSON } from "date-fns";
import { useFormikContext } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { withSchema } from "../../formik/withSchema.util";

const CreationFields = (props) => {
  const { values, setFieldValue, validateForm } = useFormikContext();

  const to = `creation.toDate`;
  const from = `creation.fromDate`;
  const isTypeCreation = values.type === "creation";

  useEffect(() => {
    if (isTypeCreation) {
      setFieldValue(from, null);
      setFieldValue(to, null);
    }
    return () => {
      setFieldValue("creation", undefined);
    };
  }, [from, isTypeCreation, setFieldValue, to]);

  useEffect(() => {
    (async function run() {
      await validateForm();
    })();
  }, [validateForm, values]);

  const fromValidation = (candidate) => {
    const candidateDate = parseJSON(candidate);
    const maxDate = parseJSON(_.get(values, to, false));
    if (maxDate && candidateDate >= maxDate)
      return "from date cannot be greater than the to date";
    const schema = yup.date().required();
    return withSchema(schema)(candidate);
  };

  const toValidation = (candidate) => {
    const candidateDate = parseJSON(candidate);
    const minDate = parseJSON(_.get(values, from, false));
    if (minDate && candidateDate <= minDate)
      return "to date cannot be before the from date";
    const schema = yup.date().required();
    return withSchema(schema)(candidate);
  };

  if (!isTypeCreation) return null;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Field
        name={from}
        autoOk={true}
        label={"Desde"}
        variant={"inline"}
        disableToolbar={true}
        format={"MM/dd/yyyy"}
        validate={fromValidation}
        component={KeyboardDatePicker}
      />
      <Field
        name={to}
        autoOk={true}
        label={"Hasta"}
        variant={"inline"}
        format={"MM/dd/yyyy"}
        disableToolbar={true}
        validate={toValidation}
        component={KeyboardDatePicker}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CreationFields;
