import React from 'react';
import _ from 'lodash';
import firebase from './firebase'

export const Store = React.createContext();
const initialState = {
  lang: 'es',
  loading: true,
  opacity: 1,
  errorLoad: false,
  hideMenu: false,
  alerts: [],
  hasAdminPolicy: false,
  dialog: {
    open: false
  },
  userInfo: {},
  uid: _.get(firebase.auth(), 'currentUser.uid', ''),
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_STORE':
      return {...state, [action.label]: action.payload};
    case 'UPDATE_USERINFO':
      return {...state, userInfo: {
        ...state.userInfo,
        ...action.payload
      }};
    case 'UPDATE_ALERT':
      return {
        ...state,
        alerts: [...state.alerts, action.payload]
      };
    case 'RESET_DATA':
      return {...initialState};
    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = {state, dispatch};
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}