import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import NotificationModal from "./notification-modal";
import { getNotificationByUser } from "../../../services/notifications.service";

const NotificationServiceComponent = (props) => {
  const { isOpen, onCloseHandle, user } = props;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, pages: 1 });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      getNotificationByUser(user.uid, currentPage, 5)
        .then((response) => {
          setNotifications(response.data.data);
          setPagination(response.data.paging);
          setLoading(false);
        })
        .catch((reason) => {
          setError(reason);
        });
    }
  }, [isOpen, currentPage, user.uid]);

  return (
    <NotificationModal
      user={user}
      isOpen={isOpen}
      hasError={error}
      isLoading={loading}
      notifications={notifications}
      onCloseHandle={onCloseHandle}
      pagination={pagination}
      onChangePagination={setCurrentPage}
    />
  );
};

const NotificationService = React.memo(
  NotificationServiceComponent,
  (prevProps, nextProps) => {
    return prevProps.isOpen === nextProps.isOpen;
  }
);

export default NotificationService;
