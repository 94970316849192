import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserService from '../services/users';
import { Store } from '../hooks/main_store';
import firebase from '../hooks/firebase';
import Model from '../hooks/Model';
import _ from 'lodash';

const PrivateRoute = ({component: Component, ...rest}) => {
  const { state } = React.useContext(Store)
  const [endLoad, setEndLoad] = React.useState(false)

  const setUserRole = (policies) => {
    const roles = {
      'srn:iam:default:policies:user_admin': 'hasAdminPolicy',
      'srn:iam:default:policies:user_admin_agent': 'hasAgentPolicy',
      'srn:iam:default:policies:user_order_manager': 'hasOrderManagerPolicy',
      'srn:iam:default:policies:user_partner': 'hasPartnerPolicy',
      'srn:iam:default:policies:user_test': 'hasTestPolicy',
    } 
    
    policies.forEach((policy) => {
      const role = roles[policy];
      if (role) {
        Model.setData(role, true)
      }
    })
  }

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      try {
        if (!endLoad) {
          if (user) {
            // Get user info
            Model.setData('uid', user.uid)
            const userMe = await UserService.userMe()
            // console.log('userMe',userMe)
            if (userMe.ok) {
              const dataUser = _.get(userMe, 'data.data', {policies: []})
              Model.updateUserInfo(dataUser)
              setUserRole(dataUser.policies)
            } else {
              throw new Error('No user')
            }
          }
          setEndLoad(true)
        }
      } catch (e) {
        console.log(e)
        setEndLoad(true)
      }
    })
    //eslint-disable-next-line
  }, [])
  return endLoad ? <Route
    {...rest}
    render={(props) => state.uid
      ? <Component {...props} />
      : <Redirect to={{pathname: '/', state: {from: props.location}}} />}
  /> : null
}

export default PrivateRoute;
