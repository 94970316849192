import React from "react";
import { Paper } from "@material-ui/core";
import PartnersTable from "./components/partners-table";
import { useTranslation } from "react-i18next";

const PartnersSection = (props) => {
  const { user, reFetch } = props;
  const { t } = useTranslation();
  return (
    <Paper
      elevation={3}
      className={"flex flex-col p-8 gap-2 justify-between"}
    >
      <div className={"font-bold"}>{t('partners.clientPartners.title')}</div>
      <PartnersTable user={user} reFetch={reFetch} />
    </Paper>
  );
};

export default PartnersSection;
