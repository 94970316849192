import _ from "lodash";

const splitByNextLevel = (levelList, userStatus) => {
  if (_.isEmpty(levelList)) return [null, []];
  const list = [...levelList];
  const last = _.head(list);
  const { type, status } = last;
  const isTypeOk = type === "accepted";
  const validUserStatuses = ['validated', 'upgrade_accepted_no_signature', 'upgrade_accepted_pending_validation'];
  const isStatusOk = (status === "pending" || status === "pending_validation") && (validUserStatuses.includes(userStatus));
  if (!(isTypeOk && isStatusOk)) return [null, list];
  const nextLevel = list.shift();
  return [nextLevel, list];
};

const splitByCurrentLevel = (levelList) => {
  if (_.isEmpty(levelList)) return [null, []];
  const list = [...levelList];
  const listFiltered = list?.filter(l => l.status === 'validated' || l.status === 'committed');
// console.log("listFiltered:", listFiltered);
  const last = listFiltered?.shift();
  // console.log("last:", last);
  const restOfList = _.orderBy(
    list?.filter(l => l.level !== last?.level || l.status !== 'validated' || l.status !== 'committed'), ['createdAt', 'level'], 'desc');
  // console.log("restOfList:", restOfList);
  return [last, restOfList];
};

export const getRowsByLevelType = (levels, userStatus) => {
  const levelsDesc = _.orderBy(levels, ["level"], ["desc"]);
  const [nextLevel, levelsWithoutNext] = splitByNextLevel(levelsDesc, userStatus);
  const [currentLevel, historyLevels] = splitByCurrentLevel(levelsWithoutNext, userStatus);
  return [nextLevel, currentLevel, historyLevels];
};

export const getCurrentLevel = (levels, userStatus) => {
  const levelsDesc = _.orderBy(levels, ["level"], ["desc"]);
  const [last, Before] = splitByCurrentLevel(levelsDesc, userStatus);

  // Check if "Before" is not empty, assign its "commitmentPath" to "commitmentPath".
  if (Before && Before.commitmentPath) {
    //console.log("CommitmentPath (from Before):", Before.commitmentPath);
    return Before.commitmentPath;
  }

  // If "Before" is empty, check if "last" is not empty and assign its "commitmentPath" to "commitmentPath".
  if (last && last.commitmentPath) {
    // console.log("CommitmentPath (from last):", last.commitmentPath);
    return last.commitmentPath;
  }

};
