import _ from 'lodash'
import React from 'react'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import {Visibility, VisibilityOff} from '@material-ui/icons'
import fileStyles from './styles/partnersInviteStyles'
import Input from '../../components/input'
import Button from '../../components/button'
import Model from '../../hooks/Model'
import PartnerService from '../../services/partners'
import { getQueryParams, logoutSession } from '../../utils/common'

const Invite = (props) => {
  const { code } = getQueryParams(props.location.search)
  const styles = fileStyles()
  const { t } = useTranslation();
  const [vc, setVc] = React.useState({
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
  });

  const changeEye = (key = 'showPassword') => {
    setVc(oldValues => ({
      ...oldValues,
      [key]: !oldValues[key]
    }))
  }

  const goTo = (route) => {
    props.history.push(route)
  }

  const setPassword = async () => {
    try {
      Model.setData('loading', true)
      const result = await PartnerService.acceptInvite(code, vc.password)
      if(result.ok) {
        await logoutSession()
        return goTo('/login')
      } else {
        throw new Error('No se pudo completar la acción')
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: _.get(e, 'message', t('common.unknownError')),
        variant: 'error'
      })
    }
  }

  const submitForm = () => {
    const { password, confirmPassword } = vc;
    if (!password || !confirmPassword) {
      Model.updateAlerts({
        message: t('common.pleaseFill'),
        variant: 'error'
      })
    } else if (password !== confirmPassword) {
      Model.updateAlerts({
        message: t('common.matchPasswords'),
        variant: 'error'
      })
    } else {
      setPassword()
    }
  }

  const changeState = name => event => {
    event.persist()
    setVc(oldValues => ({
      ...oldValues,
      [name]: event.target.value
    }))
  }

  React.useEffect(() => {
    if(!code) {
      return props.history.replace('/login')
    }
    Model.setData('loading', false)
    Model.setData('opacity', 0.7)
    return () => {}
    //eslint-disable-next-line
  }, []);

  return <Box className={styles.container}>
    <img
      src={require('../../assets/images/bg-color.png')}
      className={styles.bgColor}
      alt='Unicomer'
    />
    <Box className={styles.content}>
      <img
        src={require('../../assets/images/logo.svg')}
        className={styles.logo}
        alt='Unicomer'
      />
      <p className={styles.title}>
        {t('partners.invite.welcome')}
      </p>
      <p className={styles.label}>
        {t('partners.invite.setPassword')}
      </p>
      <Box className={styles.passCont}>
        <Input
          type={vc.showPassword ? 'text' : 'password'}
          placeholder={t('common.password')}
          onChange={changeState('password')}
          value={vc.password}
          fullWidth
          customStyles={styles.inputPass}
        />
        <Box onClick={() => changeEye('showPassword')} className={styles.eyeBt}>
          {!vc.showPassword ? <Visibility /> : <VisibilityOff/>}
        </Box>
      </Box>
      <Box className={styles.passCont}>
        <Input
          type={vc.showConfirmPassword ? 'text' : 'password'}
          placeholder={t('common.confirmPassword')}
          onChange={changeState('confirmPassword')}
          value={vc.confirmPassword}
          fullWidth
          customStyles={styles.inputPass}
        />
        <Box onClick={() => changeEye('showConfirmPassword')} className={styles.eyeBt}>
          {!vc.showConfirmPassword ? <Visibility /> : <VisibilityOff/>}
        </Box>
      </Box>
      <Button
        text={t('partners.invite.accept')}
        variant='primary'
        customStyles={styles.sendBt}
        fullWidth
        onClick={submitForm}
      />
    </Box>
  </Box>
}

export default Invite;
