import { makeStyles } from '@material-ui/styles';
import Commons from '../../../utils/commons/styles';
import { colors } from '../../../styleguide';

const styles = makeStyles({
  contBg: {
    width: '100%',
    height: '100%',
    overflow:' auto',
    backgroundColor: colors.white,
    minWidth: 900
  },
  container: {
    margin: '0 auto',
    padding: 0,
    minHeight: '100%',
    minWidth: '100%',
    display: 'initial'
  },
  rowTitle: {
    ...Commons.flexCenter,
    padding: '0px 18px',
    position: 'relative',
    flexWrap: 'wrap',
    paddingTop: 18
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    margin: 0,
  },
  cardInfo: {
    boxShadow: colors.shadow2,
    backgroundColor: colors.white,
    borderRadius: 24,
    // cursor: 'pointer',
    marginLeft: 24,
    padding: '8px 16px',
  },
  titleCard: {
    margin: 0,
    fontSize: 13,
    textAlign: 'center'
  },
  subtitleCard: {
    margin: 0,
    fontSize: 11,
    textAlign: 'center'
  },
  contInfo: {
    padding: 18,
    margin: 18,
    height: 'calc(100% - 126px)',
    paddingBottom: 0
  },
  contFilters: {
    ...Commons.flexRow,
  },
  flexRow: {
    flex: 1
  },
  selectInput: {
    flex: 1,
    margin: '0px 18px',
    border: '1px solid #d4d4d4',
    borderRadius: 6,
    padding: '1.6px 12px',
    height: '100%',
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    },
    '& .MuiSelect-selectMenu': {
      backgroundColor: 'transparent !important',
      fontSize: 12,
    }
  },
  grayTxt: {
    color: colors.gray50
  },
  textInput: {
    flex: 1,
    border: '1px solid #d4d4d4',
    borderRadius: 6,
    padding: '4px 12px',
    fontSize: 12,
    height: '100%',
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    },
    marginRight: 18
  },
  iconSearch: {
    fontSize: 18,
    color: '#a1a1a1'
  },
  contList: {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: 'calc(100% - 100px)'
  },
  headerList: {
    ...Commons.flexRow,
    borderBottom: `1px solid ${colors.gray50}`,
    marginTop: 12,
    display: 'inline-flex',
    minWidth: 1308,
    width: '100%',
    backgroundColor: colors.mainxText
  },
  hdListBold: {
    ...Commons.flexRow,
    height: 56,
    fontSize: 14,
    fontWeight: 'bold',
    padding: '12px 12px',
    lineHeight: '16px',
    color: colors.white,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.primary
    }
  },
  hdSelected: {
    backgroundColor: colors.primary
  },
  hdNohover: {
    backgroundColor: `${colors.mainxText} !important`,
    cursor: 'initial'
  },
  hdList: {
    ...Commons.flexCenter,
    justifyContent: 'flex-start',
    fontSize: 14,
    padding: '0px 12px',
    lineHeight: '14px',
  },
  headerList2: {
    fontSize: 14,
    padding: 0,
    lineHeight: '16px',
    '& span': {
      color: colors.gray50,
      fontWeight: 'normal'
    },
    minWidth: 1308,
    width: '100%',
    borderBottom: `1px solid ${colors.gray50}`,
  },
  col1c: {
    ...Commons.flexCenter,
    //maxWidth: 60,
    flex: 1,
  },
  col1: {
    minWidth: 60,
    flex: 1
  },
  col2: {
    minWidth: 120,
    flex: 2
  },
  col3: {
    minWidth: 150,
    flex: 3
  },
  col4: {
    minWidth: 180,
    flex: 4
  },
  rowItem: {
    boxShadow: 'none',
    border: 0,
    borderRadius: '0px !important',
    minWidth: 1308,
    width: '100%',
    margin: '0px !important',
    '&:before': {
      height: 0
    },
    '&:hover': {
      backgroundColor: '#f4f4f4'
    }
  },
  listItems: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100% - 80px)',
    minWidth: 1308,
    width: '100%'
  },
  itemSelected: {
    backgroundColor: '#f4f4f4',
  },
  detailsItem: {
    display: 'block'
  },
  rowInterItem: {
    ...Commons.flexRow,
    width: 'calc(100% - 120px)',
    marginLeft: 120,
    borderBottom: `1px solid ${colors.gray50}`,
    padding: '24px 0px',
    '&:last-child': {
      border: 'none'
    }
  },
  colInter: {
    flex: 1,
    fontSize: 12
  },
  emptyText: {
    color: colors.gray50,
    textAlign: 'center',
    margin: 60
  },
  iconStatus: {
    fontSize: 24,
    color: colors.mainxText
  },
  colorOrange: {
    color: colors.orange
  },
  colorGreen: {
    color: colors.primary
  },
  colorYellow: {
    color: colors.yellow
  },
  colorRed: {
    color: colors.red
  },
  colorBlue: {
    color: colors.blue
  },
  btMoreAction: {
    backgroundColor: colors.primary,
    color: colors.white,
    textAlign: 'center',
    fontSize: 12,
    padding: '12px 8px',
    fontWeight: 'bold',
    cursor: 'pointer',
    opacity: 0.75,
    '&:hover': {
      opacity: 1
    }
  },
  rowFilters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  selectInput2: {
    maxWidth: 240,
  },
  textInput2: {
    maxWidth: 240,
  },
  textInputRange: {
    maxWidth: 120,
    marginRight: 18,
    marginLeft: 18
  },
  btClean: {
    marginRight: 18
  },
  rowPagination: {
    ...Commons.flexRow,
    justifyContent: 'space-between'
  },
  spaceBetweenColumns: {
    justifyContent:'space-evenly'
  },
  pickerDate: {
    margin: '0px 18px',
    border: '1px solid #d4d4d4',
    borderRadius: 6,
    padding: '3px 12px',
    height: '100%',
    maxWidth: 120,
    paddingRight: 0,
    '& .MuiIconButton-root': {
      padding: 6
    },
    '& input': {
      fontSize: 11,
    },
    '& label[data-shrink="false"] + .MuiInputBase-formControl .MuiInputBase-input::placeholder': {
      opacity: '1 !important',
      fontSize: 11,
    },
    '& label': {
      top: -16,
      left: '50%',
      transform: 'translateX(-50%)',
      fontSize: 11,
      width: '100%',
      textAlign: 'center'
    },
    '& .MuiInput-underline': {
      marginTop: '0px !important',
      '&:before': {
        display: 'none'
      },
      '&:after': {
        display: 'none'
      },
    },
    '& p': {
      display: 'none !important'
    }
  },
});

export default styles;