import React from 'react'
import {
  Box,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TablePagination,
  Tooltip,
  Select,
  MenuItem,
  Input,
  InputAdornment,
  Popover,
} from '@material-ui/core'
import {
  ArrowDropUp,
  ArrowDropDown,
  CheckCircle,
  Help,
  Send,
  Search,
  Cancel,
  MonetizationOn,
  NoteAdd,
  ArrowUpward,
  ArrowDownward,
  MoneyOff,
  MoreVert,
  Receipt
} from '@material-ui/icons'
import { MuiPickersUtilsProvider,  KeyboardDatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { getFilterError } from '../../utils/common';
import { limitNum } from '../../utils/validations'
import OrderService from '../../services/orders'
import PartnerService from '../../services/partners'
import { Store } from '../../hooks/main_store'
import { useTranslation } from 'react-i18next'
import fileStyles from './styles/ordersStyles'
import Button from '../../components/button'
import DialogForm from '../../components/dialogForm'
import MomentUtils from '@date-io/moment';
import Model from '../../hooks/Model'
import HdValues from './hdValues'
import Layout from '../layout'
import moment from 'moment'
import _ from 'lodash'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: '#0D9DA2',
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const Orders = () => {
  const { t } = useTranslation()
  const headers = HdValues(t)
  const styles = fileStyles()
  const { state } = React.useContext(Store);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState(null);
  const [moreOpened, setMoreOpened] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [moreSelected, setMoreSelected] = React.useState({})
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false)
  const [vc, setVc] = React.useState({
    orderBy: 'withdrawalAt',
    orderDirec: 'DESC',
    expandedItem: -1,
    totalItems: 0,
    pageSelected: 0,
    rowsPerPage: 10,
    listItems: [],
    filterBy: '',
    searchText: '',
    showClean: false,
    statistics: {},
    orderInfo: {},
    beneficiaries: [],
    extraCredit: ''
  })

  const statusIcons = {
    'sent': { 'icon': Send, 'description': t('orders.sentHelp'), 'color':  styles.colorOrange },
    'processed': { 'icon': CheckCircle, 'description': t('orders.processedHelp'), color: styles.colorGreen },
    'generated': { 'icon': NoteAdd, 'description':  t('orders.generatedHelp') },
    'paid': { 'icon': MonetizationOn, 'description': t('orders.paidHelp'), color: styles.colorBlue },
    'cancelled': { 'icon': Cancel, 'description': t('orders.cancelledHelp'), color: styles.colorRed },
    'indebted': { 'icon': MoneyOff, 'description': t('orders.indebtedHelp'), color: styles.colorRed },
    'unknown': { 'icon': Help, 'description': t('orders.unknownHelp') },
  }

  const changeVc = (value) => setVc(ov => ({...ov, ...value}))
  const changeOrder = (value) =>  setVc(ov => ({...ov, orderInfo: {...ov.orderInfo, ...value}}))
  
  const handleClickMore = (e, id, item) => {
    setMoreOpened(id !== moreOpened ? id : '')
    setAnchorEl(e.currentTarget)
    setVc(ov => ({ ...ov, expandedItem: -1 }))
    setMoreSelected(item)
  }

  const handleCloseMore = () => {
    setAnchorEl(null)
    setMoreOpened('')
    setMoreSelected({})
    setVc(ov => ({ ...ov, expandedItem: -1 }))
  }

  const handleDateChangeTo = (date) => {
    setSelectedDateTo(date);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const returnRangeDate = (opt) => {
    if (opt === 'min') {
      if (selectedDate && selectedDate._isValid) {
        return moment(selectedDate._d).format('YYYY-MM-DD')
      } else {
        return ''
      }
    } else {
      if (selectedDateTo && selectedDateTo._isValid) {
        return moment(selectedDateTo._d).format('YYYY-MM-DD')
      } else {
        return ''
      }
    }
  }

  const returnDate = (date) => {
    if (date && date._isValid) {
      return moment(date._d).format('YYYY-MM-DD')
    } else {
      return ''
    }
  }

  const returnUrlVars = (
    obj = {},
    additional = vc.showClean ? `&${vc.filterBy}=${vc.searchText}` : '',
    skipDate = false
  ) => {
    if(state.hasPartnerPolicy && vc.filterBy === 'status') {
      additional = `&paid=${vc.searchText === 'paid'}`
    }
    const dateFrom = returnDate(selectedDate)
    const dateTo = returnDate(selectedDateTo)
    const extraCredit = vc.extraCredit ? `&extraCredit=${vc.extraCredit}` : ''
    const urlDate = dateFrom && dateTo && !skipDate ? `${dateFrom ? `&fromDate=${dateFrom}` : ''}${dateTo ? `&toDate=${dateTo}` : ''}` : ''
    return `?page=${obj.pageSelected + 1}&limit=${obj.rowsPerPage}&sort=${obj.orderBy}&direction=${obj.orderDirec}` + extraCredit + additional + urlDate
  }

  const handleChangeItem = (index, expanded) => {
    if (!moreOpened) {
      setVc(ov => ({
        ...ov,
        expandedItem: expanded ? index : -1
      }));
    }
  };

  const handleChange = (event) => {
    if (event.persist) {
      event.persist()
    }
    if (event.target) {
      changeVc({ 'searchText': '' });
      changeVc({[event.target.name]: event.target.value});
      setShowDropdownStatus(event.target.value === 'status');
    }
  };

  const onChangeOrder = async (orderObj) => {
    try {
      if (orderObj.orderDirec) {
        setVc(ov => ({
          ...ov,
          expandedItem: -1
        }));
        Model.setData('loading', true)
        //createdAt, code, orderId, withdrawalAt, amount]
        const response = await OrderService.getOrders(returnUrlVars(orderObj))
        if (response.ok) {
          const data = _.get(response, 'data.data', [])
          const paging = _.get(response, 'data.paging', {})
          changeVc({
            ...orderObj,
            pageSelected: orderObj.pageSelected,
            totalItems: paging.totalItems,
            listItems: data,
          });
        } else {
          throw new Error(response.problem)
        }
        Model.setData('loading', false)
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `Error [${String(e).replace('Error: ', '')}]`,
        variant: 'error'
      })
    }
  }

  const renderHeaders = () => {
    return headers.map((item, index) => <Box
      key={'header-table-' + index}
      className={[
        styles.hdListBold,
        styles[item.colWidth],
        vc.orderBy === item.value ? styles.hdSelected : '',
        item.value === 'btActions' ? styles.hdNohover : '',
      ].join(' ')}
      onClick={() => {
        if (item.value === 'btActions') { return }
        let orderObj = {}
        if (vc.orderBy === item.value) {
          orderObj = {
            orderBy: vc.orderBy,
            orderDirec: vc.orderDirec === 'ASC' ? 'DESC' : 'ASC'
          }
        } else {
          orderObj = {
            orderBy: item.value,
            orderDirec: 'DESC'
          }
        }
        onChangeOrder({
          ...orderObj,
          rowsPerPage: vc.rowsPerPage,
          pageSelected: 0
        })
      }}
    >
      {item.label} {vc.orderBy === item.value ? (vc.orderDirec === 'ASC' ? <ArrowDropUp /> : <ArrowDropDown />) : null}
    </Box>)
  }

  const onProcess = async (item, action) => {
    try {
      const isPay = action === 'pay';
      Model.setData('dialog', {open: false})
      Model.setData('loading', true)
      const response = await OrderService.processOrder(item.id, isPay ? 'paid' : 'cancelled')
      if (response.ok) {
        Model.setData('loading', false)
        Model.updateAlerts({
          message: 'Orden ' + (isPay ? 'pagada' : 'anulada'),
          variant: 'done'
        })
        firstLoad()
      } else {
        throw new Error(response.problem)
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: String(e),
        variant: 'error'
      })
    }
  }

  const onConfirmOrder = async() => {
    try {
      const requiredData = new Set(['amount', 'withdrawalAt', 'productId'])
      setShowConfirmDialog(false);
      Model.setData('loading', true)
      const notFound = [];
      requiredData.forEach((key) => !vc.orderInfo[key] && notFound.push(key))
      if (!_.isEmpty(notFound)) {
        Model.setData('loading', false)
        console.log('notFound:', notFound);
        return Model.updateAlerts({
          message: `${t('common.pleaseFill')}: ${notFound.map(item => { return t('orders.'+item)}).join(',')}`,
          variant: 'error'
        })
      }
      console.log('vc.orderInfo:', vc.orderInfo);
      const { productId, amount, withdrawalAt, id, beneficiaryId } = vc.orderInfo;
      const response = await OrderService.updateOrder(id, { productId, amount, withdrawalAt, beneficiaryId})
      if (response.ok) {
        Model.setData('loading', false)
        Model.updateAlerts({
          message: t('orders.orderConfirmed'),
          variant: 'done'
        })
        firstLoad()
      } else {
        throw new Error(response.problem)
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: String(e),
        variant: 'error'
      })
    }
  }

  const handleClickProcess = (item, action) => {
    const isPay = action === 'pay';
    handleCloseMore()
    Model.setData('dialog', {
      open: true,
      title: (isPay ? t('orders.payOrder') : t('orders.cancelOrder')) + ` ${item.orderId}`,
      text: t('orders.areUsure', {status: isPay ? 'pagar' : 'anular'}),
      txtLeft: t('common.cancel'),
      txtRight: t('common.yes'),
      fnLeft: () => Model.setData('dialog', {open: false}),
      fnRight: () => onProcess(item, action),
      onClose: () => Model.setData('dialog', {open: false}),
    })
    setTimeout(() => {
      setVc(ov => ({ ...ov, expandedItem: -1 }))
    }, 50);
  }

  const handleConfirm = (item) => {
    handleCloseMore()
    setShowConfirmDialog(true)
    item.productId = item.productId || ''
    setVc(ov => ({ ...ov, orderInfo: item }))
  }

  const [showDropdownStatus, setShowDropdownStatus] = React.useState(false);

  const returnPopover = () => {
    return moreOpened && <Popover
      anchorEl={anchorEl}
      id={moreOpened || undefined}
      open={Boolean(anchorEl)}
      onClose={handleCloseMore}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {(state.hasAdminPolicy && moreSelected.status === 'processed') && (
        <Box
          className={styles.btMoreAction}
          onClick={() => handleClickProcess(moreSelected, 'pay')}
        >
          {t('orders.pay')}
        </Box>
      )}
      {(state.hasAdminPolicy || (state.hasOrderManagerPolicy && moreSelected.toCancel)) && (
        <Box
          className={styles.btMoreAction}
          onClick={() => handleClickProcess(moreSelected, 'cancel')}
        >
          {t('orders.cancel')}
        </Box>
      )}
      {moreSelected.status === 'sent' && (
        <Box
          className={styles.btMoreAction}
          onClick={() => handleConfirm(moreSelected)}
        >
          {t('orders.edit')}
        </Box>
      )}
    </Popover>
  }

  const renderDataOrder = (dataOrder, indexOrder) => {
    const returnValueOrder = (value, currency) => {
      if (currency) {
        return parseFloat(value).toFixed(2)
      } else {
        return (value || value === 0) ? value : '-'
      }
    }
    const returnItem = (currency, value, type) => {
      if (type === 'status') {
        const iconPayload = state.hasPartnerPolicy
          ? dataOrder.paid
            ? statusIcons['paid']
            : statusIcons['indebted']
          : statusIcons[value] || statusIcons.unknown;
        return (
          state.hasPartnerPolicy
            ? <iconPayload.icon className={[styles.iconStatus, iconPayload.color]} />
            : <LightTooltip
                title={iconPayload.description}
                placement='left'
              >
                <iconPayload.icon className={[styles.iconStatus, iconPayload.color]} />
              </LightTooltip>
        )
      } else if (type === 'btActions') {
        const showAction = (
          ((dataOrder.toCancel ||  dataOrder.status === 'sent') && state.hasOrderManagerPolicy)
          || ((dataOrder.toCancel || dataOrder.status === 'processed' || dataOrder.status === 'sent') && state.hasAdminPolicy)
        )

        return showAction && (
          <MoreVert
            onClick={(e) => handleClickMore(e, `simple-popover-${indexOrder}`, dataOrder)}
          />
        )
      } else if (type === 'code') {
        const icon = dataOrder?.orderLabel?.icon
        return <>{icon
            ? <img className={"w-5 h-5 mr-1"} alt={"profile"} src={icon} />
            : <Receipt color="primary" fontSize="medium" className='mr-1' style={{ marginLeft: -2 }} />
          }
          {`${currency}${returnValueOrder(value, currency)}`}
        </>
      } else if (type === 'usedExtraCredit') {
        return value ? t('common.yes') : t('common.no');
      } else if (type === 'orderId') {
        return dataOrder?.orderBranchOffice ? `${returnValueOrder(value, false)} / ${dataOrder?.orderBranchOffice}` : returnValueOrder(value, false)
      } else if (type === 'user') {
        return `${dataOrder?.firstName} ${dataOrder?.lastName}`;
      }
      else {
        return `${currency}${returnValueOrder(value, currency)}`
      }
    }
    return headers.map((item, index) => {
      let currency = ''
      if (item.value.indexOf('amount') > -1) {
        currency = dataOrder[item.value] ? '$' : ''
      }
      return <Box
        key={'data-table-' + index}
        className={[
          styles.hdList,
          styles[item.colWidth]
        ].join(' ')}
      >
        {returnItem(currency, dataOrder[item.value], item.value)}
      </Box>
    })
  }

  const renderItemsList = () => {
    if (vc.listItems.length > 0) {
      return vc.listItems.map((item, index) => {
        let rate = _.get(item, 'details.rate', '-')
        if (rate !== '-'){
          rate = _.get(item, 'details.rate', '-').toFixed(2)
        }
        return <Accordion
          className={[styles.rowItem, vc.expandedItem === index ? styles.itemSelected : ''].join(' ')}
          key={'item-list-' + index}
          expanded={vc.expandedItem === index && !moreOpened}
          onChange={(e, expanded) => handleChangeItem(index, expanded)}
        >
          <AccordionSummary
            aria-controls="panelc-content"
            id="panelc-header"
            className={styles.headerList2}
          >
            {renderDataOrder(item, index)}
          </AccordionSummary>
          <AccordionDetails className={styles.detailsItem}>
            <Box className={styles.rowInterItem}>
              {item.photoPath ? <Box className={styles.colInter}>
                <b>{t('orders.linkBill')}:</b><br/>
                <a
                  href={item.photoPath}
                  target='_blank'
                  rel="noopener noreferrer"
                >
                  {t('common.clickHere')}
                </a>
              </Box> : null}
              <Box className={styles.colInter}>
                <b>{t('users.cellPhone')}:</b><br/>
                {_.get(item, 'cellPhone', '-')}
              </Box>
              <Box className={styles.colInter}>
                <b>{t('orders.dueDate')}:</b><br/>
                {_.get(item, 'details.dueDate', '-')}
              </Box>
            </Box>
            <Box className={styles.rowInterItem}>
              <Box className={styles.colInter}>
                <b>{t('orders.rate')}:</b><br/>
                {rate}%
              </Box>
              <Box className={styles.colInter}>
                <b>{t('orders.months')}:</b><br/>
                {_.get(item, 'details.months', '-')}
              </Box>
              <Box className={styles.colInter}>
                <b>{t('orders.level')}:</b><br/>
                {_.get(item, 'level', 0)}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>})
    } else {
      return <p className={styles.emptyText}>
        {t('orders.noOrders')}
      </p>
    }
  }

  const handleChangePage = (event, newPage) => {
    onChangeOrder({
      pageSelected: newPage,
      rowsPerPage: vc.rowsPerPage,
      orderBy: vc.orderBy,
      orderDirec: vc.orderDirec
    })
  };

  const handleChangeRowsPerPage = (event) => {
    onChangeOrder({
      pageSelected: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      orderBy: vc.orderBy,
      orderDirec: vc.orderDirec
    })
  };

  const renderSelectOptions = () => {
    const values = [
      {label: t('common.filterBy'), value: ''},
      {label: t('users.dui'), value: 'dui'},
      {label: t('ordersList.orderId'), value: 'orderId'},
      {label: t('ordersList.orderBranchOffice'), value: 'orderBranchOffice'},
      {label: t('ordersList.confirmationCode'), value: 'code'},
      {label: t('ordersList.productId'), value: 'productId'},
      {label: t('orders.status'), value: "status" },
      {label: t('orders.extraCredit'), value: "usedExtraCredit" },
      {label: t('users.amount'), value: "amount" },
      {label: t('ordersList.clientCode'), value: "clientCode" },
      {label: t('users.user'), value: "userName" },
      {label: t('users.tradeName'), value: "tradeName" },
    ]
    return values.map((item, index) => <MenuItem 
      value={item.value}
      className={styles.itemSelect}
      key={'select-opt-' + index}
    >
      {item.label}
    </MenuItem>)
  }

  const renderStatusSelectOptions = () => {
    const values = state.hasPartnerPolicy
      ? [
        {label: t(`orders.paidValues.${'paid'}`),value: 'paid'},
        {label: t(`orders.paidValues.${'unpaid'}`),value: 'unpaid'}
      ]
      : [
        {label: t(`orders.statusValues.${'paid'}`),value: 'paid'},
        {label: t(`orders.statusValues.${'sent'}`),value: 'sent'},
        {label: t(`orders.statusValues.${'processed'}`),value: 'processed'},
        {label: t(`orders.statusValues.${'accounted'}`),value: 'accounted'},
        {label: t(`orders.statusValues.${'cancelled'}`),value: 'cancelled'},
        {label: t(`orders.statusValues.${'indebted'}`),value: 'indebted'}
      ];
    return values.map((item, index) => (
      <MenuItem
        value={item.value}
        className={styles.itemSelect}
        key={"select-opt-" + index}
      >
        {item.label}
      </MenuItem>
    ));
  };

  const onClean = () => {
    if (vc.showClean) {
      changeVc({
        filterBy: '',
        searchText: '',
        showClean: false
      });
      setSelectedDate(null)
      setSelectedDateTo(null)
      firstLoad()
    }
  }

  const onSearch = async () => {
    try {
      // eslint-disable-next-line
      if (vc.filterBy && vc.searchText || (selectedDate && selectedDateTo) || (vc.filterBy && vc.extraCredit !== '')) {
        Model.setData('loading', true)
        let urlFilt = vc.filterBy && vc.searchText ? `&${vc.filterBy}=${vc.searchText}` : ''
        if(state.hasPartnerPolicy && vc.filterBy === 'status') {
          urlFilt = `&paid=${vc.searchText === 'paid'}`
        }
        const response = await OrderService.getOrders(returnUrlVars(vc, urlFilt))
        if (response.ok) {
          const data = _.get(response, 'data.data', [])
          const paging = _.get(response, 'data.paging', {})
          changeVc({
            pageSelected: 0,
            totalItems: paging.totalItems,
            listItems: data,
            showClean: true
          });
        } else {
          throw new Error(response.problem)
        }
        Model.setData('loading', false)
      } else {
        const filterError = vc.filterBy || (selectedDate && selectedDateTo);
        const valueError = vc.searchText || (selectedDate && selectedDateTo) || vc.extraCredit !== '';
        const filterErrorMsg = getFilterError({ filter: filterError, value: valueError });
        throw new Error(filterErrorMsg);
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: String(e),
        variant: 'error'
      })
    }
  }

  const onlyCancelled = async () => {
    try {
      Model.setData('loading', true)
      const response = await OrderService.getOrders(returnUrlVars(vc, '&status=cancelled'))
      if (response.ok) {
        const data = _.get(response, 'data.data', [])
        const paging = _.get(response, 'data.paging', {})
        changeVc({
          pageSelected: 0,
          totalItems: paging.totalItems,
          listItems: data,
          showClean: true
        });
      } else {
        throw new Error(response.problem)
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: String(e),
        variant: 'error'
      })
    }
  }

  const onExport = async () => {
    try {
      Model.setData('loading', true)
      let additionUrl = ''
      const dateFrom = returnDate(selectedDate)
      const dateTo = returnDate(selectedDateTo)
      if ((vc.filterBy && vc.searchText) || (dateFrom && dateTo)) {
        additionUrl = '?'
        if (vc.filterBy && vc.searchText) {
          additionUrl = additionUrl + (state.hasPartnerPolicy ? `paid=${vc.searchText === 'paid'}` : `${vc.filterBy}=${vc.searchText}`)
        }
        if (dateFrom && dateTo) {
          additionUrl = additionUrl + (additionUrl.length === 1 ? '' : '&') + `fromDate=${dateFrom}&toDate=${dateTo}` 
        }
      }
      const response = await OrderService.exportTable(additionUrl)
      if (response.ok) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'orders-list.xlsx');
        document.body.appendChild(link);
        link.click();
      } else {
        let resErr = response.problem
        if (response?.data?.text) {
          resErr = JSON.parse(await response.data.text()).message
        }
        throw new Error(resErr)
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const firstLoad = async () => {
    try {
      Model.setData('opacity', 0.7)
      Model.setData('loading', true)

      const [ response, responseStats, beneficiaryResponse ] = await Promise.all([
        OrderService.getOrders(returnUrlVars(vc, '', true)),
        OrderService.getStatistics(),
        PartnerService.getPartners()
      ])

      // validate get orders request
      if (response.ok) {
        const data = _.get(response, 'data.data', [])
        const paging = _.get(response, 'data.paging', {})
        changeVc({
          pageSelected: 0,
          totalItems: paging.totalItems,
          listItems: data
        });
      } else {
        throw new Error(response.problem)
      }
      
      // validate get stats request
      if (responseStats.ok) {
        const data = _.get(responseStats, 'data.data', {})
        changeVc({
          statistics: data
        });
      } else {
        throw new Error(responseStats.problem)
      }
      
      // validate get beneficiaries request
      if (beneficiaryResponse.ok){
        const data = _.get(beneficiaryResponse, 'data.data');
        changeVc({
          beneficiaries: data.map(beneficiary => {
            return {
              label: beneficiary.name,
              value: beneficiary.id,
              orderLabel: beneficiary?.orderLabel || {}
            }
          })
        });
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `Error [${String(e).replace('Error: ', '')}]`,
        variant: 'error'
      })
    }
  }

  React.useEffect(() => {
    firstLoad()
    return () => {}
    //eslint-disable-next-line
  }, []);

  const PartnerLabel = ({ partner }) => {
    const { label, icon } = partner?.orderLabel;
    return <div className={styles.orderLabel}>{icon
        ? <img className={"w-5 h-5 mr-1"} alt={"profile"} src={icon} />
        : <Receipt color="primary" fontSize="medium" className='mr-1' style={{ marginLeft: -2 }} />
      }<p>{label || 'Número de orden'}</p>
    </div>
  }

  return <Layout menuSelected={3}>
    <Box className={styles.contBg}>
      <Box className={styles.container}>
        <Box className={styles.rowTitle}>
          <p className={styles.title}>
            {t('common.orders')}
          </p>
          <Box className={styles.cardInfo}>
            <p className={styles.titleCard}>
              {t('orders.active')}: <b>{vc.statistics.activeOrders} </b> 
            </p>
            <p className={styles.subtitleCard}>
              <b className={styles.colorRed}>{vc.statistics.unpaidOrders || 0}</b> {t('orders.indebt')}
            </p>
          </Box>
          <Box className={styles.cardInfo}>
            <p className={styles.titleCard}>
              {t('orders.aov')}: <b>${limitNum(vc.statistics.aovLastYear)} </b> 
            </p>
            <p className={styles.subtitleCard}>
              {t('common.last')} <b>{t('common.year')}</b>
            </p>
          </Box>
          <Box className={styles.cardInfo}>
            <p className={styles.titleCard}>
              {t('orders.aov')}: <b>${limitNum(vc.statistics.aovLastMonth)} </b>
              <span className={vc.statistics.growthRate >= 0 ? styles.colorGreen : styles.colorRed}>
                {isNaN(vc.statistics.growthRate) ? '-' : limitNum(parseFloat(vc.statistics.growthRate).toFixed(2) * 100)}% {vc.statistics.growthRate >= 0 ? <ArrowUpward className={styles.iconArrow} /> : <ArrowDownward className={styles.iconArrow} />}
              </span>
            </p>
            <p className={styles.subtitleCard}>
            <b>{t('common.pluralLast')}</b> 30 {t('common.days')}
            </p>
          </Box>
          <Box className={styles.cardInfo}>
            <p className={styles.titleCard}>
              {t('orders.aov')}: <b>${limitNum(vc.statistics.aovPenultimateMonth)} </b> 
            </p>
            <p className={styles.subtitleCard}>
              <b>{t('common.previous')}</b>
            </p>
          </Box>
          {state.hasAdminPolicy || state.hasOrderManagerPolicy ? <Box className={styles.cardInfo}>
            <p className={styles.titleCard}>
              {t('orders.bestBeneficiary')}: <b>{_.get(vc, 'statistics.bestPartner.name', '-')}</b> 
            </p>
            <p className={styles.subtitleCard}>
              {t('common.total')}: <b>${limitNum(_.get(vc, 'statistics.bestPartner.total', ''))}</b> / {t('orders.aov')}: <b>${limitNum(_.get(vc, 'statistics.bestPartner.aov', ''))}</b>
            </p>
          </Box> : null}
        </Box>
        <Paper
          elevation={3}
          className={styles.contInfo}
        >
          <Box className={styles.rowFilters}>
            {vc.beneficiaries?.length ? (
              <Box className={styles.orderLabelContainer}>
                {vc.beneficiaries?.map((b, key) => (<PartnerLabel key={key} partner={b} />))}
              </Box>
            ) : ''}
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                format='YYYY-MM-DD'
                value={selectedDate}
                onChange={handleDateChange}
                className={styles.pickerDate}
                label={t('common.firstDate')}
                placeholder='YYYY-MM-DD'
                maxDate={returnRangeDate('max')}
              />
            </MuiPickersUtilsProvider>
            <Box className={styles.lblTo}>
              a
            </Box>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                format='YYYY-MM-DD'
                value={selectedDateTo}
                onChange={handleDateChangeTo}
                className={styles.pickerDate}
                label={t('common.endDate')}
                placeholder='YYYY-MM-DD'
                minDate={returnRangeDate('min')}
              />
            </MuiPickersUtilsProvider>
            <Select
              name='filterBy'
              value={vc.filterBy}
              onChange={handleChange}
              displayEmpty
              className={[styles.selectInput, styles.selectInput2, vc.filterBy ? '' : styles.grayTxt].join(' ')}
              inputProps={{ 'aria-label': 'Without label' }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: -10,
                  horizontal: "left"
                },
                getContentAnchorEl: null,
              }}
            >
              {renderSelectOptions()}
            </Select>
            { vc.filterBy === 'extraCredit' ? (
              <Select
                name='requireCode'
                value={vc.extraCredit}
                onChange={(e) => changeVc({ 'extraCredit': e.target.value })}
                displayEmpty
                className={[styles.selectInput, styles.selectInput2].join(' ')}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: -10,
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem 
                  value={''}
                  className={styles.itemSelect}
                >
                  {t('orders.extraCredit')}
                </MenuItem>
                <MenuItem 
                  value={true}
                  className={styles.itemSelect}
                >
                  {t('common.yes')}
                </MenuItem>
                <MenuItem 
                  value={false}
                  className={styles.itemSelect}
                >
                  {t('common.no')}
                </MenuItem>
              </Select>
            ) : (showDropdownStatus ?
              <Select
                name="statusSelect"
                className={[
                  styles.selectInput,
                  styles.selectInput2,
                  vc.filterBy ? "" : styles.grayTxt,
                ].join(" ")}
                
                onChange={(event)=>{
                  changeVc({ 'searchText': event.target.value });
                }}
              >
                {renderStatusSelectOptions()}
              </Select>
              :  
              <Input
                placeholder={t('common.writeHere')+' ...'}
                name='searchText'
                value={vc.searchText}
                onChange={handleChange}
                className={[styles.textInput, styles.textInput2].join(' ')}
                endAdornment={
                  <InputAdornment position="end">
                    <Search className={styles.iconSearch} />
                  </InputAdornment>
                }
              />)
            }
            {vc.showClean ? <Button
              text={t('common.clean')}
              onClick={onClean}
              variant='light'
              customStyles={styles.btClean}
            /> : null}
            <Button
              text={t('common.search')}
              onClick={onSearch}
            />
          </Box>
          <Box className={styles.contList}>
            <Box className={styles.headerList}>
              {renderHeaders()}
            </Box>
            <Box className={styles.listItems}>
              {renderItemsList()}
            </Box>
            {returnPopover()}
          </Box>
          <Box className={styles.rowPagination}>
            <Button
              text={t('common.export')}
              iconRight='iconmin-download-3'
              onClick={onExport}
            />
            {state.hasAdminPolicy || state.hasOrderManagerPolicy ? <Button
              text={t('orders.seeCancelled')}
              onClick={onlyCancelled}
              variant='light'
            /> : null}
            <TablePagination
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={vc.totalItems}
              rowsPerPage={vc.rowsPerPage}
              page={vc.pageSelected}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={t('orders.perPage')}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
    <DialogForm
      show={showConfirmDialog}
      title={t('orders.confirmOrder', {order: vc.orderInfo.orderId})}
      text={t('orders.confirmOrderDescription')}
      leftBtText={t('common.cancel')}
      rightBtText={t('common.save')}
      funcLeft={() => setShowConfirmDialog(false)}
      funcRight={onConfirmOrder}
      inputs={[
        {label: t('orders.amount'), value: 'amount'},
        {label: t('orders.date'), value: 'withdrawalAt', type: 'date', maxAmount: 'now'},
        {label: t('orders.productId'), value: 'productId'},
        {label: t('orders.beneficiaries'), value: 'beneficiaryId', values: vc.beneficiaries, type:'select'},
      ]}
      vc={vc.orderInfo}
      changeVc={changeOrder}
    />
  </Layout>
}

export default Orders
