import React from "react";
import { Pagination } from "@material-ui/lab";

const NotificationPagination = (props) => {
  const { pagination, onChangePagination } = props;

  const handlePageChange = (event, page) => {
    onChangePagination(page);
  };

  return (
    <div className={"flex flex-column justify-end"}>
      <Pagination
        page={pagination.page}
        count={pagination.pages}
        color="primary"
        onChange={handlePageChange}
      />
    </div>
  );
};

export default NotificationPagination;
