import React from "react";
import LevelTableRow from "./components/level-table-row";
import { getRowsByLevelType } from "./util/level-table.util";

const LevelTable = (props) => {
  const { user, reFetch } = props;
  const [next, current, histories] = getRowsByLevelType(user?.levelHistory, user?.status);
  //console.log('check this status', user.status)
  //console.log('Enlace a la Línea de Crédito Rotativa:', current?.commitmentPath || '#');
  //console.log("datos level history2: ",user?.levelHistory)
  //console.log("datos user status2: ",user?.status)
  return (
    <div className={"col-span-2 p-8 pr-0"}>
      {
        user.status === 'validated' ?
          <table className={"table-auto w-full"}>
            <thead>
              <tr className={"text-center font-bold uppercase h-7"}>
                <th className={"text-left text-2xl w-32"}>Niveles</th>
                <th className={"w-10"}>Nivel</th>
                <th>Fecha</th>
                <th>LCR y Anexo</th>
                <th>Estado</th>
                <th>Limite</th>
                <th>Tasa</th>
                <th>Plazo</th>
                <th>Limite Cash</th>
                <th>Tasa Cash</th>
                <th>Plazo Cash</th>
              </tr>
            </thead>
            <tbody>
              {next ? (
                <LevelTableRow
                  type={'next'}
                  reFetch={reFetch}
                  uid={user?.uid}
                  level={next?.level}
                  status={next?.status}
                  rate={next?.rate}
                  days={next?.days}
                  label={"Proximo Nivel"}
                  date={next?.createdAt}
                  payment={next?.commitmentPath}
                  maxAmount={next?.maxAmount}
                  rateCash={next?.rateCash}
                  daysCash={next?.daysCash}
                  maxAmountCash={next?.maxAmountCash}
                />) : null}
              {current ? (
                <LevelTableRow
                  type={'current'}
                  reFetch={reFetch}
                  uid={user?.uid}
                  rate={current?.rate}
                  days={current?.days}
                  level={current?.level}
                  status={current?.status}
                  label={"Nivel Actual"}
                  date={current?.updatedAt}
                  payment={current?.commitmentPath}
                  maxAmount={current?.maxAmount}
                  rateCash={current?.rateCash}
                  daysCash={current?.daysCash}
                  maxAmountCash={current?.maxAmountCash}
                />) : null}

              {histories && histories?.length ? (
                <>
                  {histories?.map((history, index) => (
                    <LevelTableRow
                      type={'history'}
                      reFetch={reFetch}
                      uid={user?.uid}
                      key={history.id}
                      rate={history?.rate}
                      days={history?.days}
                      level={history?.level}
                      status={history?.status}
                      date={history?.updatedAt}
                      payment={history?.commitmentPath}
                      maxAmount={history?.maxAmount}
                      rateCash={history?.rateCash}
                      daysCash={history?.daysCash}
                      maxAmountCash={history?.maxAmountCash}
                      label={index === 0 ? "Historico" : null}
                    />
                  ))}
                </>
                
              ) : null}
            </tbody>
          </table> :
          null
      }
    </div>
  );
};

export default LevelTable;
