import { makeStyles } from '@material-ui/styles';
import Commons from '../../../utils/commons/styles';
import { colors } from '../../../styleguide';

const styles = makeStyles({
  container: {
    ...Commons.flexCenter,
    position: 'relative',
    background: 'white',
    overflow: 'auto',
    height: '100vh',
    width: '100vw',
    minHeight: 700
  },
  bgColor: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  logo: {
    height: 42,
    margin: '0 auto'
  },
  content: {
    ...Commons.flexCenter,
    flexDirection: 'column',
    backgroundColor: colors.white,
    padding: 48,
    boxShadow: colors.shadow,
    borderRadius: 18,
    maxWidth: 450
  },
  title: {
    color: colors.mainxText,
    fontWeight: '700',
    textAlign: 'center',
    fontSize: 30,
    maxWidth: 350,
    margin: '30px 0px'
  },
  label: {
    color: colors.mainxText,
    textAlign: 'center',
    fontSize: 18,
    margin: 0,
    opacity: 0.5,
    marginBottom: 30,
    marginTop: -18
  },
  input: {
    margin: '10px 0px',
    '& input': {      
      width: '100%'
    }
  },
  sendBt: {
    maxWidth: 165,
    marginTop: 40
  },
  forgotPassBtn: {
    maxWidth: 225,
    marginTop: 15,
    backgroundColor: 'transparent',
    color: colors.primary,
    border: 'none',
    boxShadow: 'none',
    transition: 'none'
  },
  passCont: {
    position: 'relative',
    width: '100%'
  },
  eyeBt: {
    position: 'absolute',
    cursor: 'pointer',
    right: 12,
    zIndex: 1,
    top: 24,
    color: colors.mainxText,
    opacity: 0.5
  },
  inputPass: {
    margin: '10px 0px',
    '& input': {
      paddingRight: 60,
      width: '100%'
    }
  },
});

export default styles;