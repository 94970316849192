import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import userServices from "../../../../../services/users";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../../../components/loading-button";
import { useHistory } from "react-router-dom";
import firebase from '../../../../../hooks/firebase'
import { Store } from "../../../../../hooks/main_store";
const analytics = firebase.analytics();

const PendingValidationAction = (props) => {
  const { user } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = React.useContext(Store);
  const [loading, setLoading] = useState({ accepted: false, rejected: false })

  const approveCredit = async () => {
    try {
      setLoading({ accepted: true });
      console.log("Approve")
      console.log('analytics.logEvent:', analytics.logEvent)

      analytics.logEvent('Onboarding_Validar_Documentos', { uid: user.uid })

      // await userServices.changeUser(user.id, {status: 'validated'});
      // await userServices.createCredit({uid: user.uid});
      // await userServices.changeStatus(user.id, 'activate');
      await userServices.activateCredit({ id: user.id, uid: user.uid });
      setLoading({ accepted: false });
      history.go(0);
    } catch (error) {
      setLoading({ accepted: false });
      console.error('approveCredit:', error);
    }

  }

  const reject = async () => {
    try {
      setLoading({ rejected: true });
      await userServices.changeUser(user.id, { status: 'cancelled' });
      setLoading({ rejected: false });
      history.go(0);
    } catch (error) {
      setLoading({ rejected: false });
      console.error('reject:', error);
    }
  }

  return (
    <Paper elevation={3} className={"flex flex-col justify-between gap-4 p-8 "}>
      {!state.hasTestPolicy &&
        <>
          <div className={"text-justify"}>
            El cliente ha enviado todos los documentos.
          </div>
          <div className={"flex flex-row-reverse gap-2"}>

            <LoadingButton
              type={"button"}
              label={t("users.validateDocs")}
              color={"primary"}
              children={"Aprobar"}
              isLoading={loading['accepted']}
              variant={"contained"}
              loadingLabel={"procesando"}
              onClick={approveCredit}
            />
            <LoadingButton
              type={"button"}
              label={t("users.reject")}
              color={"primary"}
              children={"Rechazar"}
              isLoading={loading['rejected']}
              variant={"contained"}
              loadingLabel={"procesando"}
              onClick={reject}
            />
          </div>
        </>
      }

    </Paper>
  );
};

export default PendingValidationAction;
