import { makeStyles } from '@material-ui/core/styles';
const squareSize = 125;
const cellDateFontSize = 12;
const cellDetailsFontSize = 10;

const useStyles = makeStyles({
  auditCalendar: {
    display: 'flex',
    justifyContent: 'center',
  },
  calendarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  calendarMonthsControl: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 90,
    fontWeight: 'bold',
    fontSize: 24
  },
  calendarHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  calendarHeaderCell: {
    backgroundColor: '#1D253C',
    width: squareSize,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 35,
    color: 'white',
    '&:first-child': {
      borderRadius: '15px 0 0 0'
    },
    '&:last-child': {
      borderRadius: '0 15px 0 0'
    }
  },

  calendarCellRow: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  calendarCell: {
    height: squareSize,
    width: squareSize,
    border: '1px solid grey'
  },
  calendarCellDay: {
    height: '20%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  calendarCellDayContent: {
    margin: 5,
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
    height: 25,
    fontSize: cellDateFontSize,
    color: '#A1ADB5'
  },
  calendarCellDetails: {
    height: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  calendarDetail: {
    fontSize: cellDetailsFontSize,
    fontWeight: 'bold',
    '& span': {
      fontWeight: 'normal'
    }
  },

  modalSummary: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '16px 0px'
  },
  modalDayInfo: {
    height: 300,
    minHeight: 300,
    minWidth: '80%',
    overflowY: 'auto',
    backgroundColor: '#F9F9F9',
    border: '2px dashed #79DBE1',
  },

  // custom day styles
  currentMonth: {
    fontWeight: 'bold',
    color: 'black'
  },
  today: {
    color: 'white',
    borderRadius: '50%',
    backgroundColor: '#1D253C',
  },

  // cell background styles
  success: {
    backgroundColor: '#CFF3F2'
  },
  wrong: {
    backgroundColor: '#FDEDD3'
  },
  notProcessed: {
    backgroundColor: '#F6CCD1',
    borderLeft: '4px solid #BD192E'
  }
});

export default useStyles;