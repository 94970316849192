import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styleguide'

const useStyles = makeStyles({
  container: {
    width: 'fit-content',
    '& .MuiTypography-body1': {
      fontSize: 14,
      color: colors.mainxText,
    },
    '& .MuiSwitch-root': {
      width: 64,
      height: 44
    },
    '& .MuiSwitch-switchBase': {
      padding: 14,
      backgroundColor: 'transparent !important'
    },
    '& .MuiSwitch-track': {
      borderRadius: 12,
      backgroundColor: colors.mainxText,
      opacity: 0.25
    },
    '& .MuiSwitch-thumb': {
      width: 16,
      height: 16,
      backgroundColor: `${colors.white} !important`
    },
    '& .Mui-checked +  .MuiSwitch-track': {
      backgroundColor: colors.primary,
      opacity: 1
    },
    '& .MuiTouchRipple-root': {
      display: 'none !important'
    }
  },
  message: {
    color: colors.mainxText,
    opacity: 0.5,
    fontSize: 14,
    margin: 0,
    position: 'relative',
    marginLeft: 54,
    marginTop: -10
  },
  disabled: {
    '& .MuiTypography-body1': {
      color: colors.mainxText,
      opacity: 0.5,
      fontSize: 14,
    },
    '& .MuiSwitch-thumb': {
      opacity: 0.5
    },
  }
});

export default useStyles;