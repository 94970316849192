import React, { useContext } from "react";

import { useHistory } from 'react-router';
import Model from '../../../../hooks/Model';
import { useTranslation } from "react-i18next";
import BlockAccessButton from "./components/block-access-button";
import BlockPurchaseButton from "./components/block-purchase-button";
import DeleteAccountButton from "./components/delete-user-button";
import { Store } from "../../../../hooks/main_store";

const HeaderSection = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user, reFetch } = props;
  const { state } = useContext(Store);

  const onDeleteUser = () => {
    history.push('/users');
    setTimeout(() => {
      Model.updateAlerts({
        message: t('users.deleteSuccessful'),
        variant: "success",
      })
    }, 3000);
  }

  return (
    <div className={"col-span-3 flex flex-row justify-between gap-4"}>
      <div className={"flex items-end font-bold uppercase"}>
        Perfil de usuario
      </div>
      <div className={"flex flex-row-reverse gap-4"}>
        <BlockAccessButton user={user} reFetch={reFetch} />
        {
          !state.hasTestPolicy ? (
            <BlockPurchaseButton user={user} reFetch={reFetch} />
          ) : null
        }
        {
          !state.hasTestPolicy ? (
            <DeleteAccountButton user={user} afterUpdate={onDeleteUser} />
          ) : null
        }
      </div>
    </div>
  );
};

export default HeaderSection;
