import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router";

import Layout from "../layout";
import Model from "../../hooks/Model";
import ProfileContent from "./profile-content";
import { getUser } from "../../services/users";

const ProfilePage = () => {
  const defaultUser = { data: undefined, hasError: false, isLoading: false };

  const history = useHistory();
  const { userId } = useParams();
  const [user, setUser] = useState(defaultUser);
  const [fetch, reFetch] = useState(0);

  useEffect(() => {
    Model.setData("loading", true);
    setUser((prev) => ({ ...prev, isLoading: true }));

    getUser(userId)
      .then((response) => {
        const user = response?.data?.data;
        setUser((prev) => ({ ...prev, data: user, isLoading: false }));
        Model.setData("loading", false);
      })
      .catch((error) => {
        console.log("ERROR", error)  
        setUser((prev) => ({ ...prev, isLoading: false }));
        history.push("/404");
      });
  }, [fetch, history, userId]);

  if (user.isLoading || user.hasError || !user.data) return null;

  return (
    <Layout>
      <div className={"p-8"}>
        <ProfileContent user={user.data} reFetch={reFetch} />
      </div>
    </Layout>
  );
};

export default ProfilePage;
