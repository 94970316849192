import React from 'react'
import {
  Box,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TablePagination,
  Tooltip,
  Select,
  MenuItem,
  Input,
  InputAdornment,
  Popover,
  Chip,
  IconButton,
} from '@material-ui/core'
import MButton from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles';
import {
  ArrowDropUp,
  ArrowDropDown,
  CheckCircle,
  Help,
  Cancel,
  MoreVert,
  Search,
  Assignment,
  AssignmentInd,
  AssignmentLate,
  Autorenew,
  Cached,
  Lock,
  PanTool,
  Warning,
  Add,
  Delete,
} from '@material-ui/icons'
import PartnerService from '../../../services/partners'
import { useTranslation } from 'react-i18next'
import fileStyles from './styles/partnerMembersStyles'
import Model from '../../../hooks/Model'
import {Store} from '../../../hooks/main_store'
import Layout from '../../layout'
import HdValues from './hdValuesMembers'
import Button from '../../../components/button'
import _ from 'lodash'
import { useParams } from 'react-router-dom';
import UsersService from '../../../services/users'
import DialogForm from '../../../components/dialogForm';
import { getFilterError } from '../../../utils/common';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const PartnerMembers = ({ history }) => {
  const { id } = useParams()
  const {state} = React.useContext(Store)
  const { t } = useTranslation()
  const headers = HdValues(t)
  const styles = fileStyles()
  const iconsTool = {
    onboarding: {
      icon: Autorenew,
      style: styles.colorOrange,
      text: t("users.onboardingHelp"),
    },
    pending: {
      icon: Cached,
      style: styles.colorBlue,
      text: t("users.pendingHelp"),
    },
    approved: {
      icon: Assignment,
      style: styles.colorGreen,
      text: t("users.approvedHelp"),
    },
    committed: {
      icon: Assignment,
      style: styles.colorGreen,
      text: t("users.committedHelp"),
    },
    pending_validation: {
      icon: AssignmentLate,
      style: styles.colorOrange,
      text: t("users.pendingValidationHelp"),
    },
    pending_approval: {
      icon: AssignmentLate,
      style: styles.colorOrange,
      text: t("users.pendingApprovalHelp"),
    },
    validated: {
      icon: CheckCircle,
      style: styles.colorGreen,
      text: t("users.validatedHelp"),
    },
    invalidated: {
      icon: PanTool,
      style: styles.colorYellow,
      text: t("users.invalidatedHelp"),
    },
    cancelled: {
      icon: Cancel,
      style: styles.colorRed,
      text: t("users.cancelledHelp"),
    },
    unauthorized: {
      icon: Lock,
      style: styles.colorRed,
      text: t("users.unauthorizedHelp"),
    },
    blocked: {
      icon: Lock,
      style: styles.colorYellow,
      text: t("users.blockedHelp"),
    },
    indebted: {
      icon: Warning,
      style: styles.colorOrange,
      text: t("users.indebtedHelp"),
    },
    admin_registered: {
      icon: AssignmentInd,
      style: styles.colorBlue,
      text: t("users.adminRegisterHelp"),
    },
    upgrade_request: {
      icon: AssignmentLate, 
      style: styles.colorOrange, 
      text: t('users.upgradeRequestHelp')},
    upgrade_qualified: {
      icon: AssignmentLate, 
      style: styles.colorOrange, 
      text: t('users.upgradeQualifiedHelp')
    },
    upgrade_accepted_no_signature: {
      icon: AssignmentLate, 
      style: styles.colorOrange, 
      text: t('users.upgradeAcceptedNoSignatureHelp')
    },
    upgrade_accepted_pending_validation: {
      icon: AssignmentLate, 
      style: styles.colorOrange, 
      text: t('users.upgradeAcceptePendingValidationHelp')
    },
    unknown: { icon: Help, style: "", text: t("users.unknownHelp") },
  };
  const defaultBranchOfficeFields = [
    {
      selectedBranchOffice: '',
      selectedBranchOfficesRoutes: [],
    }
  ];
  const [vc, setVc] = React.useState({
    expandedItem: -1,
    orderBy: 'status',
    orderDirec: 'DESC',
    totalItems: 0,
    pageSelected: 0,
    rowsPerPage: 10,
    listItems: [],
    filterBy: '',
    searchText: '',
    showClean: false,
    showUpdateModal: false,
    firstName: '',
    lastName: '',
    email: '',
    memberId: null,
    confirmationMail: false,
    newRegistrationMail: false,
    
    // branch offices flow
    branchOffices: [],
    filteredBranchOffices: [],
    showBranchOfficesModal: false,
    branchOfficeFields: defaultBranchOfficeFields
  })
  const [moreOpened, setMoreOpened] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [moreSelected, setMoreSelected] = React.useState({})
  const changeVc = (value) => setVc(ov => ({...ov, ...value}))

  const returnUrlVars = (
    obj = {},
    additional = vc.showClean ? `&${vc.filterBy}=${vc.searchText}` : ''
  ) => {
    return `?page=${obj.pageSelected + 1}&limit=${obj.rowsPerPage}&sort=${obj.orderBy}&direction=${obj.orderDirec}` + additional
  }

  const handleChange = (event) => {
    if (event.persist) {
      event.persist()
    }
    if (event.target) {
      changeVc({[event.target.name]: event.target.value})
    }
  };

  const renderHeaders = () => {
    return headers.map((item, index) => <Box
      key={'header-table-' + index}
      className={[
        styles.hdListBold,
        styles[item.colWidth],
        vc.orderBy === item.value ? styles.hdSelected : '',
        item.value === 'btActions' ? styles.hdNohover : '',
      ].join(' ')}
      onClick={() => {
        if (item.value === 'btActions') { return }
        let orderObj = {}
        if (vc.orderBy === item.value) {
          orderObj = {
            orderBy: vc.orderBy,
            orderDirec: vc.orderDirec === 'ASC' ? 'DESC' : 'ASC'
          }
        } else {
          orderObj = {
            orderBy: item.value,
            orderDirec: 'DESC'
          }
        }
        onChangeState({
          ...orderObj,
          rowsPerPage: vc.rowsPerPage,
          pageSelected: 0
        })
      }}
    >
      {item.label} {vc.orderBy === item.value ? (vc.orderDirec === 'ASC' ? <ArrowDropUp /> : <ArrowDropDown />) : null}
    </Box>)
  }


  const handleClickMore = (e, id, item) => {
    setMoreOpened(id !== moreOpened ? id : '')
    setAnchorEl(e.currentTarget)
    setMoreSelected(item)
  }

  const handleCloseMore = () => {
    setAnchorEl(null)
    setMoreOpened('')
    setMoreSelected({})
  }

  const handleExpandItem = (index, expanded) => {
    if (!moreOpened) {
      changeVc({ expandedItem: expanded ? index : -1 });
    }
  };

  const showBranchOffices = (member) => {
    return member?.requireBranchOffice && member.confirmationMail;
  }

  const renderDataPartner = (dataPartner, indexPartner) => {
    const returnValuePartner = (value, currency) => {
      if (currency) {
        return parseFloat(value).toFixed(2)
      } else {
        return (value || value === 0) ? value : '-'
      }
    }
    const returnItem = (currency, value, type) => {
      if (type === 'status') {
        let IconComp = iconsTool[value] || iconsTool.unknown
        if (dataPartner.isBlocked) {
          IconComp = iconsTool.unauthorized
        }
        return <LightTooltip
          title={IconComp.text}
          placement='right'
        >
          <IconComp.icon className={[styles.iconStatus, IconComp.style]} />
        </LightTooltip>
       } else if (type === 'btActions') {
        return <MoreVert
          onClick={(e) => handleClickMore(e, `simple-popover-${indexPartner}`, dataPartner)}
        />
       } else if (type === 'newRegistrationMail') {
        return value ? t('common.yes') : t('common.no');
       } else if (type === 'confirmationMail') {
        const text = value ? t('common.yes') : t('common.no');
        return <Box className={'flex flex-col justify-center'}>
          <p>{text}</p>
          {showBranchOffices(dataPartner)
            ? <p className={styles.branchOffices} onClick={(e, expanded) => handleExpandItem(indexPartner, vc.expandedItem === -1)}>{t('common.seeBranchOffices')}</p>
            : ''
          }
        </Box>
      } else if (type === 'createdAt') {
        const date = value.split('T')
        return date[0]
      } else {
        return `${currency}${returnValuePartner(value, currency)}`
      }
    }
    return headers.map((item, index) => {
      let currency = ''
      if (item.value === 'aov' || item.value.indexOf('Amount') > -1) {
        currency = '$'
      }
      return <Box
        key={'data-table-' + index}
        className={[
          styles.hdList,
          styles[item.colWidth],
        ].join(' ')}
      >
        {returnItem(currency, dataPartner[item.value], item.value)}
      </Box>
    })
  }

  const getMemberBranchOffices = (offices = []) => {
    const data = {}
    offices.forEach(office => {
      if(data[office?.branchOffice]) {
        data[office?.branchOffice].routes.push(office.route)
      } else {
        data[office?.branchOffice] = { routes: [office.route] };
      }
    })
    return Object.keys(data)
      .filter(k => data[k]?.routes?.length)
      .map(k => ({ name: k, routes: _.sortBy(data[k]?.routes) }))
  }

  const renderItemsList = () => {
    if (vc.listItems.length > 0) {
      return vc.listItems.map((item, index) => {
        const memberBranchOffices = getMemberBranchOffices(item?.branchOffices?.data);
        return (
          <Accordion
            className={styles.rowItem}
            key={'item-list-' + index}
            expanded={vc.expandedItem === index}
          >
            <AccordionSummary
              aria-controls="panelc-content"
              id="panelc-header"
              className={styles.headerList2}
            >
              {renderDataPartner(item, index)}
            </AccordionSummary>
            {showBranchOffices(item) ? (
              <AccordionDetails className={styles.detailsItem}>
                <Box className={styles.rowInterItemOffices}>
                  {memberBranchOffices?.length ? memberBranchOffices?.map((office, i) => (
                    <Box key={`branch-office-${i}`} className={styles.branchOfficeBox}>
                      <Box className={'font-bold'}>{office?.name}</Box>
                      {office?.routes?.length ? (`Rutas: ${office?.routes?.join(', ')}`) : ''}
                    </Box>
                  )) : 'No hay Sucursales para mostrar'}
                </Box>
                <Box style={{padding: 0}} className={styles.rowInterItem}>
                  <MButton onClick={() => onOpenBranchOfficesModal(item)} color="primary">
                    {'Editar'}
                  </MButton>
                </Box>
              </AccordionDetails>
            ) : ''}
          </Accordion>
        )
      })
    } else {
      return <p className={styles.emptyText}>
        No hay aliados para mostrar
      </p>
    }
  }

  const onChangeState = async (orderObj) => {
    try {
      if (orderObj.orderDirec) {
        Model.setData('loading', true)
        const response = await PartnerService.getMembers(id, returnUrlVars(orderObj))
        if (response.ok) {
          const data = _.get(response, 'data.data', [])
          const paging = _.get(response, 'data.paging', {})
          changeVc({
            ...orderObj,
            pageSelected: orderObj.pageSelected,
            totalItems: paging.totalItems,
            listItems: data,
          });
        } else {
          throw new Error(response.problem)
        }
        Model.setData('loading', false)
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const handleChangePage = (event, newPage) => {
    onChangeState({
      pageSelected: newPage,
      rowsPerPage: vc.rowsPerPage,
      orderBy: vc.orderBy,
      orderDirec: vc.orderDirec
    })
  };

  const handleChangeRowsPerPage = (event) => {
    onChangeState({
      pageSelected: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      orderBy: vc.orderBy,
      orderDirec: vc.orderDirec
    })
  };

  const renderSelectOptions = () => {
    const values = [
      {label: 'Filtrar por', value: ''},
      {label: 'Nombre', value: 'firstName'},
      {label: 'Apellido', value: 'lastName'},
      {label: 'Email', value: 'email'}
    ]
    return values.map((item, index) => <MenuItem 
      value={item.value}
      className={styles.itemSelect}
      key={'select-opt-' + index}
    >
      {item.label}
    </MenuItem>)
  }

  const onClean = () => {
    if (vc.showClean) {
      changeVc({
        filterBy: '',
        searchText: '',
        showClean: false
      });
      firstLoad()
    }
  }

  const onSearch = async () => {
    try {
      if (vc.filterBy && vc.searchText) {
        Model.setData('loading', true)
        const response = await PartnerService.getMembers(id, returnUrlVars(vc, `&${vc.filterBy}=${vc.searchText}`))
        if (response.ok) {
          const data = _.get(response, 'data.data', [])
          const paging = _.get(response, 'data.paging', {})
          changeVc({
            pageSelected: 0,
            totalItems: paging.totalItems,
            listItems: data,
            showClean: true
          });
        } else {
          throw new Error(response.problem)
        }
        Model.setData('loading', false)
      } else {
        const filterErrorMsg = getFilterError({ filter: vc.filterBy, value: vc.searchText });
        throw new Error(filterErrorMsg);
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: String(e),
        variant: 'error'
      })
    }
  }

  const processBranchOffices = (offices) => {
    const officesMap = {};
    return offices?.filter(o => {
      if(!officesMap[o.branchOffice]) {
        officesMap[o.branchOffice] = true;
        return true;
      }
      return false;
    }).map(o => ({ label: o.branchOffice, value: o.branchOffice }))
  }

  const getBranchOfficesRoutes = (branchOfficeFilter = '') => {
    return vc.branchOffices
      .filter(bo => bo.branchOffice === branchOfficeFilter)
      .map(bo => ({ label: `Ruta ${bo.route}`, value: bo.route, branchOffice: bo.branchOffice }))
  }

  const firstLoad = async () => {
    try {
      Model.setData('opacity', 0.7)
      Model.setData('loading', true)
      const search = vc.filterBy && vc.searchText ? `&${vc.filterBy}=${vc.searchText}` : ''
      const [branchOfficesResponse, membersResponse] = await Promise.all([
        PartnerService.getBranchOffices(),
        PartnerService.getMembers(id, returnUrlVars(vc, search))
      ])

      if (branchOfficesResponse.ok) {
        const data = _.get(branchOfficesResponse, 'data.data', [])
        changeVc({ branchOffices: data, filteredBranchOffices: processBranchOffices(data) });
      } else {
        throw new Error(branchOfficesResponse.problem)
      }

      if (membersResponse.ok) {
        const data = _.get(membersResponse, 'data.data', [])
        const paging = _.get(membersResponse, 'data.paging', {})
        changeVc({
          totalItems: paging.totalItems,
          listItems: data
        });
      } else {
        throw new Error(membersResponse.problem)
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const onOpenUpdateModal = (item2Edit) => {
    setAnchorEl(null)
    setMoreOpened('')
    changeVc({
      firstName: item2Edit?.firstName,
      lastName: item2Edit?.lastName,
      email: item2Edit?.email,
      confirmationMail: !!item2Edit?.confirmationMail,
      newRegistrationMail: !!item2Edit?.newRegistrationMail,
      memberId: item2Edit?.id,
      showUpdateModal: true
    })
  }

  const onOpenBranchOfficesModal = (item2Edit) => {
    setAnchorEl(null)
    setMoreOpened('')
    const itemBranchOffices = getMemberBranchOffices(item2Edit?.branchOffices?.data)
    const data = itemBranchOffices?.map(bo => {
      return {
        selectedBranchOffice: bo?.name,
        selectedBranchOfficesRoutes: bo.routes,
      } 
    })

    changeVc({
      memberId: item2Edit?.id,
      showBranchOfficesModal: true,
      branchOfficeFields: getBranchOfficeFields(data?.length ? data : defaultBranchOfficeFields)
    })
  }

  const onInvite = async (userToInvite) => {
    try {
      handleCloseMore()
      const { email, firstName, lastName } = userToInvite
      if(!email) {
        throw new Error('No se puede invitar a este usuario por que no tiene email.')
      }
      Model.setData('loading', true)
      const response = await PartnerService.invite(id, { email, firstName, lastName })
      if (response.ok) {
        Model.updateAlerts({
          message: 'Invitacion enviada correctamente',
          variant: 'done'
        })
      } else {
        const message = response.status === 409 ? t('partners.codeExistingError') : response.problem;
        throw new Error(message)
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const onDeleteUser = async (userToDelete) => {
    try {
      handleCloseMore()
      Model.setData("dialog", { open: false });
      Model.setData('loading', true)
      const response = await UsersService.deleteUser(userToDelete?.id)
      if (response.ok) {
        firstLoad()
        Model.setData('loading', false)
        Model.updateAlerts({
          message: 'Usuario eliminado',
          variant: 'done'
        })
      } else {
        throw new Error(response.problem)
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const onStatusChange = async (userToChange, action) => {
    try {
      handleCloseMore()
      if (action) {
        Model.setData('loading', true)
        const response = await UsersService.changeStatus(userToChange?.id, action)
        if (response.ok) {
          firstLoad()
          Model.setData('loading', false)
          Model.updateAlerts({
            message: 'Usuario actualizado',
            variant: 'done'
          })
        } else {
          throw new Error(response.problem)
        }
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const updateMember = async () => {
    try {
      handleCloseMore()
      Model.setData('loading', true)
      const { firstName, lastName, email, confirmationMail, newRegistrationMail, memberId } = vc;
      changeVc({
        firstName: '',
        lastName: '',
        email: '',
        confirmationMail: false,
        newRegistrationMail: false,
        showUpdateModal: false
      })
      if (!firstName || !lastName || !email) {
        throw new Error(t('common.notEmptyFields'))
      }
      const response = await PartnerService.updateMember(id, memberId, {
        firstName,
        lastName,
        email,
        confirmationMail,
        newRegistrationMail
      })
      if (response.ok) {
        firstLoad()
        Model.updateAlerts({
          message: 'Miembro actualizado',
          variant: 'done'
        })
      } else {
        throw new Error(response.problem)
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    } finally {
      changeVc({
        firstName: '',
        lastName: '',
        email: '',
        confirmationMail: false,
        newRegistrationMail: false,
        showUpdateModal: false
      })
      Model.setData('loading', false)
    }
  }

  const updateBranchOffices = async () => {
    try {
      handleCloseMore()
      Model.setData('loading', true)
      const { branchOfficeFields, memberId } = vc;
      const member = vc.listItems?.find(item => item.id === memberId);
      if(!member) {
        throw new Error('Miembro no encontrado')
      }
      const { firstName, lastName, email, confirmationMail, newRegistrationMail } = member;
      const newOffices = branchOfficeFields?.filter(bo => bo.type === 'selectMultiple');
      const branchOffices = vc.branchOffices
        ?.filter(bo => newOffices?.some(nbo => nbo?.branchOfficeName === bo.branchOffice && nbo.value?.includes(bo.route)));

      const response = await PartnerService.updateMember(id, memberId, {
        firstName,
        lastName,
        email,
        confirmationMail,
        newRegistrationMail,
        branchOffices: { data: branchOffices }
      })
      if (response.ok) {
        firstLoad()
        Model.updateAlerts({
          message: 'Miembro actualizado',
          variant: 'done'
        })
      } else {
        throw new Error(response.problem)
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    } finally {
      changeVc({
        showBranchOfficesModal: false,
        branchOfficeFields: defaultBranchOfficeFields
      })
      Model.setData('loading', false)
    }
  }

  const returnPopover = () => {
    return <Popover
      anchorEl={anchorEl}
      id={moreOpened || undefined}
      open={Boolean(anchorEl)}
      onClose={handleCloseMore}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box
        className={styles.btMoreAction}
        onClick={() => onOpenUpdateModal(moreSelected)}
      >
        Editar miembro
      </Box>
      {!moreSelected?.firstTimeLogin && (
        <Box
          className={styles.btMoreAction}
          onClick={() => onInvite(moreSelected)}
        >
          Reenviar invitación
        </Box>
      )}
      <Box
        className={styles.btMoreAction}
        onClick={() => {
          Model.setData("dialog", {
            open: true,
            title: t("common.areUsure"),
            text: t("users.deleteMessage5", {user: moreSelected?.firstName}),
            txtLeft: t("common.cancel"),
            txtRight: t("common.yes"),
            fnLeft: () => Model.setData("dialog", { open: false }),
            fnRight: () => onDeleteUser(moreSelected),
            onClose: () => Model.setData("dialog", { open: false }),
          });
        }}
      >
        Borrar Usuario
      </Box>
      <Box
        className={styles.btMoreAction}
        onClick={() => onStatusChange(moreSelected, moreSelected.isBlocked ? 'unblock' : 'block')}
      >
        {moreSelected.isBlocked ? 'Activar' : 'Inactivar'}
      </Box>
    </Popover>
  }

  const isCheckedBranchOffice = (item, index, values) => {
    const route = values
      ?.find(field => field.index === index && field.type === 'selectMultiple');
    return route ? route?.value?.includes(item.value) : false;
  }

  const RenderValueRoutes = (selected) => {
    return (
    <div className={styles.chips}>
      {selected?.map((value) => (
        <Chip key={value} label={value} className={styles.chip} />
      ))}
    </div>
  )}

  const onChangeBranchOffices = (e, type, index, branchOfficeFields) => {
    let routesIndex = branchOfficeFields
      ?.findIndex(field => field.index === index && field.type === type);

    if(routesIndex > -1) {
      const newBranchOfficeFields = [...branchOfficeFields]
      newBranchOfficeFields[routesIndex].value = e.target.value;
      if(type === 'select') {
        routesIndex = branchOfficeFields
          ?.findIndex(field => field.index === index && field.type === 'selectMultiple');
          if(routesIndex > -1) {
            newBranchOfficeFields[routesIndex].value = [];
            newBranchOfficeFields[routesIndex].values = getBranchOfficesRoutes(e.target.value);
            newBranchOfficeFields[routesIndex].branchOfficeName = e.target.value;
          }
      }
      changeVc({branchOfficeFields: newBranchOfficeFields})
    }
  }

  const getBranchOfficeFields = (offices = []) => {
    const fields = [];
    offices.forEach(bo => {
      const uid = _.uniqueId();
      fields.push({
        width: '40%',
        index: uid,
        useValue: true,
        type: 'select',
        label: 'Sucursal',
        value: bo.selectedBranchOffice,
        values: vc.filteredBranchOffices,
        onChange: onChangeBranchOffices,
        stateSource: 'branchOfficeFields',
      }, {
        width: '40%',
        index: uid,
        checks: true,
        label: 'Rutas',
        useValue: true,
        multiple: true,
        name: `routes-${uid}`,
        type: 'selectMultiple',
        renderValue: RenderValueRoutes,
        value: bo.selectedBranchOfficesRoutes,
        values: getBranchOfficesRoutes(bo.selectedBranchOffice),
        onChange: onChangeBranchOffices,
        stateSource: 'branchOfficeFields',
        isChecked: isCheckedBranchOffice,
        branchOfficeName: bo.selectedBranchOffice
      }, {
        label: '',
        index: uid,
        width: '5%',
        icon: <Delete />,
        type: 'iconButton',
        removeOnSubmit: true
      })
    })
    return fields;
  }

  const shouldAddBranchOffice = (fields = vc.branchOfficeFields) => {
    const assgined = fields?.filter(f => f.type === 'select').map(f => f.value)
    const allAreAssigned = vc.filteredBranchOffices?.every(f => assgined?.includes(f.value));
    const thereAreEmptyFields = assgined?.some(f => !f)
    return !allAreAssigned & !thereAreEmptyFields ;
  }

  const addBranchoffice = (fields = vc.branchOfficeFields) => {
    if(!shouldAddBranchOffice()) return;
    const uid = _.uniqueId();
    const newBranchOfficeFields = [ ...fields ];
    newBranchOfficeFields.push({
      width: '40%',
      index: uid,
      useValue: true,
      type: 'select',
      label: 'Sucursal',
      value: '',
      values: vc.filteredBranchOffices,
      onChange: onChangeBranchOffices,
      stateSource: 'branchOfficeFields',
    }, {
      width: '40%',
      index: uid,
      checks: true,
      label: 'Rutas',
      useValue: true,
      multiple: true,
      name: `routes-${uid}`,
      type: 'selectMultiple',
      renderValue: RenderValueRoutes,
      value: [],
      values: [],
      onChange: onChangeBranchOffices,
      stateSource: 'branchOfficeFields',
      isChecked: isCheckedBranchOffice,
    }, {
      label: '',
      index: uid,
      width: '5%',
      icon: <Delete />,
      type: 'iconButton',
      removeOnSubmit: true
    })
    changeVc({branchOfficeFields: newBranchOfficeFields})
  }

  const removeBranchoffice = (index) => {
    const newBranchOfficeFields = [ ...vc.branchOfficeFields ]
      ?.filter(bo => bo.index !== index);

    if(!newBranchOfficeFields?.length) {
      addBranchoffice([])
    } else {
      changeVc({branchOfficeFields: newBranchOfficeFields})
    }
  }
  
  React.useEffect(() => {
    if (state.hasAdminPolicy) {
      firstLoad()
    } else {
      history.replace('/orders')
    }
    return () => {}
    //eslint-disable-next-line
  }, []);

  return <Layout menuSelected={4}>
    <Box className={styles.contBg}>
      <Box className={styles.container}>
        <Box className={styles.rowTitle}>
          <p className={styles.title}>
            {t('common.partnerMembers')}
          </p>
          <Box className={styles.colInter} />
        </Box>
        <Paper
          elevation={3}
          className={styles.contInfo}
        >
          <Box className={styles.rowFilters}>
            <Select
              name='filterBy'
              value={vc.filterBy}
              onChange={handleChange}
              displayEmpty
              className={[styles.selectInput, styles.selectInput2, vc.filterBy ? '' : styles.grayTxt].join(' ')}
              inputProps={{ 'aria-label': 'Without label' }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: -10,
                  horizontal: "left"
                },
                getContentAnchorEl: null,
              }}
            >
              {renderSelectOptions()}
            </Select>
            <Input
              placeholder={'Escribe aquí ...'}
              name='searchText'
              value={vc.searchText}
              onChange={handleChange}
              className={[styles.textInput, styles.textInput2].join(' ')}
              endAdornment={
                <InputAdornment position="end">
                  <Search className={styles.iconSearch} />
                </InputAdornment>
              }
            />
            {vc.showClean ? <Button
              text='Limpiar'
              onClick={onClean}
              variant='light'
              customStyles={styles.btClean}
            /> : null}
            <Button
              text='Buscar'
              onClick={onSearch}
            />
          </Box>
          <Box className={styles.contList}>
            <Box className={styles.headerList}>
              {renderHeaders()}
            </Box>
            <Box className={styles.listItems}>
              {renderItemsList()}
            </Box>
            {returnPopover()}
          </Box>
          <Box className={styles.rowPagination}>
            <TablePagination
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={vc.totalItems}
              rowsPerPage={vc.rowsPerPage}
              page={vc.pageSelected}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage='Miembros por página'
            />
          </Box>
        </Paper>
      </Box>
    </Box>
    <DialogForm
      maxWidth='md'
      show={vc.showUpdateModal}
      title={`Invitar Usuario`}
      text='Por favor ingresar todos los datos que se solicitan'
      leftBtText='Cancelar'
      rightBtText='Guardar'
      funcLeft={() => changeVc({
        firstName: '',
        lastName: '',
        email: '',
        confirmationMail: false,
        newRegistrationMail: false,
        showUpdateModal: false
      })}
      funcRight={updateMember}
      inputs={[
        {label: 'Nombre', value: 'firstName', width: '25%'},
        {label: 'Apellido', value: 'lastName', width: '25%'},
        {label: 'Email', value: 'email', width: '30%'},
        {label: 'Recibe confirmación de ordenes pagas', type: 'switch', value: 'confirmationMail'},
        {label: 'Recibe nuevos registros', type: 'switch', value: 'newRegistrationMail'},
      ]}
      vc={vc}
      changeVc={changeVc}
    />
    <DialogForm
      maxWidth='md'
      show={vc.showBranchOfficesModal}
      title={`Sucursales y rutas`}
      text='Por favor ingresar todos los datos que se solicitan'
      leftBtText='Cancelar'
      rightBtText='Guardar'
      funcLeft={() => changeVc({
        showBranchOfficesModal: false,
        branchOfficeFields: defaultBranchOfficeFields
      })}
      funcRight={updateBranchOffices}
      inputs={vc.branchOfficeFields}
      vc={vc}
      changeVc={changeVc}
      defaultButtonOnClick={removeBranchoffice}
      extraContent={
        <IconButton onClick={() => addBranchoffice()}>
          <Add />
        </IconButton>
      }
    />
  </Layout>
}

export default PartnerMembers
