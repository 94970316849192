import React, { useState } from "react";

import { Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../../../components/loading-button";
import userServices from "../../../../../services/users";
import { useHistory } from "react-router-dom";
import { Store } from "../../../../../hooks/main_store";

const UpgradeAcceptedNoSignatureAction = (props) => {
  const { user } = props;
  const [loading, setLoading] = useState({ accepted: false, rejected: false })
  const { state } = React.useContext(Store);
  const history = useHistory();
  const { t } = useTranslation()

  const sendCommitment = async (status) => {
    try {
      setLoading({ [status]: true });
      const historyLevel = user.levelHistory.find(history => { return history.status === 'pending_validation' })
      console.log('historyLevelFound:', historyLevel);
      const body = {
        level: historyLevel.level,
        commitmentValidation: status
      };

      console.log(user, body);

      await userServices.changeUser(user.id, body)
      setLoading({ [status]: false });
      history.go(0);
    } catch (error) {
      setLoading({ [status]: false });
      console.error('sendCommitment error:', error);
    }
  }


  return (
    <Paper elevation={3} className={"flex flex-col justify-between gap-4 p-8 "}>
      {!state.hasTestPolicy &&
        <>
          <div className={"text-justify"}>
            El pagaré para el nuevo nivel ha sido firmado por el usuario
          </div>
          <div className={"flex flex-row-reverse gap-2"}>

            <LoadingButton
              type={"button"}
              label={t("users.approve")}
              color={"primary"}
              children={"Aprobar"}
              isLoading={loading['accepted']}
              variant={"contained"}
              loadingLabel={"procesando"}
              onClick={() => { sendCommitment('accepted') }}
            />
            <LoadingButton
              type={"button"}
              label={t("users.reject")}
              color={"primary"}
              children={"Rechazar"}
              isLoading={loading['rejected']}
              variant={"contained"}
              loadingLabel={"procesando"}
              onClick={() => { sendCommitment('rejected') }}
            />
          </div>
        </>
      }

    </Paper>
  );
};

export default UpgradeAcceptedNoSignatureAction;
