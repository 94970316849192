import api from './api';
import apiExport from './apiExport';

const getPartners = (filters = '') => {
  return api.get(`/beneficiaries${filters}`)
}

const exportTable = (additional) => {
  return apiExport.get(`/beneficiaries/export${additional}`)
}

const createPartner = (body) => {
  return api.post('/beneficiaries', body)
}

const editPartner = (body) => {
  return api.put(`/beneficiaries/${body.id}`, {
    name: body.name,
    code: body.code,
    contactInfo: body.contactInfo,
    requireCode: body.requireCode,
    afterConfirmationFlow: body.afterConfirmationFlow,
    orderLabel: body.orderLabel,
    uploadType: body.uploadType
  })
}

const changeStatus = (id, status) => {
  return api.put(`/beneficiaries/${id}`, { status })
}

const invite = (id, payload) => {
  return api.post(`/beneficiaries/${id}/invite`, payload)
}

const acceptInvite = (code, password) => {
  return api.post(`/beneficiaries/accept`, { code, password })
}

const inviteMultiple = (id, payload) => {
  return api.post(`/beneficiaries/${id}/multiple/invite`, payload)
}

const getMembers = (id, filters = '') => {
  return api.get(`/beneficiaries/${id}/members${filters}`)
}

const getUserPartners = (filters = '') => {
  return api.get(`/beneficiaries/clientBeneficiaries${filters}`)
}

const createUserPartner = (payload = {}) => {
  return api.post(`/beneficiaries/clientBeneficiaries`, payload)
}

const updateUserPartner = (payload = {}) => {
  return api.put(`/beneficiaries/clientBeneficiaries`, payload)
}

const deleteUserPartner = (id) => {
  return api.delete(`/beneficiaries/clientBeneficiaries/${id}`)
}

const uploadImage = (id, filters = '', body) => {
  return api.post(`/beneficiaries/${id}/image${filters}`, body)
};

const updateMember = (id, memberId, payload) => {
  return api.put(`/beneficiaries/${id}/members/${memberId}`, payload)
}

const getBranchOffices = () => {
  return api.get(`/settings/branch-offices`)
}

export default {
  getPartners,
  exportTable,
  createPartner,
  editPartner,
  changeStatus,
  acceptInvite,
  invite,
  inviteMultiple,
  getMembers,
  uploadImage,
  getUserPartners,
  createUserPartner,
  updateUserPartner,
  deleteUserPartner,
  updateMember,
  getBranchOffices
}