import React from "react";
import { Fragment } from "react";
import { Skeleton } from "@material-ui/lab";
import NotificationList from "./notification-list";
import NotificationPagination from "./notification.pagination";

const NotificationModalBody = (props) => {
  const {
    notifications,
    hasError,
    isLoading,
    pagination,
    onChangePagination,
  } = props;

  if (isLoading) return <Skeleton variant="rect" width={"100%"} height={400} />;
  if (hasError) return null;

  return (
    <Fragment>
      <NotificationList notifications={notifications} />
      <NotificationPagination
        pagination={pagination}
        onChangePagination={onChangePagination}
      />
    </Fragment>
  );
};

export default NotificationModalBody;
