import React from "react";
import { Paper } from "@material-ui/core";

import LevelTable from "./components/level-table/level-table";
import UploadLevelModal from "./components/upload-level-modal";
import { Box } from "@material-ui/core";

const LevelSection = (props) => {
  const { user, reFetch } = props;
  const hide = ['pending_approval','onboarding_validated','cancelled'].includes(user?.status);
  return (
    <Box display={hide? 'none' : 'block'} className={"col-span-3"}>
    <Paper
      elevation={3}
      className={"col-span-3 grid grid-cols-3 gap-4 justify-between"}
    >
      <LevelTable user={user} reFetch={reFetch} />
      <div className={"flex flex-col justify-center py-8 px-16"}>
        {user?.status !== 'validated' ? null :  <UploadLevelModal cancelLast={true} user={user} reFetch={reFetch} adminRequested={true} validatePhysicalSignature />}
      </div>
    </Paper>
    </Box>
  );
};

export default LevelSection;
