import React, { useState } from "react";
import { Paper } from "@material-ui/core";


import UploadLevelModal from "../../level-section/components/upload-level-modal";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../../../components/loading-button";
import userServices from "../../../../../services/users";
import { Store } from "../../../../../hooks/main_store";

const PendingApprovalAction = (props) => {
  const { user } = props;
  const [, reFetch] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const { state } = React.useContext(Store);
  const refresh = () => {
    history.go(0);
  }

  const committed = async () => {
    try {
      setIsLoading2(true);
      await userServices.changeUser(user.id, { status: 'validated' });
      setIsLoading2(false);
      refresh();
    } catch (error) {
      console.error('committed:', error);
    }
  }

  const reject = async () => {
    try {
      setIsLoading(true);
      await userServices.changeUser(user.id, { status: 'cancelled' });
      setIsLoading(false);
      refresh();
    } catch (error) {
      console.error('reject:', error);
    }
  }

  return (
    <>
      {!state.hasTestPolicy &&
        <div>

          {
            user.status === 'committed' ?
              <Paper elevation={3} className={"flex flex-col justify-between gap-4 p-8 "}>
                <div className={"text-justify"}>
                  {t("users.pendingApprovalCardText")}
                </div>
                <div className={"flex flex-row-reverse gap-2"}>
                  {/* <UploadLevelModal
                  user={user}
                  reFetch={reFetch}
                  titleText={t("users.assignLevel")}
                  cardText={t("users.assignLevelCardText")}
                  buttonText={t("users.approveCredit")}
                  status="approved"
                  afterUpdate={refresh}
                  validatePhysicalSignature
                /> */}
                  <LoadingButton
                    type={"button"}
                    label={t("users.approve")}
                    color={"primary"}
                    children={"Aprobar"}
                    isLoading={isLoading2}
                    variant={"contained"}
                    loadingLabel={"procesando"}
                    onClick={committed}
                  />
                  <LoadingButton
                    type={"button"}
                    label={t("users.reject")}
                    color={"primary"}
                    children={"Rechazar"}
                    isLoading={isLoading}
                    variant={"contained"}
                    loadingLabel={"procesando"}
                    onClick={reject}
                  />
                </div>
              </Paper>
              :
              user.status === 'pending_approval' ?
                <Paper elevation={3} className={"flex flex-col justify-between gap-4 p-8 "}>
                  <div className={"text-justify"}>
                    {t("users.pendingApprovalCardText")}
                  </div>
                  <div className={"flex flex-row-reverse gap-2"}>
                    {user?.contractAnnex?.shareCreditHistory ?
                      <UploadLevelModal
                        user={user}
                        reFetch={reFetch}
                        titleText={t("users.assignLevel")}
                        cardText={t("users.assignLevelCardText")}
                        buttonText={t("users.approveCredit")}
                        status="approved"
                        afterUpdate={refresh}
                        validatePhysicalSignature
                      />
                      : null
                    }
                    <LoadingButton
                      type={"button"}
                      label={t("users.reject")}
                      color={"primary"}
                      children={"Rechazar"}
                      isLoading={isLoading}
                      variant={"contained"}
                      loadingLabel={"procesando"}
                      onClick={reject}
                    />
                  </div>
                </Paper>
                : null
          }
        </div>
      }
    </>


  );
};

export default PendingApprovalAction;
