import api from "./api";

export const getNotificationByUser = (uid, page, limit) => {
  const params = {
    uid,
    page,
    limit,
    sort: "createdAt",
    direction: "DESC",
  };
  return api.get("/notifications", params);
};
