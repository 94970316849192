import React, { useRef } from "react";
import * as yup from "yup";
import { Form } from "formik";
import { Field } from "formik";
import { Formik } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { DialogActions } from "@material-ui/core";
import Model from "../../../../../hooks/Model";

import LevelService from "../../../../../services/levels";
import userServices from "../../../../../services/users";
import creditServices from "../../../../../services/credits";

import LoadingButton from "../../../../../components/loading-button";
import { useTranslation } from "react-i18next";
import ValidatePhysicalSignatureModal from "../../action-section/components/validate-physicalSignature-modal";

const UploadLevelForm = (props) => {
  const { user, closeModal, reFetch, status, cardText, afterUpdate, adminRequested, validatePhysicalSignature = false } = props;
  const formRef = useRef(null);
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [openValidateModal, setOpenValidateModal] = useState(false);
  const [levels, setLevels] = useState({ data: [], isLoading: false });

  useEffect(() => {
    setLevels((prev) => ({ ...prev, isLoading: true }));
    
    LevelService.getLevels().then((response) => {
      const allLevels = response.data.data || [];
      const levels = allLevels.filter(level => {
        const levelHistory = user.levelHistory.find(levelHistory => {
          return (levelHistory.level === level.level) && levelHistory.status === 'validated';
        })
        return !levelHistory;
      })
      setLevels({ data: levels, isLoading: false });
      
    });
  }, [user.levelHistory]);

  const initialValues = {
    level: "",
  };

  const validationSchema = yup.object({
    level: yup.number().required(),
  });

  const assignLevel = () => {
    const values = formRef?.current?.values
    const newlevel = levels?.data?.find(l => l.level === values.level);
    const levelRequirePS = !!newlevel?.physicalSignature
    if(validatePhysicalSignature && levelRequirePS) {
      setOpenValidateModal(true);
    } else {
      handleSubmit();
    }
  }

  const handleSubmit = async () => {
    const values = formRef?.current?.values
    const resetForm = formRef?.current?.resetForm
    setLoading(true);
    if (status && status !== ''){
      values.status = status
    }
    try {
      let response;
      if(adminRequested === true){
        response = await creditServices.upgrade(user.id, values.level);
      }else{
        if (user.status === 'upgrade_request') {
          console.log('values:', values);
          response = await userServices.changeUserLevel(user.id, 'accept', values.level)
        }else{
          response = await userServices.changeUser(user.id, values);
        }
      }
  
      if(!response.ok) {
        throw new Error(
          response?.data?.message
          || response?.problem
        );
      }
      if (afterUpdate) {
        afterUpdate()
      }

    } catch(e) {
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: "error",
      });
      setLoading(false);
      closeModal();
    } finally {
      resetForm();
      reFetch((prev) => prev + 1);    
      setLoading(false);
      closeModal();
    }
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={assignLevel}
      >
        <Form className={"flex flex-col gap-4"}>
          <div>
            {cardText || 'Por favor seleccione el nivel al que desea que se actualice el usuario' }
          </div>
          {
            levels.isLoading === true ? 
            <p>Cargando Niveles, por favor espere</p> :
            <Field
              name="level"
              type="number"
              select={true}
              component={TextField}
              label={"Nivel"}
            >
              {levels?.data?.map((level) => (
                <MenuItem key={level.id} value={level.level}>
                  {`Nivel ${level.level} - $${level.maxAmount}`}
                </MenuItem>
              ))}
            </Field>
          }

          <DialogActions>
            <Button
              variant={"text"}
              color={"default"}
              children={"Salir"}
              onClick={closeModal}
            />
            {
              levels.isLoading === false ? 
                <LoadingButton
                  type={"submit"}
                  label={t("users.assign")}
                  color={"primary"}
                  children={"Actualizar"}
                  isLoading={isLoading}
                  variant={"contained"}
                  loadingLabel={"procesando"}
                />
              : 
                <LoadingButton
                  type={"submit"}
                  label={t("users.assign")}
                  color={"primary"}
                  children={"Actualizar"}
                  isLoading={true}
                  variant={"contained"}
                  loadingLabel={"Espere"}
                /> 
            }
          </DialogActions>
        </Form>
      </Formik>
      {openValidateModal ? (
        <ValidatePhysicalSignatureModal
          open={openValidateModal}
          onCloseHandle={closeModal}
          onAcceptHandle={handleSubmit}
        />
      ) : ''}
    </>
  );
};

export default UploadLevelForm;
