import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UploadLevelModal from "../../level-section/components/upload-level-modal";
import userServices from "../../../../../services/users";
import LoadingButton from "../../../../../components/loading-button";
import { Store } from "../../../../../hooks/main_store";

const UpgradeRequestAction = (props) => {
  const { user } = props;
  const [, reFetch] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const { state } = React.useContext(Store);

  const refresh = () => {
    history.go(0);
  }

  const reject = async () => {
    try {
      setIsLoading(true);
      await userServices.changeUserLevel(user.id, 'reject');
      setIsLoading(false);
      refresh();
    } catch (error) {
      console.error('reject: ', error)
      setIsLoading(false);
    }
  }

  return (
    <Paper elevation={3} className={"flex flex-col justify-between gap-4 p-8 "}>
      {!state.hasTestPolicy &&
        <>
          <div className={"text-justify"}>
            El cliente ha solicitado una revisión de su nivel actual de crédito.
          </div>
          <div className={"flex flex-row-reverse gap-2"}>
            <UploadLevelModal
              user={user}
              reFetch={reFetch}
              titleText={t("users.approveRaiseLevel")}
              cardText={t("users.approveRaiseLevelCardText")}
              buttonText={t("users.approveRaiseLevel")}
              status="approved"
              afterUpdate={refresh}
              validatePhysicalSignature
            />
            <LoadingButton
              type={"button"}
              label={t("users.reject")}
              color={"primary"}
              children={"Rechazar"}
              isLoading={isLoading}
              variant={"contained"}
              loadingLabel={"procesando"}
              onClick={reject}
            />

          </div>
        </>
      }

    </Paper>
  );
};

export default UpgradeRequestAction;
