import React from "react";
import { Paper } from "@material-ui/core";
import { startCase, toLower } from "lodash";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

const CommerceSection = (props) => {
  const { user } = props;
  const { t } = useTranslation();

  const getGeolocation = (geolocation = {}) => {
    
    let geoLocationLabels = {};

    if (geolocation){
      if (geolocation.department.code) {
        geoLocationLabels = {
          department: geolocation.department.name,
          municipality: geolocation.municipality.name,
          colony: geolocation.colony.name,
        }     
      } else {
        geoLocationLabels = geolocation;
      }
    }
    
    const {
      department = '',
      municipality = '',
      colony = ''
    } = geoLocationLabels;
    
    return [department, municipality, colony]
      .filter(v => v)
      ?.map(v => startCase(toLower(v)))
      ?.join(', ')
  }

  return (
    <Paper elevation={3} className={"flex flex-col gap-2 p-8"}>
      <div className={"font-bold uppercase"}>{user?.tradeName}</div>
      <div>
        <div className={"font-bold"}>{t('profile.labels.address')}:</div>
        <div>{user?.address}</div>
      </div>
      {user?.geolocation && (
        <div>
          <div className={"font-bold"}>{t('profile.labels.location')}:</div>
          <div>{getGeolocation(user?.geolocation)}</div>
        </div>
      )}
      <div className={"flex flex-col gap-1"}>
        <div className={"font-bold"}>{t('profile.labels.phone')}:</div>
        <Typography variant={"inherit"} color={"primary"}>
          <a
            target={"_blank"}
            rel={"noopener noreferrer"}
            className={"hover:underline"}
            href={`tel:${user?.cellPhone}`}
          >
            {user?.cellPhone}
          </a>
        </Typography>
      </div>
      {user?.activityType ? (
        <div>
          <div className={"font-bold"}>{t('profile.labels.activityType')}:</div>
          <div>{ 
            user.activityType.other ? 
            user.activityType.label + "  -  " + user.activityType.other :
            user.activityType.label
          }</div>
        </div>
      ) : ''}
    </Paper>
  );
};

export default CommerceSection;
