import React from "react";

import ProfileSection from "./sections/profile-section";
import CommerceSection from "./sections/commerce-section";
import DocumentSection from "./sections/document-section";
import LevelSection from "./sections/level-section/level-section";
import ActionSection from "./sections/action-section/action-section";
import HeaderSection from "./sections/header-section/header-section";
import PartnersSection from "./sections/partners-section/partners-section";

const ProfileContent = (props) => {
  const { user, reFetch } = props;
  return (
    <div className={"grid grid-cols-3 gap-4"}>
      <HeaderSection user={user} reFetch={reFetch} />
      <ProfileSection user={user} />
      <CommerceSection user={user} />
      <DocumentSection user={user} reFetch={reFetch} />
      <ActionSection user={user} />
      <PartnersSection user={user} />
      <LevelSection user={user} reFetch={reFetch} />
    </div>
  );
};

export default ProfileContent;
