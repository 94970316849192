import React from "react";
import { useState } from "react";
import { Box, Button } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogTitle } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { Store } from '../../../../../hooks/main_store';
import userServices from "../../../../../services/users";
import LoadingButton from "../../../../../components/loading-button";

const DeleteUserModal = (props) => {
  
  const { user, afterUpdate } = props;
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { state } = React.useContext(Store);

  const handleSubmit = async (values) => {
    setLoading(true);

    userServices.deleteUser(user.id)      
    setLoading(false);
    setOpen(false);
    if (afterUpdate) {
      afterUpdate()
    }
  };

  return state.hasAdminPolicy ? (
    <Box style={{alignSelf:'flex-end'}}>
      
      <Button
        variant={"contained"}
        color={"secondary"}
        children={t('profile.labels.deleteUser')}
        onClick={() => setOpen(true)}
        
      />
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle className={"p-8"}>{t('profile.labels.deleteUser')}</DialogTitle>
        <Divider />
        <div className={"p-8"}>
            
          <div>
            { (user.amountOfOrders > 0 ) ? <div>{ t('users.deleteMessage1') } <b>{ user.amountOfOrders }</b> { t('users.deleteMessage2') }<br/><br/></div>: null }
            { t('users.deleteMessage3') } <b>{user.firstName} {user.lastName}</b> { t('users.deleteMessage4') } <b>{user.dui}</b>?
          </div>
          
          <DialogActions>
            <Button
              variant={"text"}
              color={"default"}
              children={"Salir"}
              onClick={() => {
                setOpen(false)
              }}
            />
            <LoadingButton
              type={"submit"}
              label={t("users.delete")}
              color={"primary"}
              isLoading={isLoading}
              variant={"contained"}
              loadingLabel={"procesando"}
              onClick={handleSubmit}
            />
          </DialogActions>
        </div>
      </Dialog>
    </Box>
  ) : '';
};

export default DeleteUserModal;
