import React, { useContext, useState } from "react";
import { format } from "date-fns";
import { parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { Edit } from '@material-ui/icons'
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import Model from "../../../../../../../hooks/Model";
import { saveDocument } from "../../../../../../../services/users";
//import { getImageUrl } from "../../../../../../../utils/common";
import { Store } from "../../../../../../../hooks/main_store";

export const LevelTableRow = (props) => {
  const [loading, setLoading] = useState(false)
  const { type, uid, label, level, date, status, maxAmount, rate, days, payment, reFetch, maxAmountCash, rateCash, daysCash } = props;
  const { state } = useContext(Store);
  //const baseUrl = 'https://storage.cloud.google.com';

  const onChangeFile = async (e) => {
    try {
      setLoading(true)
      Model.setData('loading', true)
      const data = new FormData() 
      data.append('file', e.target.files[0])
      const response = await saveDocument(
        uid,
        'commitment',
        data,
        type === 'current' ? null : level
      )
      setLoading(false)

      if(response.ok) {
        reFetch((prev) => prev + 1);
      } else {
        throw new Error(response.problem)
      }

    } catch (e) {
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: "error",
      });
    } finally {
      setLoading(false)
      Model.setData('loading', false)
    }
  }

  const Status = ({ status }) => {
    const isRejected = status === 'rejected';
    return <Typography color={isRejected ? 'secondary' : 'primary'}>{isRejected ? 'Rechazado' : 'Aceptado'}</Typography>
  }

  return (
    <tr className={"text-center h-7"}>
      {label ? <td className={"text-left font-bold uppercase"}>{label}</td> : <td />}
      <td className={"ordinal"}>{level}</td>
      <td>{format(parseISO(date), "PPP", { locale: es })}</td>
      <td>
        <span className={'flex justify-center items-center'}>
          {payment ? (
            <Button
              href={payment}
              type={"button"}
              variant={"text"}
              color={"primary"}
              target={"_blank"}
              children={`Pagaré Nivel ${level}`}
            />
          ) : '-'}
          <IconButton
            color="default"
            variant="contained"
            component="label"
            disabled={loading}
            style={{ padding: 4 }}
          >
            <Edit />
            {
              !state.hasTestPolicy ? (
                <input
                  type="file"
                  hidden
                  onChange={e => onChangeFile(e)}
                />
              ) : null
            }
          </IconButton>
        </span>
      </td>
      <td>
        <Status status={status} />
      </td>
      <td>${maxAmount}</td>
      <td>{rate ? `${parseFloat(rate).toFixed(2)}%` : null}</td>
      <td>{days}</td>
      <td>${maxAmountCash}</td>
      <td>{`${parseFloat(rateCash).toFixed(2)}%`}</td>
      <td>{daysCash}</td>
    </tr>
  );
};

export default LevelTableRow;
