import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import {Store} from '../hooks/main_store'
import PropTypes from 'prop-types'
import _ from 'lodash'

const DialogComponent = () => {
  const {state} = React.useContext(Store);

  const handleClose = _.get(state, 'dialog.onClose', () => {})

  return <Dialog
    open={_.get(state, 'dialog.open', false)}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {_.get(state, 'dialog.title', '')}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {_.get(state, 'dialog.text', '')}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={_.get(state, 'dialog.fnLeft', () => {})} 
        color="primary"
      >
        {_.get(state, 'dialog.txtLeft', '')}
      </Button>
      <Button
        onClick={_.get(state, 'dialog.fnRight', () => {})}  
        color="primary"
      >
        {_.get(state, 'dialog.txtRight', '')}
      </Button>
    </DialogActions>
  </Dialog>
};

DialogComponent.defaultProps = {
  customStyles: '',
};

DialogComponent.propTypes = {
  customStyles: PropTypes.string,
};

export default DialogComponent;