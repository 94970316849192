import React from "react";
import { Fragment } from "react";

import ApprovedAction from "./components/approved-action";
import ValidatedAction from "./components/validated-action";
import UpgradeRequestAction from "./components/upgrade-request-action";
import PendingApprovalAction from "./components/pending-approval-action";
import PendingValidationAction from "./components/pending-validation-action";
import UpgradeAcceptedNoSignatureAction from "./components/upgrade-accepted-no-signature-action";
import UpgradeAcceptedPendingValidation from "./components/upgrade-accepted-pending-validation-action";
import UpgradeQualifiedAction from "./components/upgrade-qualified-action";

const ActionSection = (props) => {
  let { user } = props;
  const statusMap = {
    pending_approval: <PendingApprovalAction user={user}/>,
    committed: <PendingApprovalAction user={user}/>,
    approved: <ApprovedAction user={user}/>,
    pending_validation: <PendingValidationAction user={user}/>,
    validated: <ValidatedAction user={user}/>,
    upgrade_request: <UpgradeRequestAction user={user}/>,
    upgrade_accepted_no_signature: <UpgradeAcceptedNoSignatureAction user={user}/>,
    upgrade_accepted_pending_validation: <UpgradeAcceptedPendingValidation user={user}/>,
    upgrade_qualified: <UpgradeQualifiedAction user={user}/>
  }
  return (
    <Fragment>
      {statusMap[user?.status] || <ValidatedAction user={user}/> }
    </Fragment>
  );
};

export default ActionSection;
