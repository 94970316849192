import React from "react";
import { Dialog } from "@material-ui/core";
import { createStyles } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogContentText from "@material-ui/core/DialogContentText";

import Button from "../../../components/button";
import NotificationModalBody from "./notification-modal-body";

const useStyles = makeStyles((theme) =>
  createStyles({
    modalWidth: {
      width: "600px",
    },
  })
);

const NotificationModal = (props) => {
  const {
    notifications,
    hasError,
    isLoading,
    isOpen,
    onCloseHandle,
    user,
    pagination,
    onChangePagination,
  } = props;
  const classes = useStyles();
  return (
    <Dialog open={isOpen} onClose={onCloseHandle}>
      <div>
        <DialogTitle
          className={classes.modalWidth}
          children={`Notificaciones - ${user.firstName} ${user.lastName}`}
        />
        <DialogContent className={classes.modalWidth}>
          <DialogContentText>
            <NotificationModalBody
              hasError={hasError}
              isLoading={isLoading}
              notifications={notifications}
              pagination={pagination}
              onChangePagination={onChangePagination}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseHandle} color="primary" text={"Cerrar"} />
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default NotificationModal;
