import React from "react";
import moment from "moment";
import {
  CheckCircle,
  Help,
  AccessTimeOutlined,
  ErrorOutline,
  MoreVert,
  Edit
} from "@material-ui/icons";
import fileStyles from "./styles";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import { Button, DialogTitle, DialogActions, Dialog, Box, Popover, Tooltip, IconButton, Divider } from "@material-ui/core";
import DialogFormComponent from "../../../../../components/dialogForm";
import partnersService from './../../../../../services/partners';
import Model from "../../../../../hooks/Model";
import LoadingButton from "../../../../../components/loading-button";
import { Store } from "../../../../../hooks/main_store";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

export const PartnersTableRow = (props) => {
  const { data, user, reFetch } = props;
  const styles = fileStyles();
  const { t } = useTranslation();
  const { state } = React.useContext(Store);
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [moreOpened, setMoreOpened] = React.useState('')
  const [itemSelected, setItemSelected] = React.useState({})
  const [openEditModal, setOpenEditmodal] = React.useState(false)
  const [openDeleteModal, setOpenDeletemodal] = React.useState(false)

  const [vc, setVc] = React.useState({
    newCode: '',
  })
  const changeVc = (value) => setVc(ov => ({ ...ov, ...value }))

  const iconsTool = {
    inreview: {
      icon: AccessTimeOutlined,
      style: styles.colorOrange,
      text: t("partners.clientPartners.status.inreviewHelp"),
    },
    accepted: {
      icon: CheckCircle,
      style: styles.colorGreen,
      text: t("partners.clientPartners.status.acceptedHelp"),
    },
    rejected: {
      icon: ErrorOutline,
      style: styles.colorRed,
      text: t("partners.clientPartners.status.rejectedHelp"),
    },
    unknown: { icon: Help, style: "", text: t("partners.clientPartners.status.unknownHelp") },
  };

  const getStatusIcon = (status = 'unknown') => {
    const IconComp = iconsTool[status] || iconsTool['unknown']
    return (
      <LightTooltip title={IconComp.text} placement="right">
        <IconComp.icon className={[styles.iconStatus, IconComp.style]} />
      </LightTooltip>
    )
  }

  const editPartnerCode = (item) => {
    setItemSelected(item)
    setOpenEditmodal(true)
  }

  const onEdit = async () => {
    try {
      setOpenEditmodal(false)
      Model.setData("loading", true);
      const { newCode } = vc;
      const payload = {
        userId: user?.id,
        clientCode: newCode,
        beneficiaryId: itemSelected?.beneficiaryId
      }

      if (Object.values(payload).some(v => !v)) {
        throw new Error(t('common.notEmptyFields'))
      }

      const response = await partnersService.updateUserPartner(payload)
      if (response.ok) {
        await reFetch()
      } else {
        throw new Error(
          t(`partners.clientPartners.errors.${response?.data?.message}`, { defaultValue: t('common.partnerCodeError') })
          || response?.data?.message
          || response?.problem
        );
      }
      Model.setData("loading", false);
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      changeVc({ newCode: '' })
      setItemSelected(null)
    }
  }

  const onChangeStatus = async (isApproved = false) => {
    try {
      handleCloseMore();
      Model.setData("loading", true);
      const payload = {
        userId: user?.id,
        beneficiaryId: itemSelected?.beneficiaryId,
        status: isApproved ? 'accepted' : 'rejected'
      }

      const response = await partnersService.updateUserPartner(payload)
      if (response.ok) {
        await reFetch()
      } else {
        throw new Error(response.problem);
      }
      Model.setData("loading", false);
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      setItemSelected(null)
    }
  }

  const deletePartnerCode = () => {
    setAnchorEl(null)
    setMoreOpened('')
    setOpenDeletemodal(true)
  }

  const onDelete = async () => {
    try {
      handleCloseMore();
      setOpenDeletemodal(false)
      Model.setData("loading", true);

      const response = await partnersService.deleteUserPartner(itemSelected?.clientBeneficiaryId)
      if (response.ok) {
        await reFetch()
      } else {
        throw new Error(response.problem);
      }
      Model.setData("loading", false);
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      setItemSelected(null)
    }
  }

  const returnPopover = () => {
    return <Popover
      anchorEl={anchorEl}
      id={moreOpened || undefined}
      open={Boolean(anchorEl)}
      onClose={handleCloseMore}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {itemSelected?.status === 'rejected' || itemSelected?.status === 'inreview' ? (
        <Box
          className={styles.btMoreAction}
          onClick={() => onChangeStatus(true)}
        >
          Aprobar
        </Box>
      ) : ''}
      {itemSelected?.status === 'accepted' || itemSelected?.status === 'inreview' ? (
        <Box
          className={styles.btMoreAction}
          onClick={() => onChangeStatus(false)}
        >
          Rechazar
        </Box>
      ) : ''}
      <Box
        className={styles.btMoreAction}
        onClick={deletePartnerCode}
      >
        Borrar
      </Box>
    </Popover>
  }

  const handleClickMore = (e, id, item) => {
    setItemSelected(item)
    setAnchorEl(e.currentTarget)
    setTimeout(() => {
      setMoreOpened(id !== moreOpened ? id : '')
    }, 200);
  }

  const handleCloseMore = () => {
    setAnchorEl(null)
    setMoreOpened('')
    setTimeout(() => {
      setItemSelected(null)
    }, 200);
  }

  return (
    <>
      {data?.map((partner, index) => (
        <tr key={index} className={"text-center h-7"}>
          <td>{getStatusIcon(partner?.status)}</td>
          <td>{partner.name}</td>
          <td>
            {partner.code}
            <IconButton
              color="default"
              variant="contained"
              component="label"
              disabled={false}
              style={{ padding: 4 }}
              onClick={() => editPartnerCode(partner)}
            >
              {!state.hasTestPolicy && <Edit />}

            </IconButton>
          </td>
          <td>{moment(partner.updatedAt).format('YYYY-MM-DD')}</td>
          <td>
            {!state.hasTestPolicy && <MoreVert onClick={(e) => handleClickMore(e, `simple-popover-${index}`, partner)} />}
          </td>
        </tr>
      ))}
      {!state.hasTestPolicy && returnPopover()}
      <DialogFormComponent
        show={openEditModal}
        title={`Editar código de Proveedor ${itemSelected?.name}`}
        text='Por favor ingresar todos los datos que se solicitan'
        leftBtText='Cancelar'
        rightBtText='Guardar'
        funcLeft={() => {
          changeVc({ newCode: '' })
          setOpenEditmodal(false)
        }}
        funcRight={onEdit}
        inputs={[
          { label: 'Código', value: 'newCode' },
        ]}
        vc={{ newCode: vc.newCode || itemSelected?.code }}
        changeVc={changeVc}
      />
      <Dialog open={openDeleteModal} onClose={() => setOpenDeletemodal(false)}>
        <DialogTitle className={"p-8"}>{t('partners.clientPartners.delete')}</DialogTitle>
        <Divider />
        <div className={"p-8"}>

          <div>
            {t('partners.clientPartners.deleteMessage')}
          </div>

          <DialogActions>
            <Button
              variant={"text"}
              color={"default"}
              children={"Salir"}
              onClick={() => {
                setOpenDeletemodal(false)
              }}
            />
            <LoadingButton
              type={"submit"}
              label={t("users.delete")}
              color={"primary"}
              isLoading={false}
              variant={"contained"}
              loadingLabel={"procesando"}
              onClick={onDelete}
            />
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default PartnersTableRow;
