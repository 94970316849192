import api from './api';
import apiExport from './apiExport';

const getOrders = (fitlers = '') => {
  return api.get(`/orders${fitlers}`)
}

const getStatistics = () => {
  return api.get('/orders/statistics')
}

const exportTable = (additional) => {
  return apiExport.get(`/orders/export${additional}`)
}

const processOrder = (id, status) => {
  return api.put(`/orders/${id}`, { status })
}

const updateOrder = (id, payload) => {
  return api.put(`/orders/${id}`, payload)
}

export default {
  getOrders,
  getStatistics,
  exportTable,
  processOrder,
  updateOrder,
}