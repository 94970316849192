import api from "./api";
import apiExport from "./apiExport";

export const getUser = (id) => {
  return api.get(`/users/${id}/profile`);
};

const getUsers = (fitlers = "") => {
  return api.get(`/users${fitlers}`);
};

const changeStatus = (id = "", action = "") => {
  return api.put(`/users/${id}/${action}`);
};

const getStatistics = () => {
  return api.get("/users/statistics");
};

const exportTable = (additional) => {
  return apiExport.get(`/users/export${additional}`);
};

const userMe = () => {
  return api.get("/users/me");
};

const createOne = (body) => {
  return api.post("/users", body);
};

export const changeUser = (id, body) => {
  return api.put(`/users/${id}`, body);
};

const createCredit = (body) => {
  return api.post("/credits", body);
};

const pushNotification = (body) => {
  return api.post("/users/notification/push", body);
};

const changeUserLevel = (id, action, level = null) => {
  
  let body = {
    id
  };
  console.log('typeof level:', typeof level)
  if (level !== null){
    body.level = level;
  }
  return api.post(`/users/level/${action}`, body);
}

const deleteUser = (id) => {
  return api.delete(`/users/${id}`);
};

const resendInvite = (body) => {
  return api.put('/users/resend-invite', body);
}

const forgotPassword = (body) => {
  return api.put('/users/forgot-password', body);
}

const activateCredit = (body) => {
  return api.post("/users/activateCredit", body);
};

export const saveDocument = (uid, type, body, level = null) => {
  let url = `/users/document/${type}?uid=${uid}`
  if(level !== null && !Number.isNaN(level)) {
    url += `&level=${level}`
  }
  return api.post(url, body);
};

export const uploadImage = (uid, type, body) => {
  return api.post(`/users/image/${type}?uid=${uid}`, body);
};

export const rejectLastlevel = (id) => {
  return api.post(`/users/${id}/level/rejectLast`);
};


export default {
  getUsers,
  changeStatus,
  getStatistics,
  exportTable,
  userMe,
  createOne,
  changeUser,
  createCredit,
  pushNotification,
  changeUserLevel,
  deleteUser,
  activateCredit,
  resendInvite,
  rejectLastlevel,
  forgotPassword
};
