import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Button } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import Model from '../../../../../hooks/Model'
import { useTranslation } from "react-i18next";
import { DialogTitle } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import UsersService from '../../../../../services/users';
import _ from 'lodash';
import { Store } from "../../../../../hooks/main_store";

import UploadLevelForm from "./upload-level-form";

const UploadLevelModal = (props) => {
  const { state } = React.useContext(Store);
  const { user, reFetch, buttonText, titleText, cardText, status, afterUpdate, adminRequested = false, cancelLast, validatePhysicalSignature = false } = props;
  const { t } = useTranslation();
  const [isOpenUpgrade, setOpenUpgrade] = useState(false);
  const [isOpenReject, setOpenReject] = useState(false);

  const showRejectButton = (user) => {
    // user has more than 1 level validated and not has pending levels 
    const levelsAccepted = user?.levelHistory?.filter(lh => lh?.status === 'validated');
    const levelsPending = user?.levelHistory?.filter(lh => lh?.status?.toLowerCase()?.includes('pending'))?.length;
    return cancelLast && _.uniqBy(levelsAccepted, 'level')?.length > 1 && !levelsPending;
  }

  const getRejectMsg = (user = null) => {
    const levels = _.sortBy(
      _.uniqBy(user?.levelHistory, 'level'), 'level')
      ?.filter(lh => lh?.status === 'validated');
    const currentLevel = levels?.[levels?.length - 1];
    const prevLevel = levels?.[levels?.length - 2];
    const { level, maxAmount } = currentLevel;
    const { level: newLevel, maxAmount: newMaxAmount } = (prevLevel || {});
    return t('profile.rejectLastLevelMsg', {
      level,
      newLevel,
      maxAmount: `$${maxAmount}`,
      newMaxAmount: `$${newMaxAmount}`
    })
  }

  const rejectLastLevel = async () => {
    try {
      Model.setData('loading', true)
      const response = await UsersService.rejectLastlevel(user?.id)
      if (response.ok) {
        reFetch((prev) => prev + 1);
      } else {
        throw new Error(response.problem)
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  return (
    <Fragment>
      {!state.hasTestPolicy &&
        <>
          <Button
            variant={"contained"}
            color={"primary"}
            children={buttonText || 'Subir Nivel'}
            onClick={() => setOpenUpgrade(true)}
          />
          {showRejectButton(user) ? (
            <>
              <Button
                style={{ marginTop: 15 }}
                variant={"contained"}
                color={"primary"}
                children={'Cancelar último nivel activo'}
                onClick={() => setOpenReject(true)}
              />
              <Dialog open={isOpenReject} onClose={() => setOpenReject(false)}>
                <DialogTitle className={"p-8"}>{'Cancelar último nivel activo'}</DialogTitle>
                <Divider />
                <div className={"p-8"}>
                  {getRejectMsg(user)}
                </div>
                <div className={"p-8"}>
                  <DialogActions>
                    <Button variant={"contained"} onClick={() => setOpenReject(false)} color="primary" children={t('common.cancel')} />
                    <Button variant={"contained"} onClick={rejectLastLevel} color="primary" children={t('common.save')} />
                  </DialogActions>
                </div>
              </Dialog>
            </>
          ) : ''}
          <Dialog open={isOpenUpgrade} onClose={() => setOpenUpgrade(false)}>
            <DialogTitle className={"p-8"}>{titleText || 'Subir Nivel'}</DialogTitle>
            <Divider />
            <div className={"p-8"}>
              <UploadLevelForm
                user={user}
                reFetch={reFetch}
                closeModal={() => { setOpenUpgrade(false) }}
                status={status}
                afterUpdate={afterUpdate}
                cardText={cardText}
                adminRequested={adminRequested}
                validatePhysicalSignature={validatePhysicalSignature}
              />
            </div>
          </Dialog>
        </>
      }

    </Fragment>
  );
};

export default UploadLevelModal;
