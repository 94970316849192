import _ from 'lodash';
import firebase from '../hooks/firebase';
import i18n from '../utils/I18n';
import api from "./api";

const currentUid = async () => {
  try {
    return _.get(firebase.auth(), 'currentUser.uid', '');
  } catch(firebaseError){
    throw Error(firebaseError.message, firebaseError)
  }
}

const emailFirebaseLogin = async (email, password) => {
  try {
    const firebaseResult = await firebase.auth().signInWithEmailAndPassword(email, password)
    return {uid: firebaseResult.user.uid}
  } catch(firebaseError){
    let msg = firebaseError.message
    if(firebaseError.code === 'auth/user-not-found') {
      msg = i18n.t('login.errors.emailNotFound', { email })
    } else if(firebaseError.code === 'auth/wrong-password') {
      msg = i18n.t('login.errors.wrongPassword')
    }
    throw Error(msg, firebaseError)
  }
}

const setpasswordValue = (body) => {
  return api.put('/users/reset-password', body);
}

export default {
  emailFirebaseLogin,
  currentUid,
  setpasswordValue
}