import React from "react";
import { useState } from "react";

import UsersService from "../../../../../services/users";
import LoadingButton from "../../../../../components/loading-button";
import { Box } from "@material-ui/core";
import { Store } from "../../../../../hooks/main_store";

const BlockAccessButton = (props) => {
  const { user, reFetch } = props;
  const isBlocked = user?.isBlocked === true;
  const hide = ['pending_approval', 'approved', 'commited', 'pending_validation', 'cancelled'].includes(user?.status)
  const [status, setStatus] = useState({ isLoading: false, data: undefined });
  const { state } = React.useContext(Store);
  const handleOnClickBlockButton = async () => {
    const isBlocked = user?.isBlocked === true;
    const action = isBlocked ? "unblock" : "block";
    setStatus((prevState) => ({ ...prevState, isLoading: true }));
    UsersService.changeStatus(user.id, action).then((response) => {
      setStatus(() => ({ data: response.data, isLoading: false }));
      reFetch((prev) => prev + 1);
    });
  };

  return (
    <Box display={hide ? 'none' : 'block'}>
      {!state.hasTestPolicy &&
        <>
          <LoadingButton
            type={"submit"}
            color={"secondary"}
            variant={"contained"}
            loadingLabel={"procesando"}
            isLoading={status.isLoading}
            onClick={handleOnClickBlockButton}
            label={isBlocked ? "Desbloquear Acesso" : "Bloquear Acceso"}
          />
        </>
      }

    </Box>
  );
};

export default BlockAccessButton;
