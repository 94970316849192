import React from "react";
import { useEffect } from "react";
import { Paper } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import Layout from "../layout";
import Model from "../../hooks/Model";
import NotificationForm from "./notification-form";

const NotificationPage = () => {
  useEffect(() => {
    Model.setData("loading", false);
  }, []);

  return (
    <Layout>
      <div className={"flex flex-row justify-center p-12"}>
        <Paper className={"w-full"} elevation={3}>
          <Toolbar>
            <Typography
              variant={"h6"}
              className={"m-0"}
              children={"Enviar Notificación"}
            />
          </Toolbar>
          <Divider />
          <Paper elevation={0} className={"p-8"}>
            <NotificationForm />
          </Paper>
        </Paper>
      </div>
    </Layout>
  );
};

export default NotificationPage;
