export default {
  profile: {
    gender: {
      male: "Masculino",
      female: "Femenino",
      no: "-"
    },
    status: {
      validated: "Activo",
      invalidated: "Bloqueado - El usuario puede acceder a la aplicación pero no puede hacer compras",
      cancelled: "Cancelado",
      blocked: "Bloqueado por ahorros",
      indebted: "En mora",
      pending_approval: "Aprobación pendiente",
      approved: "Aprobado",
      pending_validation: "LCR y Anexo firmado",
      committed: "Validación pendiente",
      upgrade_request: "Solicitud para subir de nivel",
      upgrade_qualified: "Calificado para subir de nivel",
      upgrade_accepted_no_signature: "Ascenso de nivel aprobado - Pendiente de firma",
      upgrade_accepted_pending_validation: "Ascenso de nivel aprobado - Pendiente de validación",
      onboarding_validated: "Validación de Onfido exitosa",
      inactive: "Inactivo - El usuario no puede acceder a la app",
      onboarding: "Onboarding - Validación pendiente",
    },
    labels: {
      dateOfBirth: "Fecha de Nacimiento",
      years: "Años",
      gender: "Género",
      deleteUser: "Borrar Usuario",
      name: "Nombre",
      period: "Periodo",
      position: "Cargo",
      family: "Familiares",
      relation: "Parentesco",
      politicallyExposedPerson: "Persona Politicamente Expuesta",
      location: "Localización",
      address: "Dirección",
      phone: "Teléfono",
      activityType: "Tipo de actividad",
    },
    rejectLastLevelMsg: "El nivel actual del usuario es el nivel {{level}} con cupo {{maxAmount}} y será cambiado al nivel {{newLevel}} con cupo {{newMaxAmount}}",
    roles: {
      user_admin: "Super Admin",
      user_admin_agent: "Agente",
      user_order_manager: "Gestion de Ordenes",
      user_test: "Centro de Experiencia",
    }
    
  },
  partners: {
    status: 'Estado',
    name: 'Nombre',
    code: 'Código',
    contactInfo: 'Información de Contacto',
    requireCode: 'Requiere Código de Cliente',
    requireBranchOffice: 'Requiere Sucursal',
    label: 'Etiqueta de orden',
    icon: 'Icono de orden',
    logo: 'Logo',
    profileImage: 'Imagen de perfil',
    invoiceSample: 'Ejemplo de factura',
    activeUsers: 'Usuarios Activos',
    createdAt: 'Fecha de creación',
    updatedAt: 'Fecha de actualización',
    unknownHelp: 'Desconocido: El aliado presenta problemas en su perfil, por favor reportarlo al equipo de soporte.',
    activeHelp: 'Activo: Este aliado estará habilitado en la aplicación para realizar pedidos.',
    inactiveHelp: 'Inactivo: Este aliado no estará habilitado en la aplicación, ningún usuario lo puede seleccionar.',
    titleChange: 'Modificar aliado',
    textChange: '¿Estas de acuerdo en ',
    codeExistingError: 'El código de beneficiario ya existe',
    invite: {
      welcome: 'Bienvenido/a!',
      setPassword: 'Falta poco para que puedas acceder al portal y manejar las ordenes.  Por favor configura tu password.',
      accept: 'Aceptar invitación'
    },
    clientPartners: {
      title: 'Proveedores',
      delete: 'Borrar código de proveedor',
      emptyMsg: 'No hay proveedores asociados a este cliente',
      deleteMessage: "Esta seguro que desea borrar este código de proveedor?",
      addPartner: 'Adicionar proveedor',
      status: {
        inreviewHelp: 'Pendiente: El código de cliente esta pendiente de ser aprobado.',
        acceptedHelp: 'Aprobado: El código de proveedor ha sido aprobado.',
        rejectedHelp: 'Rechazado: Se rechazó el código de cliente.',
        unknownHelp: 'Desconocido: El estado es desconocido, por favor reportarlo al equipo de soporte.'
      },
      errors: {
        benefeciary_not_found: 'El proveedor no existe',
        client_benefeciary_code_already_exists: 'El código de proveedor ya fue asociado a otro cliente'
      }
    },
    contactInfoPlaceholder: "Dirección\nTeléfono\nApartado postal\nFax",
    uploadType: "Tipo de Upload",
    afterConfirmationFlow: "Flujo después de Confirmación"
  },
  login: {
    welcome: "Bienvenido/a  de nuevo!",
    enterAccount: "Ingresa a tu cuenta",
    setPassword: "Establece tu contraseña",
    forgotPasswordTitle: "Ingresa tu email",
    lblsetPassword: "Para poder acceder al portal del EMMA, debes establecer tu contraseña.",
    lblforgotPassword: "Ingresa tu email para reestablecer tu contraseña",
    yourPassword: "Tu contraseña",
    save: "Guardar",
    doneSetpass: "Se ha guardado su nueva contraseña",
    doneForgotpass: "Se ha enviado un email para reestablecer tu contraseña",
    equalPass: "Las contraseñas deben coincidir",
    repeatPassword: "Confirmar contraseña",
    errors: {
      emailNotFound: 'El email {{email}} no está registrado en EMMA, por favor verifica tu dirección',
      wrongPassword: 'Tu contraseña está errada.  Por favor ingresala nuevamente'
    }
  },
  layout: {
    users: "Usuarios",
    clients: "Clientes",
    admins: "Administradores",
    distributors: "Distribuidores",
    partners: "Aliados",
    levels: "Niveles",
    settings: "Settings",
    adminProfile: "Admin profile",
    orders: "Ordenes",
    audit: "Auditoria",
    registerAudit: "Auditoria de registro",
    logout: "Cerrar sesión",
  },
  audit: {
    success: 'Registro OK',
    wrong: 'Error en registro',
    notProcessed: 'Sin registro',
  },
  users: {
    firstName: 'Nombres',
    lastName: 'Apellidos',
    dui: 'DUI',
    approvalDate: 'Fecha de registro',
    firstOrderDate: 'Fecha de Activación',
    GAPRegisterApproval: 'GAP registro / activacion',
    level: 'Nivel',
    aov: 'AOV',
    firstTimeLogin: 'Primer inicio de sesión',
    confirmationMail: 'Recibe confirmación de ordenes pagas',
    newRegistrationMail: 'Recibe nuevos registros',
    availableAmount: 'Cupo',
    spentAmount: 'Gastado',
    savedAmount: 'Ahorro',
    paymentDueDate: 'Próxima fecha de pago',
    status: 'Estado',
    tradeName: 'Comercio',
    dateOfBirth: 'Fecha de nacimiento',
    gender: 'Genero',
    cellPhone: 'Número de celular',
    phone: 'Teléfono',
    address: 'Dirección de comercio',
    withdrawalAt: 'Fecha',
    amount: 'Valor',
    email: 'Email',
    user: 'Usuario',
    onboardingHelp: 'Onboarding: Usuario que aún no ha completado el proceso de registro y aceptado los términos y condiciones en la App.',
    validatedHelp: 'Activo: El usuario ya ha terminado satisfactoriamente el registro y firmó los documentos, ya puede hacer uso de la App.',
    invalidatedHelp: 'Inactivo: El usuario fue auto-registrado y puede acceder a la app pero no puede pagar ordenes.',
    cancelledHelp: 'Cancelado: Se rechazó alguno de los documentos firmados',
    unknownHelp: 'Desconocido: El usuario presenta problemas en su perfil, por favor reportarlo al equipo de soporte.',
    adminRegisterHelp: 'Registrado por un administrador directamente desde el Admin Portal sin tener que haber ingresado por la App.',
    blockedHelp: 'Bloqueado por ahorros: El usuario no ha registrado ahorro durante los últimos 2 días.',
    indebtedHelp: 'En mora: El usuario no ha realizado el pago correspondiente en la fecha límite.',
    unauthorizedHelp: 'Bloqueado: Desde el Admin portal se ha bloqueado el usuario y no puede realizar ningún tipo de acción.',
    pendingHelp: 'Pendiente: El usuario ha terminado el flujo de Onfido, pero aún no ha firmado la documentación.',
    approvedHelp: 'Aprobado: Crédito aprobado, pero aún no ha firmado el LCR y la declaración jurada.',
    committedHelp: 'LCR firmado: El usuario ya firmó el LCR, pero aún no ha firmado la declaración jurada.',
    pendingApprovalHelp: 'Aprobación pendiente: Por favor, verifica si la información del usuario y el buro consent cumplen con los requisitos para que el usuario obtenga un crédito',
    pendingValidationHelp: 'Validación pendiente: Por favor, verifica si el LCR y la declaración jurada cumplen con los requisitos para que el usuario pueda ingresar a la App.',
    upgradeRequestHelp: 'Usuario solicitó subir nivel: Este usuario es un usuario válido que desde la aplicación ha solicitado una evaluación para subir de nivel.  Se de analizar y proceder a aprobar o rechazar.',
    upgradeQualifiedHelp: 'Usuario califica para subir de nivel automáticamente:  Este usuario es un usuario válido que de acuerdo a las reglas de upgrade automático ha clasificado y ya ha sido notificado pero aún no aprobado o rechazado el aumento del nivel',
    upgradeAcceptedNoSignatureHelp: 'Usuario con upgrade de nivel aprobado, pero que no ha firmado el LCR: Este usuario obtuvo un upgrade de nivel aprobado pero aún no ha firmado el nuevo LCR para que el nivel sea aprobado',
    upgradeAcceptePendingValidationHelp: 'Usuario con upgrade de nivel que ya firmó LCR: Se requiere que se revisé el nuevo LCR para que el usuario pueda utilizar el nuevo nivel de crédito',
    sureChangeLevel: '¿Desea definitivamente cambiar el nivel del usuario con DUI {{dui}} de {{oldLevel}} a {{newLevel}}?',
    linkDui: 'DUI - Cara frontal',
    linkDuiBack: 'DUI - Cara trasera',
    linkPhoto: 'Foto del usuario',
    buroConsent: 'Buro Consent',
    commitment: 'LCR y Anexo',
    commitmentActive: 'LCR Activo',
    statement: 'Declaración Jurada',
    approveCredit: 'Aprobar crédito',
    validateDocs: 'Validar documentación',
    cancellDocs: 'Rechazar documentación',
    cancellCredit: 'Rechazar solicitud',
    assignLevel: 'Asignar Nivel',
    assign: 'Asignar',
    approve: 'Aprobar',
    reject: 'Rechazar',
    pendingApprovalCardText: 'El cliente ha completado su solicitud de registro en EMMA. Es necesario realizar el estudio de crédito.',
    assignLevelCardText: 'Por favor seleccione el nivel con el que va a quedar el usuario al aprobar el crédito',
    days: 'días',
    sinceCreditApproved: 'desde que se aprobó el crédito',
    clientHasAssignedToLevel: 'El cliente ha sido asignado al Nivel',
    clientHasNotSentDocsToValidate: 'ha sido notificado pero aún no ha ingresado a la aplicación.',
    clientHasLevelWithPhysicalSignature: 'pero este nivel requiere diligenciar un LCR fisico, un asesor debe visitar al cliente para realizar esta acción. Recuerda que luego debe ser cargado el LCR asociado al nivel para poder activar el nuevo nivel',
    approveRaiseLevel: "Aprobar ascenso de nivel",
    approveRaiseLevelCardText: "Por favor seleccione el nivel que desea aprobar al usuario",
    sinceLastLevelApproval: "desde la aprobación del nuevo nivel",
    clientHastBeenNotifiedWaitingCommitment: "El cliente ha sido notificado. Estamos a la espera  del LCR firmado.",
    clientApplyForAutomaticRaise: "Este cliente aplica para un ascenso automático.",
    delete: "Borrar",
    deleteMessage1: "Este usuario ha realizado",
    deleteMessage2: "compras.  Al confirmar esta acción, todas las ordenes asociadas a las compras van a ser eliminadas y toda la información relacionada a este usuario.",
    deleteMessage3: "Esta seguro que desea borrar al usuario",
    deleteMessage4: "identificada con DUI",
    deleteMessage5: "Esta seguro que desea borrar al usuario {{user}}?",
    deleteSuccessful: "El usuario fue eliminado exitosamente",
    validatePhysicalSignatureTitle: "Confirmación de asignación de nivel",
    validatePhysicalSignatureMsgP1: "Este nivel requiere que el documento de LCR sea firmado en fisico, por lo tanto, tenga en cuenta que un asesor debe visitar al cliente para diligenciar y firmar el LCR.",
    validatePhysicalSignatureMsgP2: "Después de la visita, tome una foto o escanee el documento y subalo en este portal.",
    validatePhysicalSignatureMsgP3: "Esta seguro que desea asignar el nivel a este usuario?",
    approvalDateTooltip: "Fecha de Registro:  Fecha en que el usuario comenzó el proceso de onboarding.",
    firstOrderDateTooltip: "Fecha de Activación:  Fecha en que el usuario realizó su primera orden."
  },
  admins: {
    name: "Nombres",
    lastName: "Apellidos",
    rol: "Rol",
    email: "Email"
  },
  orders: {
    orderId: "Número de pedido",
    interest: "Interés",
    rate: "Porcentaje",
    paymentComplete: "Pago completado",
    dueDate: "Fecha vencimiento",
    months: "Meses",
    level: "Nivel",
    status: "Estado",
    beneficiary: "Beneficiario",
    linkBill: "Link de factura",
    sentHelp:
      "Enviada: La órden ha sido generada y enviada al ERP, pero aún no se recibe la confirmación",
    processedHelp: "Confirmada: La órden ha sido confirmada por el ERP",
    unknownHelp:
      "Desconocido: La orden presenta problemas en su información, por favor reportarlo al equipo de soporte.",
    cancelledHelp:
      "Anulada: Orden que ya había sido pagada pero fue cancelada por el ERP.",
    paidHelp: "Pagada: La órden ha sido pagada por el ERP",
    indebtedHelp:
      "En mora: La orden no ha sido pagada antes de la fecha acordada",
    generatedHelp:
      "Generada: La órden ha sido generada pero aún no ha sido enviada al ERP",
    processOrder: "Procesar orden",
    payOrder: "Pagar orden",
    cancelOrder: "Anular orden",
    areUsure: "¿Estas de acuerdo en {{status}} esta orden?",
    pay: "Pagar",
    cancel: "Anular",
    edit: "Editar",
    orderConfirmed: "La orden ha sido confirmada",
    confirmOrder: "Confirma la información de la orden {{order}}",
    confirmOrderDescription:
      "Actualiza la siguiente información para que la orden pueda ser confirmada",
    amount: "Valor",
    productId: "Número de transacción",
    date: "Fecha",
    statusValues: {
      paid: "Generada",
      sent: "Enviada",
      processed: "Confirmada",
      accounted: "Pagada",
      cancelled: "Anulada",
      indebted: "En mora"
    },
    paidValues: {
      paid: "Pagada",
      unpaid: "No Pagada"
    },
    perPage: "Ordenes por página",
    seeCancelled: "Ver ordenes anuladas",
    active: "Ordenes activas",
    indebt: "en mora",
    aov: "AOV",
    bestBeneficiary: "Mejor Aliado",
    noOrders: "No hay ordenes para mostrar",
    beneficiaries: "Aliado",
    extraCredit: 'Credito Extra',
  },
  common: {
    none: 'Ninguno',
    email: 'Correo electrónico',
    password: 'Contraseña',
    confirmPassword: 'Confirmar Contraseña',
    continue: 'Continuar',
    pleaseFill: 'Por favor ingresar todos los datos',
    matchPasswords: 'Las contraseñas no son iguales',
    errorEmail: 'Ingresar un email válido',
    unknownError: 'Error desconocido',
    partnerCodeError: 'El código no puede tener mas de 16 caracteres',
    enter: 'Ingresar',
    users: 'Usuarios',
    orderBy: 'Ordenar por',
    name: 'Nombre',
    openDate: 'Fecha de apertura',
    orders: 'Ordenes',
    code: 'Código de confirmación',
    clickHere: 'Clic aquí',
    areUsure: '¿Está seguro?',
    logoutConfirm: '¿Desea cerrar sesión?',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    yes: 'Si',
    no: 'No',
    partners: 'Aliados',
    partnerMembers: 'Miembros del Aliado',
    save: 'Guardar',
    filterBy: 'Filtrar por',
    export: 'Exportar',
    search: 'Buscar',
    clean: 'Limpiar',
    firstDate: 'Fecha Inicial',
    endDate: 'Fecha Final',
    total: 'Total',
    last: 'Último',
    pluralLast: 'Últimos',
    year: 'año',
    days: 'días',
    previous: 'Previo',
    writeHere: 'Escribe aquí',
    upload: 'Subir',
    seeMore: "Ver Mas",
    file: 'Archivo',
    photo: 'Foto',
    uploadFile: "Subir archivo",
    confirmUploadDoc: "Esta seguro que desea subir este archivo {{docName}} como {{docLabel}}?",
    forgotPassword: "Olvidaste la contraseña?",
    send: "Enviar",
    notEmptyFields: "No dejar campos vacíos",
    seeBranchOffices: "Ver Sucursales y Rutas",
    filterErrors: {
      allFiltersEmpty: "Por favor seleccione un tipo de filtro para hacer una búsqueda",
      typeFilterEmpty: "Por favor seleccione un tipo de filtro para hacer una búsqueda",
      valueFilterEmpty: "Por favor seleccione un valor para hacer la búsqueda"
    }
  },
  ordersList: {
    confirmationCode: 'Confirmación',
    clientCode: 'Código de proveedor',
    orderId: 'Pedido',
    productId: 'Pagaré',
    usedExtraCredit: 'Credito Extra',
    orderBranchOffice: 'Numero de ruta'
  },
  levels: {
    level: 'Nivel',
    minimumAmount: 'Valor mínimo para ordenes'
  }

};
