import api from './api';

const getLevels = () => {
  return api.get('/credits/terms')
}

const changeLevel = (id = '', body) => {
  return api.put(`/credits/terms/${id}`, body)
}

const createLevel = (body) => {
  return api.post('/credits/terms', body)
}

export default {
  getLevels,
  changeLevel,
  createLevel
}