import React from "react";
import fileStyles from "./styles/layoutStyles";
import {
  ListAlt,
  Group,
  ExitToApp,
  ChevronLeft,
  ChevronRight,
  GroupWork,
  EmojiEvents,
  Lock,
  Assignment
} from "@material-ui/icons";
import { Store } from "../../hooks/main_store";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Logo from "../../assets/images/logo_round.png";
import { logoutSession } from '../../utils/common'
import Box from "@material-ui/core/Box";
import Model from "../../hooks/Model";
import _ from "lodash";

const Layout = (props) => {
  const styles = fileStyles();
  const { t } = useTranslation();
  // Export all global variables from Store
  const { state } = React.useContext(Store);
  // const { userInfo } = state;
  // // Define local vars_ctrl
  // const [vc, setVc] = React.useState({
  //   hiddenMenu: false
  // })

  const goTo = (route) => {
    props.history.push(route);
  };

  function renderScreen() {
    return props.children;
  }

  const logout = async () => {
    try {
      Model.setData("dialog", { open: false });
      await logoutSession()
      goTo("/");
    } catch (e) {
      Model.updateAlerts({
        message: _.get(e, "message", t("common.unknownError")),
        variant: "error",
      });
    }
  };

  const onlogout = () => {
    Model.setData("dialog", {
      open: true,
      title: t("common.areUsure"),
      text: t("common.logoutConfirm"),
      txtLeft: t("common.cancel"),
      txtRight: t("common.yes"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      fnRight: logout,
      onClose: () => Model.setData("dialog", { open: false }),
    });
  };
  const toggleMenu = () => {
    Model.setData("hideMenu", !state.hideMenu);
  };

  React.useEffect(() => {
    return () => {};
  });

  return (
    <Box className={styles.container}>
      <Box
        className={[
          styles.contMenu,
          state.hideMenu ? styles.hideMenu : "",
        ].join(" ")}
      >
        <Box className={styles.contLogo} onClick={() => goTo("/dashboard")}>
          <img className={styles.logo} src={Logo} alt="logo" />
        </Box>
        {state.hasAdminPolicy || state.hasAgentPolicy || state.hasTestPolicy ? (
          <Box
            className={[
              styles.btMenu,
              props.menuSelected === 1 ? styles.btSelected : "",
            ].join(" ")}
            onClick={() => goTo("/users")}
          >
            <Group className={styles.iconBtMenu} />
            <p className={styles.lblBtMenu}>{t("layout.clients")}</p>
          </Box>
        ) : null}
        {state.hasAdminPolicy ? (
          <Box
            className={[
              styles.btMenu,
              props.menuSelected === 2 ? styles.btSelected : "",
            ].join(" ")}
            onClick={() => goTo("/admins")}
          >
            <Lock className={styles.iconBtMenu} />
            <p className={styles.lblBtMenu}>{t("layout.admins")}</p>
          </Box>
        ) : null}
        {state.hasAdminPolicy ||
        state.hasOrderManagerPolicy ||
        state.hasTestPolicy ||
        state.hasPartnerPolicy ? (
          <Box
            className={[
              styles.btMenu,
              props.menuSelected === 3 ? styles.btSelected : "",
            ].join(" ")}
            onClick={() => goTo("/orders")}
          >
            <ListAlt className={styles.iconBtMenu} />
            <p className={styles.lblBtMenu}>{t("layout.orders")}</p>
          </Box>
        ) : null}
        {state.hasAdminPolicy ? (
          <Box
            className={[
              styles.btMenu,
              props.menuSelected === 4 ? styles.btSelected : "",
            ].join(" ")}
            onClick={() => goTo("/partners")}
          >
            <GroupWork className={styles.iconBtMenu} />
            <p className={styles.lblBtMenu}>{t("layout.partners")}</p>
          </Box>
        ) : null}
        {state.hasAdminPolicy ? (
          <Box
            className={[
              styles.btMenu,
              props.menuSelected === 5 ? styles.btSelected : "",
            ].join(" ")}
            onClick={() => goTo("/levels")}
          >
            <EmojiEvents className={styles.iconBtMenu} />
            <p className={styles.lblBtMenu}>{t("layout.levels")}</p>
          </Box>
        ) : null}
        {state.hasAdminPolicy ? (
          <Box
            className={[
              styles.btMenu,
              props.menuSelected === 6 ? styles.btSelected : "",
            ].join(" ")}
            onClick={() => goTo("/audit")}
          >
            <Assignment className={styles.iconBtMenu} />
            <p className={styles.lblBtMenu}>{t("layout.audit")}</p>
          </Box>
        ) : null}
        {/*state.hasAdminPolicy ? (
          <Box
            className={[
              styles.btMenu,
              props.menuSelected === 6 ? styles.btSelected : "",
            ].join(" ")}
            onClick={() => goTo("/notifications")}
          >
            <Notifications className={styles.iconBtMenu} />
            <p className={styles.lblBtMenu}>Notificaciones</p>
          </Box>
          ) : null*/}
        <Box className={styles.blankSection} />
        {/* <Box
          className={styles.btMenu}
        >
          <Settings className={styles.iconBtMenu} />
          <p className={styles.lblBtMenu}>
            {t('layout.settings')}
          </p>
        </Box>
        <Box
          className={styles.btMenu}
          onClick={logout}
        >
          <AccountCircle className={styles.iconBtMenu} />
          <p className={styles.lblBtMenu}>
            {t('layout.adminProfile')}
          </p>
        </Box> */}
        <Box className={styles.btMenu} onClick={onlogout}>
          <ExitToApp className={styles.iconBtMenu} />
          <p className={styles.lblBtMenu}>{t("layout.logout")}</p>
        </Box>
        <Box
          className={[
            styles.btMenu,
            styles.toggle,
            state.hideMenu ? styles.centerFlex : "",
          ].join(" ")}
          onClick={toggleMenu}
        >
          {state.hideMenu ? (
            <ChevronRight className={styles.iconBtMenu} />
          ) : (
            <ChevronLeft className={styles.iconBtMenu} />
          )}
        </Box>
      </Box>
      <Box className={styles.contentDash}>{renderScreen()}</Box>
    </Box>
  );
};

export default withRouter(Layout);
