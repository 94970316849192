import React, { useEffect, useState } from 'react'
import {
  Box,
  Paper,
  Select,
  MenuItem,
  FormControl
} from '@material-ui/core'
import _ from 'lodash'
import AuditCalendar from '../../components/auditCalendar'
import { useTranslation } from 'react-i18next'
import auditStyles from './styles/auditStyles'
import Model from '../../hooks/Model'
import Layout from '../layout'
import moment from 'moment';
import Check from '@material-ui/icons/Check';
import { Close, PriorityHigh } from '@material-ui/icons'
import AuditService from '../../services/audit'

const daysFilter = [
  { value: 30, label: 'Últimos 30 Dias' },
  { value: 60, label: 'Últimos 60 Dias' },
  { value: 90, label: 'Últimos 90 Dias' },
]
const AuditPage = ({ history }) => {
  const { t } = useTranslation();
  const styles = auditStyles();
  const [filterDay, setFilterDay] = useState(30);
  const [auditData, setAuditData] = useState([]);
  const [auditSummary, setAuditSummary] = useState({});

  useEffect(() => {
    getAuditData(filterDay);
  }, [filterDay])

  const getAuditData = async (days = 30) => {
    Model.setData('loading', true);
    const processData = await AuditService.getAuditData(days);
    const auditData = processData?.data?.data;
    
    if(auditData) {
      const auditProcessedData = Object.keys(auditData?.days || {}).map(key => {
        if(!auditData?.days?.[key]?.length) {
          return {
            date: moment(key),
            status: 'notProcessed',
            details: {}
          };
        }
        // get sum of ok and errors per day
        const item = auditData?.days?.[key]?.reduce((prev, val) => {
          prev.ok += (val?.ok || 0);
          prev.errors += (val?.errors || 0);
          return prev;
        }, { ok: 0, errors: 0 })
        return {
          date: moment(key),
          status: item?.errors ? 'wrong' : 'success',
          details: item?.errors ? { ok: item?.ok, fail: item?.errors } : {},
          rawData: item?.errors ? auditData?.days?.[key] : []
        }
      })
      setAuditSummary(auditData.summary);
      setAuditData(auditProcessedData);
    }
    Model.setData('loading', false);
  }


  const AuditDetails = ({ data = {} }) => {
    const processData = (data) => {
      const { ok: success, noRecord: notProcessed, fail: wrong } = data;
      return { success, wrong, notProcessed };
    }
    const [auditDetails] = useState(processData(data));
    const getIcon = (status) => {
      switch (status) {
        case 'success':
          return <Check style={{ color: 'white' }} />
        case 'wrong':
          return <PriorityHigh style={{ color: 'white' }} />
        default:
          return <Close style={{ color: 'white' }} />
      }
    }

    return (
      <Box className={styles.auditDetailsContainer}>
        {Object.keys(auditDetails)?.map((detail, iDetail) => (
          <Box key={iDetail} className={styles.auditDetails}>
            <Box className={styles.auditDetailsRow}>
              <Box className={styles.auditDetailsInfo}>
                <span className={'font-bold'}>{t(`audit.${detail}`)}</span>
                <span className={styles.auditCount}>{auditDetails?.[detail] || 0}</span>
              </Box>
              <Box className={[styles.auditIcon, detail].join(' ')}>
                {getIcon(detail)}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  }

  const onChangeDaysFilter = (days) => setFilterDay(days)

  const processMonthsData = (data = []) => {
    const resp = {};
    data.forEach(item => {
      const month = item?.date.format('MM-YYYY')
      if(resp[month]) {
        resp[month].push(item);
      } else {
        resp[month] = [item]
      }
    })
    return Object.keys(resp).map(k => {
      const dates = resp[k];
      const lastDay = _.first(dates).date;
      const month = lastDay.month();
      let auxLastDay = lastDay;
      while(auxLastDay.isoWeekday() !== 1) {
        auxLastDay = auxLastDay.clone().subtract(1, 'days');
        dates.unshift({ date: auxLastDay, status: 'empty', details: {} })
      }
      return { key: k, dates, month }
    });
  }
  
  return <Layout menuSelected={6}>
    <Box className={styles.contBg}>
      <Box className={styles.container}>
        <Box className={styles.rowTitle}>
          <p className={styles.title}>
            {t('layout.registerAudit')}
          </p>
          <FormControl variant="outlined" className={styles.daysFormControl}>
            <Select
              name="daysFilterSelect"
              className={styles.daysFilter}
              onChange={(e)=>onChangeDaysFilter(e.target.value)}
              value={filterDay}
            >
              {daysFilter?.map((day, index) => (
                <MenuItem
                  value={day.value}
                  key={"select-opt-" + index}
                  className={styles.daysFilterOpt}
                >
                  {day.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Paper
          elevation={0}
          className={styles.contInfo}
        >
         <AuditDetails data={auditSummary} />
        </Paper>
        {auditData?.length ? processMonthsData(auditData).map((data, i) => (
          <Paper
            key={`paper-audit-${i}`}
            elevation={3}
            className={styles.contInfo}
          >
            <AuditCalendar data={data?.dates} month={data?.month} />
          </Paper>
        )) : (
          <Paper
            elevation={0}
            className={styles.contInfo}
          >
            No hay data para mostrar
          </Paper>
        )}
      </Box>
    </Box>
  </Layout>
}

export default AuditPage
