import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/privateRoute";

// Import pages
import Users from "./pages/users";
import Admins from "./pages/admins";
import Orders from "./pages/orders";
import Levels from "./pages/levels";
import Main from "./pages/auth/main";
import Login from "./pages/auth/login";
import SetPassword from "./pages/auth/setPassword";
import Partners from "./pages/partners";
import Dashboard from "./pages/dashboard";
import Page404 from "./components/page404";
import PartnerInvite from "./pages/partners/invite";
import PartnerMembers from "./pages/partners/members";
import ProfilePage from "./pages/profile/profile-page";
import NotificationPage from "./pages/notification/notification-page";
import ForgotPassword from "./pages/auth/forgotPassword";
import AuditPage from "./pages/audit";

// Create router

const AppRouter = () => (
  <BrowserRouter>
    <Switch>
      {/* Auth pages */}
      <Route path="/" exact component={Main} />
      <Route path="/login" exact component={Login} />
      <Route path="/setpassword" exact component={SetPassword} />
      <Route path="/forgotPassword" exact component={ForgotPassword} />
      <Route path="/partners/invite" exact component={PartnerInvite} />
      {/* Dashboard pages */}
      <PrivateRoute path="/users" exact component={Users} />
      <PrivateRoute path="/admins" exact component={Admins} />
      <PrivateRoute path="/orders" exact component={Orders} />
      <PrivateRoute path="/levels" exact component={Levels} />
      <PrivateRoute path="/partners" exact component={Partners} />
      <PrivateRoute path="/partners/:id/members" exact component={PartnerMembers} />
      <PrivateRoute path="/dashboard" exact component={Dashboard} />
      <PrivateRoute path="/profile/:userId" exact component={ProfilePage} />
      <PrivateRoute path="/audit" exact component={AuditPage} />
      <PrivateRoute
        path={"/notifications"}
        exact
        component={NotificationPage}
      />
      <Route component={Page404} />
    </Switch>
  </BrowserRouter>
);

export default AppRouter;
