import { makeStyles } from '@material-ui/styles';
import Commons from '../../../utils/commons/styles';
import { colors } from '../../../styleguide';

const styles = makeStyles({
  contBg: {
    ...Commons.coverBackground,
    width: '100%',
    height: '100%',
    backgroundImage: `url("${require('../../../assets/images/background.svg')}")`,
    backgroundPosition: 'top center',
    overflow:' auto'
  },
  container: {
    ...Commons.flexColumn,
    width: '90%',
    margin: '0 auto',
    padding: 0,
    minHeight: '100%',
    justifyContent: 'center',
    maxWidth: 700
  },
  title: {
    fontSize: 36,
    fontWeight: '400',
    textAlign: 'center',
    color: colors.mainxText,
    '& span': {
      color: colors.primary,
      fontWeight: '600',
    }
  },
});

export default styles;