import React from "react";
import _ from "lodash";
import { Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Store } from "../../../../../hooks/main_store";

const UpgradeAcceptedPendingValidationAction = (props) => {
  const { user } = props;
  const { t } = useTranslation()
  const levelsDesc = _.orderBy(user.levelHistory, ["level"], ["desc"]);
  const currentLevel = levelsDesc.shift();
  const levelDate = new Date(currentLevel.createdAt)
  const date = new Date();
  const diffTime = Math.abs(date - levelDate);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const { state } = React.useContext(Store);

  return (
    <Paper elevation={3} className={"flex flex-col justify-between gap-4 p-8"}>
      {!state.hasTestPolicy &&
        <>
          <div className={"text-center"}>
            <div className={"text-5xl font-bold"}>{diffDays} {t('users.days')}</div>
            <div>{t('users.sinceLastLevelApproval')}</div>
          </div>
          <div className={"text-justify"}>
            {t('users.clientHastBeenNotifiedWaitingCommitment')}
          </div>
        </>
      }

    </Paper>
  );
};

export default UpgradeAcceptedPendingValidationAction;
