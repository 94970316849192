const HdValues = (t) => {
  return ([{
    label: t('partners.status'),
    colWidth: 'col1c',
    value: 'status'
  }, {
    label: t('partners.name'),
    colWidth: 'col2',
    value: 'name'
  }, {
    label: t('partners.code'),
    colWidth: 'col1',
    value: 'code'
  }, {
    label: t('partners.requireCode'),
    colWidth: 'col1',
    value: 'requireCode'
  }, {
    label: t('partners.activeUsers'),
    colWidth: 'col1',
    value: 'usersCount'
  }, {
    label: t('partners.createdAt'),
    colWidth: 'col1',
    value: 'createdAt'
  }, {
    label: '',
    colWidth: 'col1',
    value: 'seeMore'
  }, {
    label: '',
    colWidth: 'col0',
    value: 'btActions'
  }]);
}

export default HdValues