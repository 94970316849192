import { makeStyles } from '@material-ui/styles';
import Commons from '../../../utils/commons/styles';
import { colors } from '../../../styleguide'

const styles = makeStyles({
  container: {
    ...Commons.flexRow,
    margin: 0,
    padding: 0,
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: 'white',
    maxWidth: '100vw',
  },
  contMenu: {
    ...Commons.flexColumn,
    height: '100%',
    width: 240,
    transition: 'all 0.2s',
    overflow: 'hidden',
    alignItems: 'flex-start',
    backgroundColor: colors.mainxText
  },
  hideMenu: {
    width: 60
  },
  contLogo: {
    ...Commons.flexCenter,
    cursor: 'pointer',
    padding: '12px 0px',
    width: '100%'
  },
  logo: {
    height: 42,
    margin: '0px 12px'
  },
  btMenu: {
    ...Commons.flexRow,
    width: 240,
    padding: '12px 0px',
    cursor: 'pointer',
    borderLeft: '3px solid transparent',
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  toggle: {
    justifyContent: 'flex-end',
    width: '100%'
  },
  centerFlex: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  btSelected: {
    borderLeftColor: colors.primary,
    backgroundColor: '#000'
  },
  iconBtMenu: {
    fontSize: 30,
    margin: '0px 14px 0px 11px',
    color: colors.white
  },
  lblBtMenu: {
    margin: '0px 6px',
    color: colors.white,
    // fontWeight: '700',
    fontSize: 14
  },
  blankSection: {
    flex: 1
  },
  contentDash: {
    height: '100%',
    backgroundColor: '#F6F8F9',
    overflow: 'auto',
    flex: 1
  }
})

export default styles;