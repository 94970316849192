import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import { Dialog } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";

import CreateNotificationForm from "./create-notification-form";

const CreateNotificationModal = (props) => {
  const { user } = props;
  const [isOpen, setOpen] = useState(false);

  return (
    <Fragment>
      <Button
        variant={"contained"}
        color={"primary"}
        children={"Enviar Notificación"}
        onClick={() => setOpen(true)}
      />
      <Dialog open={isOpen} onClose={() => setOpen(false)}>
        <DialogTitle className={"p-8"}>Enviar Notificacion</DialogTitle>
        <Divider />
        <div className={"p-8"}>
          <CreateNotificationForm
            user={user}
            closeModal={() => setOpen(false)}
          />
        </div>
      </Dialog>
    </Fragment>
  );
};

export default CreateNotificationModal;
