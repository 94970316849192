import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import { Button } from "@material-ui/core";

import NotificationService from "../../../../../users/components/notification-service";

const ListNotificationModal = (props) => {
  const { user } = props;
  const [isOpen, setOpen] = useState(false);

  return (
    <Fragment>
      <Button
        variant={"contained"}
        color={"primary"}
        children={"Listar Notificaciones"}
        onClick={() => setOpen(true)}
      />
      <NotificationService
        user={user}
        isOpen={isOpen}
        onCloseHandle={() => setOpen(false)}
      />
    </Fragment>
  );
};

export default ListNotificationModal;
