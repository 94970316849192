import React from "react";
import { hot } from 'react-hot-loader/root';
import I18n from "./utils/I18n";
import UrlsComponent from "./urls";
import Alert from "./components/alert";
import model_class from "./hooks/Model";
import Dialog from "./components/dialog";
import Loading from "./components/loading";

import { Store, StoreProvider } from "./hooks/main_store";
import MuiThemeProvider from "./styleguide/mui-theme-provider";

// Function to init dispatch model classes
const InitHooksClasses = () => {
  const { dispatch } = React.useContext(Store);
  // Init dispatch for model or models
  React.useEffect(() => {
    model_class.set_dispatch(dispatch);
    return () => {};
  });
  return <React.Fragment />;
};

const App = () => {
  I18n.changeLanguage("es");
  return (
    <>
      <MuiThemeProvider>
        <StoreProvider>
          <InitHooksClasses />
          <UrlsComponent />
          <Loading />
          <Alert />
          <Dialog />
        </StoreProvider>
      </MuiThemeProvider>
    </>
  );
}

export default hot(App);