import React from "react";
import { Paper } from "@material-ui/core";

const ValidatedAction = () => {
  return (
    <Paper elevation={3} className={"flex flex-col justify-between gap-4 p-8"}>
      <div className={"text-justify"}>
        No hay acciones pendientes con este cliente.
      </div>
    </Paper>
  );
};

export default ValidatedAction;
