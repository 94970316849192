import React, { useContext, useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogTitle, Divider, IconButton, Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useTranslation } from 'react-i18next'
import { Edit } from '@material-ui/icons'
import Model from "../../../hooks/Model";
import { saveDocument, uploadImage } from "../../../services/users";
import { getImageUrl } from "../../../utils/common";
import { Store } from "../../../hooks/main_store";
// import { getCurrentLevel } from "./level-section/components/level-table/util/level-table.util";

const DocumentSection = (props) => {
  const { user, reFetch } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [openConfirmDoc, setOpenConfirmDoc] = useState(false)
  const [uploadData, setUploadData] = useState({
    file: null,
    dataDoc: null
  })
  const [commitmentPaths, setCommitmentPaths] = useState([])
  const { state } = useContext(Store);
  // const currentLevel = getCurrentLevel(user?.levelHistory, user?.status);
  //const baseUrl = 'https://storage.cloud.google.com';

  function modifyImageUrl(originalUrl) {
    if (originalUrl.includes("googleapis")) {
      // Reemplazar "googleapis" con "cloud.google"
      return originalUrl.replace("googleapis", "cloud.google");
    } else {
      // Si la URL no contiene "googleapis", devolver la URL original
      return originalUrl;
    }
  }

  const obtenerNumeroDeCadena = (cadena) => {
    let match = cadena?.match(/(\d+)\.pdf/);
    return match ? parseInt(match[1]) : 0;
  }
  
  const getDocumentsData = () => {
    const data = [
      { label: t('users.linkPhoto'), value: user.photoPath, key: 'photo', upload: uploadImage, accept: ".jpg, .png, .jpeg" },
      { label: t('users.linkDui'), value: user.duiPath, key: 'dui', upload: uploadImage, accept: ".jpg, .png, .jpeg" },
      { label: t('users.linkDuiBack'), value: user.duiBackPath, key: 'duiBack', upload: uploadImage, accept: ".jpg, .png, .jpeg" },
      { label: t('users.buroConsent'), value: user.buroConsentPath, key: 'buroConsent', upload: saveDocument, accept: ".jpg, .png, .jpeg, application/pdf" },
      { label: t('users.statement'), value: user.statementPath, key: 'statement', upload: saveDocument, accept: ".jpg, .png, .jpeg, application/pdf" },
      
    ]

    // console.log('user.status',user.status)
    const validStatuses = [
      'pending_approval',
      'approved',
      'committed',
      'pending_validation',
      'upgrade_accepted_pending_validation',
      'upgrade_request',
      'validated'
    ];

    // console.log('currentLevel',currentLevel)



    if (validStatuses.includes(user.status)) {
      const baseDocument = {
        label: t('users.commitment'),
        value: commitmentPaths[commitmentPaths.length - 1],
        key: 'commitment',
        upload: saveDocument,
        accept: ".jpg, .png, .jpeg, application/pdf"
      };

      data.push(baseDocument);

    }
    return data;
  }

  useEffect(()=> {
    let commitmentPaths = user?.levelHistory?.map((level) => level?.commitmentPath).sort((a, b) => {
      return obtenerNumeroDeCadena(a) - obtenerNumeroDeCadena(b);
    });
    setCommitmentPaths(commitmentPaths)
    console.log('commitmentPaths',commitmentPaths)

  }, [user.levelHistory])

   // Agrega un console.log para ver los documentos
   //console.log("Documentos del usuario:", getDocumentsData());
   //console.log("Documentos del usuario:", currentLevel);
   
  const onUploadFile = async () => {
    try {
      setLoading(true)
      setOpenConfirmDoc(false)
      Model.setData('loading', true)

      const { file, dataDoc } = uploadData;
      if(!file || !dataDoc) {
        throw new Error('Error subiendo el documento');
      }
  
      const { key, upload } = dataDoc;
      const data = new FormData() 
      data.append('file', file)
      const response = await upload(user?.uid, key, data)

      if(response.ok) {
        reFetch((prev) => prev + 1);
      } else {
        throw new Error(response.problem)
      }

    } catch (e) {
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: "error",
      });
    } finally {
      setUploadData({
        file: null,
        dataDoc: null,
      })      
      setLoading(false)
      Model.setData('loading', false)
    }
  }

  const UploadDoc = ({ doc }) => {
    return <span className={'flex justify-between items-center'}>
      {doc?.label}
      <IconButton
        color="default"
        variant="contained"
        component="label"
        disabled={loading}
        style={{ padding: 4 }}
      >
        <Edit />
        {
          !state.hasTestPolicy ? (
            <input
              type="file"
              hidden
              accept={doc?.accept}
              onChange={e => {
                setUploadData({
                  file: e.target.files[0],
                  dataDoc: doc
                })
                setOpenConfirmDoc(true)
              }}
            />
          ) : null
        }
      </IconButton>
    </span>
  }

  const onCancelUpload = () => {
    setUploadData({
      file: null,
      dataDoc: null,
    })
    setOpenConfirmDoc(false)
  }

  return (
    <Paper elevation={3} className={"flex flex-col gap-1 p-8"}>
      <div className={"font-bold"}>Documentos</div>
      {getDocumentsData()?.map((doc, key) => (
        <Typography key={key} color={doc?.value ? "primary" : "initial"}>
          {doc?.value ? (
            <a
            children={<UploadDoc doc={doc} />}
            target={"_blank"}
            href={doc?.value ? modifyImageUrl(getImageUrl(doc.value)) : '/#'}
            rel={"noopener noreferrer"}
            className={"hover:underline"}
          />          
            /*<a
              children={ <UploadDoc doc={doc} /> }
              target={"_blank"}
              //href={doc?.value ? baseUrl + getImageUrl(doc?.value).split('https://storage.googleapis.com')[1] : '/#'}
              href={doc?.value ? getImageUrl(doc?.value) : '/#'}
              rel={"noopener noreferrer"}
              className={"hover:underline"}
            />*/
          ) : <UploadDoc doc={doc} />}
        </Typography>
      ))}
      <Dialog open={openConfirmDoc} onClose={() => setOpenConfirmDoc(false)}>
        <DialogTitle className={"p-8"}>{t('common.uploadFile')}</DialogTitle>
        <Divider />
        <div className={"p-8"}>
            
          <div>
            {t('common.confirmUploadDoc', {
              docLabel: uploadData?.dataDoc?.label || '',
              docName: uploadData?.file?.name || '' 
            })}
          </div>
          
          <DialogActions>
            <Button onClick={onCancelUpload} color="primary">
              {t('common.cancel')}
            </Button>
            <Button onClick={() => onUploadFile()} color="primary">
              {t('common.upload')}
            </Button>
          </DialogActions>
        </div>
      </Dialog>

    </Paper>
  );
};

export default DocumentSection;
