import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import userServices from "../../../../../services/users";
import LevelService from "../../../../../services/levels";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../../../components/loading-button";
import { useHistory } from "react-router-dom";
import ValidatePhysicalSignatureModal from "./validate-physicalSignature-modal";
import { Store } from "../../../../../hooks/main_store";

const UpgradeQualifiedAction = (props) => {
  const { user } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [openValidateModal, setOpenValidateModal] = useState(false);
  const [loading, setLoading] = useState({ accepted: false, rejected: false })
  const [levels, setLevels] = useState({ data: [], isLoading: false });
  const { state } = React.useContext(Store);

  const onAccept = () => {
    const nextLevel = Number(user?.level) + 1;
    const nextlevelInfo = levels?.data?.find(l => Number(l?.level) === nextLevel);
    const validatePS = !!nextlevelInfo?.physicalSignature;
    if (validatePS) {
      setOpenValidateModal(true);
    } else {
      approve();
    }
  }

  useEffect(() => {
    LevelService.getLevels().then((response) => {
      const allLevels = response.data.data || [];
      const levels = allLevels.filter(level => {
        const levelHistory = user.levelHistory.find(levelHistory => {
          return (levelHistory.level === level.level) && levelHistory.status === 'validated';
        })
        return !levelHistory;
      })
      setLevels({ data: levels, isLoading: false });
    });
    // eslint-disable-next-line
  }, [])

  const approve = async () => {
    try {
      setLoading({ accepted: true });
      console.log('here on approve')
      await userServices.changeUserLevel(user.id, 'accept');
      setOpenValidateModal(false);
      setLoading({ accepted: false });
      history.go(0);
    } catch (error) {
      setLoading({ accepted: false });
      console.error('approveCredit:', error);
    }

  }

  const reject = async () => {
    try {
      setLoading({ rejected: true });
      await userServices.changeUserLevel(user.id, 'denied');
      setOpenValidateModal(false);
      history.go(0);
    } catch (error) {
      setLoading({ rejected: false });
      console.error('rejectedCredit:', error);
    }

  }



  return (
    <Paper elevation={3} className={"flex flex-col justify-between gap-4 p-8 "}>
      {!state.hasTestPolicy &&
        <>
          <div className={"text-justify"}>
            {t('users.clientApplyForAutomaticRaise')}
          </div>
          <div className={"flex flex-row-reverse gap-2"}>

            <LoadingButton
              type={"button"}
              label={t("users.approve")}
              color={"primary"}
              children={"Aprobar"}
              isLoading={loading['accepted']}
              variant={"contained"}
              loadingLabel={"procesando"}
              onClick={onAccept}
            />

            <LoadingButton
              type={"button"}
              label={t("users.reject")}
              color={"primary"}
              children={"Rechazar"}
              isLoading={loading['rejected']}
              variant={"contained"}
              loadingLabel={"procesando"}
              onClick={reject}
            />

          </div>
          {openValidateModal ? (
            <ValidatePhysicalSignatureModal
              open={openValidateModal}
              onCloseHandle={() => setOpenValidateModal(false)}
              onAcceptHandle={approve}
            />
          ) : ''}
        </>
      }

    </Paper>
  );
};

export default UpgradeQualifiedAction;
