const HdValues = (t) => {
  return ([{
    label: t('admins.name'),
    colWidth: 'col2',
    value: 'firstName'
  }, {
    label: t('admins.lastName'),
    colWidth: 'col2',
    value: 'lastName'
  }, {
    label: t('admins.rol'),
    colWidth: 'col2',
    value: 'role'
  }, {
    label: t('admins.email'),
    colWidth: 'col2',
    value: 'email'
  }, {
    label: '',
    colWidth: 'col0',
    value: 'btActions'
  }]);
}

export default HdValues