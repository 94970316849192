import React from 'react'
import firebase from '../../hooks/firebase'
import Box from '@material-ui/core/Box'

const MainPage = (props) => {
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        props.history.push('/dashboard')
      } else {
        props.history.push('/login')
      }
    })
    return () => {}
    //eslint-disable-next-line
  }, []);

  return <Box />
}

export default MainPage;
