export const withSchema = (yupSchema, globalValue) => {
  return async (value) => {
    const currentValue = globalValue || value;
    try {
      await yupSchema.validate(currentValue);
      return undefined;
    } catch (error) {
      return error.message;
    }
  };
};
