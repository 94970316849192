import React from 'react'
import { Box } from '@material-ui/core'
import { Store } from '../../hooks/main_store'
// import { useTranslation } from 'react-i18next'
import fileStyles from './styles/dashboardStyles'
import Model from '../../hooks/Model'
import Layout from '../layout'
import _ from 'lodash'

const Dashboard = (props) => {
  // Export all global variables from Store 
  const { state } = React.useContext(Store);
  // const { state } = React.useContext(Store);
  // const { t } = useTranslation()
  const styles = fileStyles()
  // const [vc, setVc] = React.useState({
  //   showAll: false,
  //   txtSearch: ''
  // })

  const renderName = () => {
    if (!_.get(state, 'userInfo.firstName', '') && !_.get(state, 'userInfo.lastName', '')) {
      return 'there'
    } else {
      return `${_.get(state, 'userInfo.firstName', '')} ${_.get(state, 'userInfo.lastName', '')}`
    }
  }

  React.useEffect(() => {
    Model.setData('loading', false)
    return () => {}
    //eslint-disable-next-line
  }, []);

  return <Layout>
    <Box className={styles.contBg}>
      <Box className={styles.container}>
      <Box className={styles.title}>
        <span>Emma Presta</span>
        <br/>Hola {renderName()}
      </Box>
    </Box>
  </Box>
  </Layout>
}

export default Dashboard
