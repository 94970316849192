import React from 'react'
import {
  Box,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TablePagination,
  Tooltip,
  Popover,
  Select,
  MenuItem,
  Input,
  InputAdornment,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  Divider,
  DialogActions
} from '@material-ui/core'
import MUButton from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles';
import {
  ArrowDropUp,
  ArrowDropDown,
  CheckCircle,
  Help,
  Cancel,
  MoreVert,
  Search,
  Add,
  Delete,
  Edit
} from '@material-ui/icons'
import PartnerService from '../../services/partners'
import DialogForm from '../../components/dialogForm'
import { useTranslation } from 'react-i18next'
import fileStyles from './styles/partnersStyles'
import Model from '../../hooks/Model'
import {Store} from '../../hooks/main_store'
import Layout from '../layout'
import HdValues from './hdValues'
import Button from '../../components/button'
import _ from 'lodash'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { getFilterError, getImageUrl } from '../../utils/common';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const dateFilters = ['createdAt'];

const Partners = ({ history }) => {
  const {state} = React.useContext(Store)
  const { t } = useTranslation()
  const headers = HdValues(t)
  const styles = fileStyles()
  const iconsTool = {
    active: {icon: CheckCircle, style: styles.colorGreen, text: t('partners.activeHelp')},
    inactive: {icon: Cancel, style: styles.colorRed, text: t('partners.inactiveHelp')},
    unknown: {icon: Help, style: '', text:  t('partners.unknownHelp')},
  }
  const [requireCode, setRequireCode] = React.useState('');
  const [moreOpened, setMoreOpened] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [moreSelected, setMoreSelected] = React.useState({})
  const [openConfirmDoc, setOpenConfirmDoc] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState(null);
  const [uploadData, setUploadData] = React.useState({
    file: null,
    dataDoc: null,
    partnerId: null
  })
  const defaultInviteFields = [
    {label: 'Nombre', type: 'rowTextField', key: 'firstName', value: '', width: '25%'},
    {label: 'Apellido', type: 'rowTextField', key: 'lastName', value: '', width: '25%'},
    {label: 'Email', type: 'rowTextField', key: 'email', value: '', width: '30%'},
    {label: '', type: 'iconButton', width: '5%', icon: <Delete />, removeOnSubmit: true},
  ]
  const [vc, setVc] = React.useState({
    orderBy: 'status',
    orderDirec: 'DESC',
    expandedItem: -1,
    totalItems: 0,
    pageSelected: 0,
    rowsPerPage: 10,
    listItems: [],
    filterBy: '',
    searchText: '',
    showClean: false,
    item2Edit: null,
    showAddModal: false,
    newCode: '',
    newName: '',
    newOrderLabel: {
      label: '',
      icon: '',
      preview: ''
    },
    newRequireCode: true,
    newAfterConfirmationFlow: false,
    newRequireBranchOffice: false,
    newContactInfo: '',
    uploadType: '',
    // invite user state
    showInviteModal: false,
    inviteFields: []
  })
  const changeVc = (value) => setVc(ov => ({...ov, ...value}))

  const changeVcInvite = (value) => {
    if(value.index) {
      const { index, ...values } = value
      const fieldKey = Object.keys(value)?.[0]
      const indexFound = vc.inviteFields.findIndex(v => v.index === index && fieldKey === v?.key )
      if(indexFound >= 0) {
        const inviteFieldsNew = [...vc.inviteFields]
        inviteFieldsNew[indexFound].value = values[fieldKey]
        changeVc({
          inviteFields: inviteFieldsNew
        })
      }
    }
  }

  const returnUrlVars = (
    obj = {},
    additional = vc.showClean && !dateFilters.includes(vc.filterBy) ? `&${vc.filterBy}=${vc.searchText}` : '',
    omitVars = false
  ) => {
    const isDateFilter = dateFilters.includes(vc.filterBy);
    const requireCodeFilter = requireCode !== '' ? `&requireCode=${requireCode}` : ''
    
    const dateFrom = returnDate(selectedDate)
    const dateTo = returnDate(selectedDateTo)
    const datesFilter = vc.filterBy && (dateTo || dateFrom) ? `&dateFilter=${vc.filterBy}` : ''
    const urlDate = dateFrom && dateTo ? `${dateFrom ? `&fromDate=${dateFrom}` : ''}${dateTo ? `&toDate=${dateTo}` : ''}` : ''
    const fullDatesFilter = isDateFilter ? (datesFilter + urlDate) : ''

    return `?page=${obj.pageSelected + 1}&limit=${obj.rowsPerPage}&sort=${obj.orderBy}&direction=${obj.orderDirec}` + (!omitVars ? fullDatesFilter + requireCodeFilter : '') + additional
  }

  const handleClickMore = (e, id, item) => {
    e.preventDefault();
    e.stopPropagation();
    setMoreOpened(id !== moreOpened ? id : '')
    setAnchorEl(e.currentTarget)
    setMoreSelected(item)
  }

  const handleCloseMore = () => {
    setAnchorEl(null)
    setMoreOpened('')
    setMoreSelected({})
  }

  const onStatusChange = async (action) => {
    try {
      handleCloseMore()
      if (action) {
        Model.setData('loading', true)
        const response = await PartnerService.changeStatus(moreSelected.id, action)
        if (response.ok) {
          firstLoad()
          Model.setData('loading', false)
          Model.updateAlerts({
            message: 'Aliado actualizado',
            variant: 'done'
          })
        } else {
          throw new Error(response.problem)
        }
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const handleChange = (event) => {
    if (event.persist) {
      event.persist()
    }
    if (event.target) {
      changeVc({[event.target.name]: event.target.value})
    }
  };

  const onChangeOrder = async (orderObj) => {
    try {
      if (orderObj.orderDirec) {
        Model.setData('loading', true)
        const response = await PartnerService.getPartners(returnUrlVars(orderObj))
        if (response.ok) {
          const data = _.get(response, 'data.data', [])
          const paging = _.get(response, 'data.paging', {})
          changeVc({
            ...orderObj,
            pageSelected: orderObj.pageSelected,
            totalItems: paging.totalItems,
            listItems: data,
          });
        } else {
          throw new Error(response.problem)
        }
        Model.setData('loading', false)
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const renderHeaders = () => {
    return headers.map((item, index) => <Box
      key={'header-table-' + index}
      className={[
        styles.hdListBold,
        styles[item.colWidth],
        vc.orderBy === item.value ? styles.hdSelected : '',
        item.value === 'btActions' ? styles.hdNohover : '',
      ].join(' ')}
      onClick={() => {
        if (item.value === 'btActions') { return }
        let orderObj = {}
        if (vc.orderBy === item.value) {
          orderObj = {
            orderBy: vc.orderBy,
            orderDirec: vc.orderDirec === 'ASC' ? 'DESC' : 'ASC'
          }
        } else {
          orderObj = {
            orderBy: item.value,
            orderDirec: 'DESC'
          }
        }
        onChangeOrder({
          ...orderObj,
          rowsPerPage: vc.rowsPerPage,
          pageSelected: 0
        })
      }}
    >
      {item.label} {vc.orderBy === item.value ? (vc.orderDirec === 'ASC' ? <ArrowDropUp /> : <ArrowDropDown />) : null}
    </Box>)
  }

  const goTo = (route) => {
    history.push(route)
  }

  const renderDataPartner = (dataPartner, indexPartner) => {
    const returnValuePartner = (value, currency) => {
      if (currency) {
        return parseFloat(value).toFixed(2)
      } else {
        return (value || value === 0) ? value : '-'
      }
    }
    const returnItem = (currency, value, type) => {
      if (type === 'status') {
        let IconComp = iconsTool[value] || iconsTool.unknown
        if (dataPartner.isBlocked) {
          IconComp = iconsTool.blocked
        }
        return <LightTooltip
          title={IconComp.text}
          placement='right'
        >
          <IconComp.icon className={[styles.iconStatus, IconComp.style]} />
        </LightTooltip>
      } else if (type === 'btActions') {
        return <MoreVert
          onClick={(e) => handleClickMore(e, `simple-popover-${indexPartner}`, dataPartner)}
        />
      } else if (type === 'createdAt') {
        const date = value.split('T')
        return date[0]
      } else if (type === 'usersCount') {
        return Number(value) ? (
          <Typography className={styles.countUsers} color={"primary"} onClick={() => {goTo(`/partners/${dataPartner?.id}/members`)}}>
            {value}
          </Typography>
        ) : '-'
      } else if (type === 'seeMore') {
        return (
        <Typography className={styles.countUsers} color={"primary"}>
          {t('common.seeMore')}
        </Typography>)
      } else if (type === 'requireCode') {
        return t(`common.${value ? 'yes' : 'no'}`)
      } else {
        return `${currency}${returnValuePartner(value, currency)}`
      }
    }
    return headers.map((item, index) => {
      let currency = ''
      if (item.value === 'aov' || item.value.indexOf('Amount') > -1) {
        currency = '$'
      }
      return <Box
        key={'data-table-' + index}
        className={[
          styles.hdList,
          styles[item.colWidth],
        ].join(' ')}
      >
        {returnItem(currency, dataPartner[item.value], item.value)}
      </Box>
    })
  }

  const getPartnerDocsData = (partner) => {
    const { uploadImage } = PartnerService;
    const data = [
      { label: t('partners.logo'), value: partner.logoPath, key: 'logo', upload: uploadImage, accept: ".jpg, .png, .jpeg" },
      { label: t('partners.profileImage'), value: partner.profileImagePath, key: 'profileImage', upload: uploadImage, accept: ".jpg, .png, .jpeg" },
      { label: t('partners.invoiceSample'), value: partner.invoiceSamplePath, key: 'invoiceSample', upload: uploadImage, accept: ".jpg, .png, .jpeg" },
    ]
    return data;
  }

  const onChangeFile = async () => {
    try {
      Model.setData('loading', true)
      setOpenConfirmDoc(false)
      const { file, dataDoc, partnerId } = uploadData;
      if(!file || !dataDoc) {
        throw new Error('Error subiendo el documento');
      }

      const data = new FormData() 
      data.append('file', file)
      const { key, upload } = dataDoc;
      const response = await upload(partnerId, `?type=${key}`, data)

      if(response.ok) {
        firstLoad()
      } else {
        throw new Error(response.problem)
      }

    } catch (e) {
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: "error",
      });
    } finally {
      setUploadData({
        file: null,
        dataDoc: null,
      })      
      Model.setData('loading', false)
    }
  }

  const UploadDoc = ({ id, doc }) => {
    return <span className={'flex items-center'}>
      {doc?.label}
      <IconButton
        size="small"
        color="default"
        variant="contained"
        component="label"
        disabled={false}
        style={{ padding: 4 }}
      >
        <Edit />
        <input
          type="file"
          hidden
          accept={doc?.accept}
          onChange={e => {
            setUploadData({
              file: e.target.files[0],
              dataDoc: doc,
              partnerId: id
            })
            setOpenConfirmDoc(true)
          }}
        />
      </IconButton>
    </span>
  }

  const handleExpandItem = (index, expanded) => {
    if (!moreOpened) {
      changeVc({ expandedItem: expanded ? index : -1 });
    }
  };

  const renderItemsList = () => {
    if (vc.listItems.length > 0) {
      return vc.listItems.map((item, index) => <Accordion
        className={[styles.rowItem, vc.expandedItem === index ? styles.itemSelected : ''].join(' ')}
        key={'item-list-' + index}
        expanded={vc.expandedItem === index && !moreOpened}
        onChange={(e, expanded) => handleExpandItem(index, expanded)}
      >
        <AccordionSummary
          aria-controls="panelc-content"
          id="panelc-header"
          className={styles.headerList2}
        >
          {renderDataPartner(item, index)}
        </AccordionSummary>
        <AccordionDetails className={styles.detailsItem}>
          <Box className={styles.rowInterItem}>
            {getPartnerDocsData(item)?.map((doc, key) => (
              <Typography className={styles.colInter} key={key} color={doc?.value ? "primary" : "initial"}>
                {doc?.value ? (
                  <a
                    children={ <UploadDoc id={item?.id} doc={doc} /> }
                    target={"_blank"}
                    href={doc?.value ? getImageUrl(doc?.value) : '/#'}
                    rel={"noopener noreferrer"}
                    className={"hover:underline"}
                  />
                ) : <UploadDoc id={item?.id} doc={doc} />}
              </Typography>
            ))}
            <Typography className={styles.colInter} color={"initial"}>
              {item?.contactInfo || '-'}
            </Typography>
          </Box>
          <Box className={styles.rowInterItem}>
            <Typography className={styles.colInter} color={"initial"}>
              <h4 className="font-bold">{t('partners.label')}:</h4>
              {item?.orderLabel?.label || '-'}
            </Typography>
            <Typography className={styles.colInter} color={"initial"}>
              <h4 className="font-bold">{t('partners.icon')}:</h4>
              <div style={{ height: 'fit-content', width: 'fit-content' }} className={"border border-solid"}>
                {item?.orderLabel?.icon ? <img className={"w-8 h-8"} alt={"profile"} src={item?.orderLabel?.icon} /> : ''}
              </div>
            </Typography>
            <Typography className={styles.colInter} color={"initial"}>
              <h4 className="font-bold">{t('partners.requireBranchOffice')}:</h4>
              {t(`common.${item?.orderLabel?.requireBranchOffice ? 'yes' : 'no'}`)}
            </Typography>
            <Typography className={styles.colInter} color={"initial"}>
              <h4 className="font-bold">{t('partners.uploadType')}:</h4>
              {t(`common.${item?.uploadType || 'photo'}`)}
            </Typography>
          </Box>
          <Box className={styles.rowInterItem}>
            <Typography className={styles.colInter} color={"initial"}>
              <h4 className="font-bold">{t('partners.afterConfirmationFlow')}:</h4>
              {t(`common.${item?.afterConfirmationFlow ? 'yes' : 'no'}`)}
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>)
    } else {
      return <p className={styles.emptyText}>
        No hay aliados para mostrar
      </p>
    }
  }

  const handleChangePage = (event, newPage) => {
    onChangeOrder({
      pageSelected: newPage,
      rowsPerPage: vc.rowsPerPage,
      orderBy: vc.orderBy,
      orderDirec: vc.orderDirec
    })
  };

  const handleChangeRowsPerPage = (event) => {
    onChangeOrder({
      pageSelected: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      orderBy: vc.orderBy,
      orderDirec: vc.orderDirec
    })
  };

  const onEdit = (itemEdit) => {
    setAnchorEl(null)
    setMoreOpened('')
    changeVc({
      item2Edit: itemEdit,
      newName: itemEdit.name,
      newCode: itemEdit.code,
      newOrderLabel: {
        label: itemEdit?.orderLabel?.label,
        icon: itemEdit?.orderLabel?.icon,
        preview: itemEdit?.orderLabel?.icon,
      },
      newRequireCode: itemEdit.requireCode,
      newContactInfo: itemEdit.contactInfo,
      newRequireBranchOffice: itemEdit.orderLabel?.requireBranchOffice,
      newAfterConfirmationFlow: !!itemEdit.afterConfirmationFlow,
      uploadType: itemEdit.uploadType,
      showAddModal: true
    })
  }

  const onInvite = (itemInvite) => {
    setAnchorEl(null)
    setMoreOpened('')
    addInviteRow()
    changeVc({
      item2Edit: itemInvite,
      showInviteModal: true
    })
  }

  const returnPopover = () => {
    return <Popover
      anchorEl={anchorEl}
      id={moreOpened || undefined}
      open={Boolean(anchorEl)}
      onClose={handleCloseMore}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box
        className={styles.btMoreAction}
        onClick={() => onEdit(moreSelected)}
      >
        Editar
      </Box>
      <Box
        className={styles.btMoreAction}
        onClick={() => onInvite(moreSelected)}
      >
        Invitar Usuario
      </Box>
      <Box
        className={styles.btMoreAction}
        onClick={() => onStatusChange(moreSelected.status === 'active' ? 'inactive' : 'active')}
      >
        {moreSelected.status === 'active' ? 'Inactivar' : 'Activar'}
      </Box>
    </Popover>
  }

  const returnRangeDate = (opt) => {
    if (opt === 'min') {
      if (selectedDate && selectedDate._isValid) {
        return moment(selectedDate._d).format('YYYY-MM-DD')
      } else {
        return ''
      }
    } else {
      if (selectedDateTo && selectedDateTo._isValid) {
        return moment(selectedDateTo._d).format('YYYY-MM-DD')
      } else {
        return ''
      }
    }
  }
  
  const renderSelectOptions = () => {
    const values = [
      {label: 'Filtrar por', value: ''},
      {label: 'Nombre', value: 'name'},
      {label: 'Código', value: 'code'},
      {label: 'Requiere Código de cliente', value: 'requireCode'},
      {label: 'Usuarios Activos', value: 'usersCount'},
      {label: t('partners.createdAt'), value: 'createdAt'},
    ]
    return values.map((item, index) => <MenuItem 
      value={item.value}
      className={styles.itemSelect}
      key={'select-opt-' + index}
    >
      {item.label}
    </MenuItem>)
  }

  const onClean = () => {
    if (vc.showClean) {
      setSelectedDate(null)
      setRequireCode('')
      changeVc({
        filterBy: '',
        searchText: '',
        showClean: false
      });
      setSelectedDate(null)
      setSelectedDateTo(null)
      firstLoad()
    }
  }


  const returnDate = (date) => {
    if (date && date._isValid) {
      return moment(date._d).format('YYYY-MM-DD')
    } else {
      return ''
    }
  }

  const onSearch = async () => {
    try {
      if ((vc.filterBy && vc.searchText) || (selectedDate && selectedDateTo) || requireCode !== '') {
        Model.setData('loading', true)
        const filter = vc.filterBy && vc.searchText ? `&${vc.filterBy}=${vc.searchText}` : ''
        const response = await PartnerService.getPartners(returnUrlVars(vc, filter)) 
        if (response.ok) {
          const data = _.get(response, 'data.data', [])
          const paging = _.get(response, 'data.paging', {})
          changeVc({
            pageSelected: 0,
            totalItems: paging.totalItems,
            listItems: data,
            showClean: true
          });
        } else {
          throw new Error(response.problem)
        }
        Model.setData('loading', false)
      } else {
        const filterError = vc.filterBy || (selectedDate && selectedDateTo);
        const valueError = vc.searchText || (selectedDate && selectedDateTo) || requireCode !== '';
        const filterErrorMsg = getFilterError({ filter: filterError, value: valueError });
        throw new Error(filterErrorMsg);
      }
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: String(e),
        variant: 'error'
      })
    }
  }

  const onExport = async () => {
    try {
      Model.setData('loading', true)
      const response = await PartnerService.exportTable(vc.filterBy ? `?${vc.filterBy}=${vc.searchText}` : '')
      if (response.ok) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'partners-list.xlsx');
        document.body.appendChild(link);
        link.click();
      } else {
        let resErr = response.problem
        if (response?.data?.text) {
          resErr = JSON.parse(await response.data.text()).message
        }
        throw new Error(resErr)
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const onNewPartner = async () => {
    try {
      const newLabelIcon = vc.newOrderLabel?.icon;
      const data = {
        name: vc.newName,
        code: vc.newCode,
        orderLabel: {
          label: vc.newOrderLabel?.label,
          requireBranchOffice: !!vc.newRequireBranchOffice
        },
        requireCode: vc.newRequireCode,
        afterConfirmationFlow: vc.newAfterConfirmationFlow,
        contactInfo: vc.newContactInfo,
        uploadType: vc.uploadType,
      }
      changeVc({
        showAddModal: false,
        item2Edit: null,
        newName: '',
        newCode: '',
        newOrderLabel: {
          label: '',
          icon: '',
          preview: '',
        },
        newRequireCode: true,
        newAfterConfirmationFlow: false,
        newRequireBranchOffice: false,
        uploadType: '',
        newContactInfo: ''
      })
      if (!data.name || !data.code) {
        throw new Error(t('common.notEmptyFields'));
      }
      Model.setData('loading', true);
      const response = await PartnerService.createPartner(data)
      if (response.ok) {
        if(newLabelIcon) {
          const uploadData = new FormData() ;
          uploadData.append('file', newLabelIcon)
          const respIcon = await PartnerService.uploadImage(response?.data?.data?.id, '?type=logo&orderLabelIcon=true', uploadData);
          if (!respIcon.ok) {
            throw new Error('Error subiendo el icono')
          }
        }
        firstLoad()
      } else {
        throw new Error(response.problem)
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const onEditPartner = async () => {
    try {
      const itemToEdit = vc.item2Edit;
      const newLabelIcon = vc.newOrderLabel?.icon;
      const data = {
        name: vc.newName,
        code: vc.newCode,
        id: vc.item2Edit.id,
        orderLabel: {
          label: vc.newOrderLabel?.label,
          requireBranchOffice: !!vc.newRequireBranchOffice,
        },
        requireCode: vc.newRequireCode,
        afterConfirmationFlow: vc.newAfterConfirmationFlow,
        contactInfo: vc.newContactInfo,
        uploadType: vc.uploadType,
      }
      changeVc({
        showAddModal: false,
        item2Edit: null,
        newName: '',
        newCode: '',
        newOrderLabel: {
          label: '',
          icon: '',
          preview: '',
        },
        newRequireCode: true,
        newAfterConfirmationFlow: false,
        newRequireBranchOffice: false,
        newContactInfo: '',
        uploadType: ''
      })
      if (!data.name || !data.code) {
        throw new Error(t('common.notEmptyFields'))
      }
      Model.setData('loading', true)
      const hasNewIcon = newLabelIcon && newLabelIcon !== itemToEdit?.orderLabel?.icon;
      const response = await PartnerService.editPartner(data)
      if (response.ok) {
        if(hasNewIcon) {
          const uploadData = new FormData() ;
          uploadData.append('file', newLabelIcon)
          const respIcon = await PartnerService.uploadImage(itemToEdit?.id, '?type=logo&orderLabelIcon=true', uploadData);
          if (!respIcon.ok) {
            throw new Error('Error subiendo el icono')
          }
        }
        firstLoad()
      } else {
        const message = response.status === 409 ? t('partners.codeExistingError') : response.problem;
        throw new Error(message)
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const onInvitePartner = async () => {
    try {
      if(!vc.inviteFields?.length) {
        throw new Error('No hay data para enviar')
      }
      const partnerId = vc.item2Edit.id
      const groupUsersToInvite = _.groupBy(vc.inviteFields?.filter(f => !f?.removeOnSubmit), 'index')
      const processedUsers = Object.values(groupUsersToInvite).map(fields => {
        const dataToSend = {}
        fields.forEach(field => {
          dataToSend[field.key] = field.value
        })
        return dataToSend;
      })
      const data = processedUsers
      if (data?.some(u => Object.values(u).some(v => !v))) {
        throw new Error(t('common.notEmptyFields'))
      }
      Model.setData('loading', true)
      const response = await PartnerService.inviteMultiple(partnerId, { members: data })
      if (response.ok) {
        firstLoad()
        changeVc({
          item2Edit: null,
          showInviteModal: false,
          inviteFields: []
        });
      } else {
        const message = response.status === 409 ? t('partners.codeExistingError') : response.problem;
        throw new Error(message)
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const firstLoad = async () => {
    try {
      Model.setData('opacity', 0.7)
      Model.setData('loading', true)
      const response = await PartnerService.getPartners(returnUrlVars(vc, '', true))
      if (response.ok) {
        const data = _.get(response, 'data.data', [])
        const paging = _.get(response, 'data.paging', {})
        changeVc({
          totalItems: paging.totalItems,
          listItems: data
        });
      } else {
        throw new Error(response.problem)
      }
      Model.setData('loading', false)
    } catch (e) {
      Model.setData('loading', false)
      Model.updateAlerts({
        message: `${String(e)}`,
        variant: 'error'
      })
    }
  }

  const addInviteRow = (fields = vc.inviteFields) => {
    const uid = _.uniqueId()
    changeVc({
      inviteFields: [
        ...fields, 
        ...(defaultInviteFields?.map(f => ({ ...f, index: uid })))
      ]
    })
  }

  const removeInviteRow = (uid) => {
    const rows = vc.inviteFields?.filter(f => f.index !== uid)
    if(!rows.length) {
      addInviteRow([])
    } else {
      changeVc({
        inviteFields: rows
      })
    }
  }

  const onChangeOrderLabel = (e, key) => {
    if (encodeURIComponent.persist) {
      e.persist()
    }
    if(key === 'label') {
      return changeVc({ newOrderLabel: { ...vc.newOrderLabel, label: e?.target?.value } })
    }
    const file = e?.target?.files?.[0]
    return changeVc({ newOrderLabel: { ...vc.newOrderLabel, icon: file, preview: URL.createObjectURL(file) } })
  }

  const showPreviewIcon = (preview) =>
    preview?.startsWith('blob:') ? preview : getImageUrl(preview || '');

  React.useEffect(() => {
    if (state.hasAdminPolicy) {
      firstLoad()
    } else {
      history.replace('/orders')
    }
    return () => {}
    //eslint-disable-next-line
  }, []);

  const onCancelUpload = () => {
    setUploadData({
      file: null,
      dataDoc: null,
      partnerId: null
    })
    setOpenConfirmDoc(false)
  }
  

  return <Layout menuSelected={4}>
    <Box className={styles.contBg}>
      <Box className={styles.container}>
        <Box className={styles.rowTitle}>
          <p className={styles.title}>
            {t('common.partners')}
          </p>
          <Box className={styles.colInter} />
          <Button
            text='Crear aliado'
            onClick={() => changeVc({showAddModal: true})}
            iconRight='iconmin-plus_circle-1'
          />
        </Box>
        <Paper
          elevation={3}
          className={styles.contInfo}
        >
          <Box className={styles.rowFilters}>
            <Select
              name='filterBy'
              value={vc.filterBy}
              onChange={handleChange}
              displayEmpty
              className={[styles.selectInput, styles.selectInput2, vc.filterBy ? '' : styles.grayTxt].join(' ')}
              inputProps={{ 'aria-label': 'Without label' }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: -10,
                  horizontal: "left"
                },
                getContentAnchorEl: null,
              }}
            >
              {renderSelectOptions()}
            </Select>
            {vc.filterBy === 'requireCode' ? (
              <Select
                name='requireCode'
                value={requireCode}
                onChange={(e) => setRequireCode(e.target.value)}
                displayEmpty
                className={[styles.selectInput, styles.selectInput2].join(' ')}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: -10,
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem 
                  value={''}
                  className={styles.itemSelect}
                >
                  {t('partners.requireCode')}
                </MenuItem>
                <MenuItem 
                  value={true}
                  className={styles.itemSelect}
                >
                  {t('common.yes')}
                </MenuItem>
                <MenuItem 
                  value={false}
                  className={styles.itemSelect}
                >
                  {t('common.no')}
                </MenuItem>
              </Select>
            ) : (
              <>{dateFilters.includes(vc.filterBy) ? (
                <>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      format='YYYY-MM-DD'
                      value={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      className={styles.pickerDate}
                      label={t('common.firstDate')}
                      placeholder='YYYY-MM-DD'
                      maxDate={returnRangeDate('max')}
                    />
                  </MuiPickersUtilsProvider>
                  <Box className={styles.lblTo}>
                    a
                  </Box>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      format='YYYY-MM-DD'
                      value={selectedDateTo}
                      onChange={(date) => setSelectedDateTo(date)}
                      className={styles.pickerDate}
                      label={t('common.endDate')}
                      placeholder='YYYY-MM-DD'
                      minDate={returnRangeDate('min')}
                    />
                  </MuiPickersUtilsProvider>
                </>) : (
                <Input
                  placeholder={'Escribe aquí ...'}
                  name='searchText'
                  value={vc.searchText}
                  onChange={handleChange}
                  className={[styles.textInput, styles.textInput2].join(' ')}
                  endAdornment={
                    <InputAdornment position="end">
                      <Search className={styles.iconSearch} />
                    </InputAdornment>
                  }
                />)}
            </>)}
            {vc.showClean ? <Button
              text='Limpiar'
              onClick={onClean}
              variant='light'
              customStyles={styles.btClean}
            /> : null}
            <Button
              text='Buscar'
              onClick={onSearch}
            />
          </Box>
          <Box className={styles.contList}>
            <Box className={styles.headerList}>
              {renderHeaders()}
            </Box>
            <Box className={styles.listItems}>
              {renderItemsList()}
            </Box>
            {returnPopover()}
          </Box>
          <Box className={styles.rowPagination}>
            <Button
              text={t('common.export')}
              iconRight='iconmin-download-3'
              onClick={onExport}
            />
            <TablePagination
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={vc.totalItems}
              rowsPerPage={vc.rowsPerPage}
              page={vc.pageSelected}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage='Aliados por página'
            />
          </Box>
        </Paper>
      </Box>
    </Box>
    <DialogForm
      show={vc.showAddModal}
      title={`${vc.item2Edit ? 'Editar' : 'Crear'} Aliado`}
      text='Por favor ingresar todos los datos que se solicitan'
      leftBtText='Cancelar'
      rightBtText='Guardar'
      funcLeft={() => changeVc({
        item2Edit: null,
        showAddModal: false,
        newName: '',
        newCode: '',
        newOrderLabel: {
          label: '',
          icon: '',
          preview: '',
        },
        newRequireCode: true,
        newAfterConfirmationFlow: false,
        newRequireBranchOffice: false,
        newContactInfo: '',
        uploadType: '',
      })}
      funcRight={vc.item2Edit ? onEditPartner : onNewPartner}
      inputs={[
        {label: t('partners.name'), value: 'newName'},
        {label: t('partners.code'), value: 'newCode'},
        {label: t('partners.contactInfo'), value: 'newContactInfo',
          multiline: true,
          width: '100%',
          shrink: true,
          autoSize: true,
          placeholder: t('partners.contactInfoPlaceholder')
        },
        {label: 'Label', value: 'newOrderLabel.label', onChange: (e) => onChangeOrderLabel(e, 'label')},
        {label: 'Icono', type: 'image', value: 'newOrderLabel.icon', src: showPreviewIcon(vc?.newOrderLabel?.preview), onChange: (e) => onChangeOrderLabel(e, 'icon')},
        {label: t('partners.requireCode'), value: 'newRequireCode', type: 'switch'},
        {label: t('partners.requireBranchOffice'), value: 'newRequireBranchOffice', type: 'switch'},
        {label: t('partners.afterConfirmationFlow'), value: 'newAfterConfirmationFlow', type: 'switch'},
        {label: t('partners.uploadType'), value: 'uploadType', type: 'select', values: [
          { label: 'Archivo', value: 'file' },
          { label: 'Foto', value: 'photo' },
          { label: 'Ninguno', value: 'none' }
        ]},
      ]}
      vc={vc}
      changeVc={changeVc}
    />
    <DialogForm
      maxWidth='md'
      show={vc.showInviteModal}
      title={`Invitar Usuario`}
      text='Por favor ingresar todos los datos que se solicitan'
      leftBtText='Cancelar'
      rightBtText='Guardar'
      funcLeft={() => changeVc({
        item2Edit: null,
        showInviteModal: false,
        inviteFields: []
      })}
      funcRight={onInvitePartner}
      inputs={vc.inviteFields}
      vc={vc}
      changeVc={changeVcInvite}
      defaultButtonOnClick={removeInviteRow}
      extraContent={
        <IconButton onClick={() => addInviteRow()}>
          <Add />
        </IconButton>
      }
    />
    <Dialog open={openConfirmDoc} onClose={() => setOpenConfirmDoc(false)}>
      <DialogTitle className={"p-8"}>{t('common.uploadFile')}</DialogTitle>
      <Divider />
      <div className={"p-8"}>
          
        <div>
          {t('common.confirmUploadDoc', {
            docLabel: uploadData?.dataDoc?.label || '',
            docName: uploadData?.file?.name || '' 
          })}
        </div>
        
        <DialogActions>
          <MUButton onClick={onCancelUpload} color="primary">
            {t('common.cancel')}
          </MUButton>
          <MUButton onClick={() => onChangeFile()} color="primary">
            {t('common.upload')}
          </MUButton>
        </DialogActions>
      </div>
    </Dialog>
  </Layout>
}

export default Partners
