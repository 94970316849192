import React from "react";
import * as yup from "yup";
import { Field } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { useField } from "formik";
import { useFormikContext } from "formik";
import { MenuItem } from "@material-ui/core";
import { TextField } from "formik-material-ui";

import { withSchema } from "../../formik/withSchema.util";
import LevelsService from "../../../../services/levels";
import { CircularProgress } from "@material-ui/core";

const LevelFieldsLoading = () => {
  return (
    <div
      className={"w-full flex flex-row justify-center"}
      children={<CircularProgress color={"secondary"} />}
    />
  );
};

const LevelFields = () => {
  const name = "level";
  const [field] = useField(name);
  const { values, setFieldValue } = useFormikContext();
  const [levels, setLevels] = useState({ data: [], isLoading: false });

  const isLevelType = values.type === "level";

  useEffect(() => {
    (async () => {
      if (isLevelType) {
        setLevels((prevState) => ({ ...prevState, isLoading: true }));
        const response = await LevelsService.getLevels();
        setLevels({ data: response.data.data, isLoading: false });
      }
    })();
  }, [isLevelType]);

  useEffect(() => {
    if (isLevelType) {
      setFieldValue(name, "");
    }
    return () => {
      setFieldValue(name, undefined);
    };
  }, [setFieldValue, isLevelType, name]);

  const validationSchema = yup.string().required();

  if (!isLevelType) return null;

  if (levels.isLoading) return <LevelFieldsLoading />;

  return (
    <Field
      name={name}
      type={"text"}
      select={true}
      label={"Nivel"}
      component={TextField}
      value={field.value || ""}
      validate={withSchema(validationSchema)}
    >
      {levels?.data?.map((level) => (
        <MenuItem
          key={level.id}
          value={level.level}
          children={`Nivel ${level.level} - $${level.maxAmount}`}
        />
      ))}
    </Field>
  );
};

export default LevelFields;
